import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import { LOADING, SUCCESS } from '../../../../constants/common';
import { validatePhoneNumber } from '../../../../helper/Validations';
import PhoneNumberInput from '../../../PhoneNumberInput';

const ContactDrawer = ({ onClose, open }) => {
  const { editContact, updateInfoState, updateContactStatus, fetchInfo, data } = useBasicInfo();
  const initialState = {
    phone_number: '',
    business_number: '',
    contact_headline: '',
  }
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);

  function handleClose() {
    setState(initialState);
    setIsInvalid(false)
    onClose();
  }

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      ...ev
    }))
  }

  function isValid() {
    return (
      validatePhoneNumber(state.phone_number) && 
      validatePhoneNumber(state.business_number)
    )
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid())
      editContact(state);
    else
      setIsInvalid(true)
  }

  useEffect(() => {
    if (updateContactStatus === SUCCESS) {
      updateInfoState('updateContactStatus', '')
      fetchInfo();
      handleClose();
    }
  }, [updateContactStatus])

  useEffect(() => {
    if (data && open) {
      setState({
        phone_number: data?.info?.phone_number,
        business_number: data?.info?.business_number,
      })
    }
  }, [data, open])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
        component='form'
        onSubmit={handleSubmit}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Contact</Typography>
          <Divider sx={{ mb: 4 }} />
          <PhoneNumberInput
            fullWidth
            variant='outlined'
            sx={{ mb: 3 }}
            name='phone_number'
            label='Phone Number'
            value={state.phone_number}
            onChange={handleInput}
            error={isInvalid && !validatePhoneNumber(state.phone_number)}
            helperText={
              isInvalid &&
              !validatePhoneNumber(state.phone_number) &&
              "Please add valid phone number"
            }
          />

          <TextField
            fullWidth
            variant='outlined'
            label='Email Address'
            sx={{ mb: 3 }}
            name='email'
            value={data.email}
            disabled
          />
          <PhoneNumberInput
            fullWidth
            variant='outlined'
            sx={{ mb: 3 }}
            label='Business Number'
            name='business_number'
            value={state.business_number}
            onChange={handleInput}
            error={isInvalid && !validatePhoneNumber(state.business_number)}
            helperText={
              isInvalid &&
              !validatePhoneNumber(state.business_number) &&
              "Please add valid business number"
            }
          />

        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            type='submit'
            loading={updateContactStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default ContactDrawer;