import React, { createContext, useContext, useState } from 'react';
import { ERROR, LOADING, SUCCESS } from '../constants/common';
import { useAlert } from './Alert';
import { getFacilityInfo, updateFacilityBasicInfo, updateFacilityContact, updateFacilityServices, updateFacilityPhoto, updateFacilityFaqs, uploadFacilityImages, removeFacilityImage, updateAboutMe } from '../services/organization.services';


const GeneralProfileContext = createContext({});

export const GeneralProfileContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();
    const [generalProfileState, setGeneralProfileState] = useState({
        updateFacilityBasicInfoStatus: '',
        updateFacilityContactStatus: '',
        updateFacilityServicesStatus: '',
        updatePhotoStatus: '',
        fetchFacilityStatus: '',
        updateFacilityFaqsStatus: '',
        updateFacilityImagesStatus: '',
        deleteFacilityImagesStatus: '',
        updateFacilityAboutMeStatus: '',
        facilityInfo: {}
    });

    function handleState(obj) {
        setGeneralProfileState((prevState) => ({
            ...prevState,
            ...obj
        }))
    }

    async function fetchFacilityInfo() {
      handleState({ fetchFacilityStatus: LOADING })
      try {
        const response = await getFacilityInfo();
        handleState({
            facilityInfo: response.data.data || {},
            fetchFacilityStatus: SUCCESS
        })
      } catch (error) {
        handleState({ fetchFacilityStatus: ERROR })
        Promise.reject(error)
      }
    }

    async function editFacilityBasicInfo(data) {
        handleState({ updateFacilityBasicInfoStatus: LOADING })
      try {
        await updateFacilityBasicInfo(data);
        handleState({
            updateFacilityBasicInfoStatus: SUCCESS
        })
        sendAlert('Updated successfully', 'success')
      } catch (error) {
        handleState({ updateFacilityBasicInfoStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    async function editFacilityContact(data) {
        handleState({ updateFacilityContactStatus: LOADING })
      try {
        await updateFacilityContact(data);
        handleState({
            updateFacilityContactStatus: SUCCESS
        })
        sendAlert('Updated successfully', 'success')
      } catch (error) {
        handleState({ updateFacilityContactStatus: ERROR })
        sendAlert((error.response?.data?.errors?.email || [])[0] || error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    async function editFacilityServices(data) {
        handleState({ updateFacilityServicesStatus: LOADING })
      try {
        await updateFacilityServices(data);
        handleState({
            updateFacilityServicesStatus: SUCCESS
        })
        sendAlert('Updated successfully', 'success')
      } catch (error) {
        handleState({ updateFacilityServicesStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    async function editFacilityPhoto(data) {
      handleState({ updatePhotoStatus: LOADING })
      try {
        await updateFacilityPhoto(data);
        handleState({
            updatePhotoStatus: SUCCESS
        })
        sendAlert('Updated successfully', 'success')
      } catch (error) {
        handleState({ updatePhotoStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    async function editFacilityFaqs(data) {
      handleState({ updateFacilityFaqsStatus: LOADING })
      try {
        await updateFacilityFaqs(data);
        handleState({
            updateFacilityFaqsStatus: SUCCESS
        })
        sendAlert('Updated successfully', 'success')
      } catch (error) {
        handleState({ updateFacilityFaqsStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    async function saveFacilityImages(data) {
      handleState({ updateFacilityImagesStatus: LOADING })
      try {
        await uploadFacilityImages(data);
        handleState({
            updateFacilityImagesStatus: SUCCESS
        })
        sendAlert('Updated successfully', 'success')
      } catch (error) {
        handleState({ updateFacilityImagesStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    async function deleteFacilityImage(data) {
      handleState({ deleteFacilityImagesStatus: LOADING })
      try {
        await removeFacilityImage(data);
        handleState({
            deleteFacilityImagesStatus: SUCCESS
        })
        sendAlert('Deleted successfully', 'success')
      } catch (error) {
        handleState({ deleteFacilityImagesStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    async function editFacilityAboutMe(data) {
      handleState({ updateFacilityAboutMeStatus: LOADING })
    try {
      await updateAboutMe(data);
      handleState({
          updateFacilityAboutMeStatus: SUCCESS
      })
      sendAlert('Updated successfully', 'success')
    } catch (error) {
      handleState({ updateFacilityAboutMeStatus: ERROR })
      sendAlert(error.response?.data?.message, 'error')
      Promise.reject(error)
    }
  }

    function updateGeneralProfileState(field, value) {
        handleState({ [field]: value })
    }
    

    const state = {
        ...generalProfileState,
        editFacilityBasicInfo,
        editFacilityContact,
        editFacilityServices,
        editFacilityPhoto,
        fetchFacilityInfo,
        editFacilityFaqs,
        saveFacilityImages,
        deleteFacilityImage,
        editFacilityAboutMe,
        updateGeneralProfileState
    };

    return (
        <GeneralProfileContext.Provider value={state}>
        {children}
        </GeneralProfileContext.Provider>
    );
};

export const useGeneralProfile = () => useContext(GeneralProfileContext);