import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { LOADING, SUCCESS } from '../../../../constants/common';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import isURL from 'validator/lib/isURL';

const LinksDrawer = ({ onClose, open }) => {
    const { editLinksInfo, updateInfoState, updateLinkstatus, fetchInfo, data } = useBasicInfo();
    const initialState = {
        website_link: '',
        facebook: '',
        twitter: '',
        linkedin: ''
    }
    const [state, setState] = useState(initialState);
    const [isInvalid, setIsInvalid] = useState(false);

    function handleClose() {
        setState(initialState);
        setIsInvalid(false) 
        onClose();
    }

    function handleInput(ev) {
        setState((prevState) => ({
            ...prevState,
            [ev.target.name]: ev.target.value?.toLowerCase()
        }))
    }

    function isValid() {
        return (state.website_link ? isURL(state.website_link) : true)
        && (state.facebook ? isURL(state.facebook) : true)
        && (state.twitter ? isURL(state.twitter) : true)
        && (state.linkedin ? isURL(state.linkedin) : true)
    }

    function handleSubmit(ev) {
        ev.preventDefault();
        if (isValid())
            editLinksInfo(state);
        else
            setIsInvalid(true) 
    }

    useEffect(() => {
      if (updateLinkstatus === SUCCESS) {
        updateInfoState('updateLinkstatus', '')
        fetchInfo();
        handleClose();
      }
    }, [updateLinkstatus])

    useEffect(() => {
        if (data && open) {
            setState({
                website_link: data?.info?.website_link,
                facebook: data?.info?.facebook,
                twitter: data?.info?.twitter,
                linkedin: data?.info?.linkedin,
            })
        }
    }, [data, open])

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            anchor='right'
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    pt: 8
                }}
                component='form'
                onSubmit={handleSubmit}
            >
                <Box pb={3} px={3}>
                    <Typography variant='h4' mb={1}>Links</Typography>
                    <Divider sx={{ mb: 4 }} />
                    <TextField
                        fullWidth
                        variant='outlined'
                        label='Website'
                        onChange={handleInput}
                        name='website_link'
                        sx={{ mb: 4 }}
                        value={state.website_link}
                        error={isInvalid && (state.website_link && !isURL(state.website_link))}
                        helperText={
                            isInvalid
                            && (state.website_link && !isURL(state.website_link))
                            && "Please enter valid link"
                        }
                    />
                    <TextField
                        fullWidth
                        variant='outlined'
                        label='Facebook'
                        onChange={handleInput}
                        name='facebook'
                        sx={{ mb: 3 }}
                        value={state.facebook}
                        error={isInvalid && (state.facebook && !isURL(state.facebook))}
                        helperText={
                            isInvalid
                            && (state.facebook && !isURL(state.facebook))
                            && "Please enter valid link"
                        }
                    />
                    <TextField
                        fullWidth
                        variant='outlined'
                        label='X'
                        onChange={handleInput}
                        name='twitter'
                        sx={{ mb: 3 }}
                        value={state.twitter}
                        error={isInvalid && (state.twitter && !isURL(state.twitter))}
                        helperText={
                            isInvalid
                            && (state.twitter && !isURL(state.twitter))
                            && "Please enter valid link"
                        }
                    />
                    <TextField
                        fullWidth
                        variant='outlined'
                        label='LinkedIn'
                        onChange={handleInput}
                        name='linkedin'
                        sx={{ mb: 3 }}
                        value={state.linkedin}
                        error={isInvalid && (state.linkedin && !isURL(state.linkedin))}
                        helperText={
                            isInvalid
                            && (state.linkedin && !isURL(state.linkedin))
                            && "Please enter valid link"
                        }
                    />
                </Box>
                
                <Box
                    sx={{
                        boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
                        px: 1,
                        pt: 3,
                        pb: 5.625,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        position: 'sticky',
                        width: '100%',
                        backgroundColor: 'background.paper',
                        bottom: 0,
                        zIndex: 9
                    }}
                >
                    <Button
                        variant='outlined'
                        color='secondary'
                        sx={{
                            color: 'primary.main'
                        }}
                        size='large'
                        fullWidth
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        size='large'
                        fullWidth
                        type='submit'
                        loading={updateLinkstatus === LOADING}
                    >
                        Save
                    </LoadingButton>
                </Box>
            </Box>
        </Drawer>
    )
}

export default LinksDrawer;