import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactComponent as ChevronDown } from '../../assets/Icons/chevron-down.svg'
import { useTraining } from '../../contexts/Training';
import { LOADING, SUCCESS } from '../../constants/common';
import { validateOrganizationName, validateText, yearValidation } from '../../helper/Validations';

const TrainingDrawer = ({ onClose, open, activeTraining }) => {
  const { generateTraining, createStatus, fetchTraining, editTraining, updateStatus, updateTrainingtate, openConfirmationModal, deleteStatus } = useTraining();
  const initialState = {
    type: '',
    name: '',
    focus: '',
    year: ''
  }

  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);

  const isEdit = Boolean(activeTraining);

  const handleInput = (ev) => {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }));
  }

  const handleClose = () => {
    onClose();
    setState(initialState);
    setIsInvalid(false);
  }

  const isValid = () => {
    return Boolean(state.type)
      && Boolean(validateOrganizationName(state.name))
      && Boolean(validateText(state.focus))
      && Boolean(yearValidation(state.year))
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (isValid()) {
      if (isEdit) {
        editTraining({
          type: state.type,
          data: state,
          id: activeTraining?.id
        });
      } else {
        generateTraining({
          type: state.type,
          data: state
        });
      }
    } else {
      setIsInvalid(true);
    }
  }

  useEffect(() => {
    if (createStatus === SUCCESS) {
      updateTrainingtate('createStatus', '');
      fetchTraining();
      handleClose();
    }
  }, [createStatus])

  useEffect(() => {
    if (updateStatus === SUCCESS) {
      updateTrainingtate('updateStatus', '');
      fetchTraining();
      handleClose();
    }
  }, [updateStatus])


  useEffect(() => {
    if (deleteStatus === SUCCESS) {
      handleClose();
    }
  }, [deleteStatus])

  useEffect(() => {
    if (isEdit && open) {
      setState({
        ...activeTraining.data,
      });
    }
  }, [activeTraining, open, isEdit])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
        component='form'
        onSubmit={handleSubmit}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Training</Typography>
          <Typography variant='body1' mb={3}>To add a new training entry, please provide the following information:</Typography>
          <Divider sx={{ mb: 3 }} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                variant='outlined'
                label='Type of Training'
                fullWidth
                SelectProps={{
                  IconComponent: () => (
                    <Box
                      sx={{
                        minWidth: 24,
                        width: 24,
                        height: 24,
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'text.secondary',
                        mr: 2
                      }}
                    >
                      <ChevronDown width={12} height={6} />
                    </Box>
                  ),
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 220,
                        width: 250,
                      },
                    },
                  }
                }}
                name='type'
                onChange={handleInput}
                value={state.type}
                error={isInvalid && !state.type}
                disabled={isEdit}
              >
                <MenuItem value={''} disabled sx={{ py: 0 }} />
                <MenuItem value='certificate' sx={{ py: 1 }}>Certificate</MenuItem>
                <Divider sx={{ my: 1 }} />
                <MenuItem value='fellowship' sx={{ py: 1 }}>Fellowship</MenuItem>
                <Divider sx={{ my: 1 }} />
                <MenuItem value='post-doctoral' sx={{ py: 1 }}>Post-Doctoral</MenuItem>
                <Divider sx={{ my: 1 }} />
                <MenuItem value='residency' sx={{ py: 1 }}>Residency</MenuItem>
              </TextField>
            </Grid>
            {Boolean(state.type) && (
              <>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    label='Insert Institution Name'
                    fullWidth
                    name='name'
                    onChange={handleInput}
                    value={state.name}
                    error={isInvalid && !validateOrganizationName(state.name)}
                    helperText={
                      isInvalid &&
                      !validateOrganizationName(state.name) &&
                      "Please add valid institution name"
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant='outlined'
                    label='Training Focus'
                    fullWidth
                    name='focus'
                    onChange={handleInput}
                    value={state.focus}
                    error={isInvalid && !validateText(state.focus)}
                    helperText={
                      isInvalid &&
                      !validateText(state.focus) &&
                      "Please add valid training focus"
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant='outlined'
                    label='Year Earned (YYYY)'
                    fullWidth
                    name='year'
                    onChange={handleInput}
                    value={state.year}
                    error={isInvalid && !yearValidation(state.year)}
                    helperText={
                      isInvalid &&
                      !yearValidation(state.year) &&
                      "Please add valid year"
                    }
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary'>Please note that the licensure information provided will be visible to other users when viewing your full profile.</Typography>
            </Grid>
            {isEdit && (
              <Grid item xs={12} display='flex' justifyContent='flex-end'>
                <Button
                  onClick={openConfirmationModal}
                  size='small'
                  color='error'
                  sx={{
                    minWidth: 120,
                    backgroundColor: 'error.light',
                    color: 'error.main',
                  }}
                >
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>


        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            type='submit'
            loading={createStatus === LOADING}
          >
            {isEdit ? 'Save' : 'Create'}
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default TrainingDrawer;