import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { ReactComponent as EditIcon } from '../../../assets/Icons/edit-3.svg'
import AddressDrawer from './AddressDrawer';
import { useBasicInfo } from '../../../contexts/BasicInfo';
const AddressCard = () => {
  const { data } = useBasicInfo();
  const [open, setOpen] = useState(false)

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  return (
    <>
      <Box
        sx={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'border.main',
          p: 3,
          borderRadius: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 1.5
          }}
        >
          <Typography variant='h5'>Address</Typography>
          <IconButton
            sx={{ p: 0.375 }}
            onClick={handleOpen}
          >
            <EditIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1.5,
            gap: 0.5
          }}
        >
          <Typography variant='body2' fontWeight={600}>Street Name:</Typography>
          <Typography variant='body2'>{data?.info?.address?.address1}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1.5,
            gap: 0.5
          }}
        >
          <Typography variant='body2' fontWeight={600}>City:</Typography>
          <Typography variant='body2'>{data?.info?.address?.city}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1.5,
            gap: 0.5
          }}
        >
          <Typography variant='body2' fontWeight={600}>State:</Typography>
          <Typography variant='body2'>{data?.info?.address?.state?.name}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <Typography variant='body2' fontWeight={600}>Zip code:</Typography>
          <Typography variant='body2'>{data?.info?.address?.zip_code}</Typography>
        </Box>
      </Box>
      <AddressDrawer
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

export default AddressCard;