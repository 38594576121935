import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useOnboarding } from '../../../contexts/onboarding';
import { ReactComponent as ChevronDown } from '../../../assets/Icons/chevron-down.svg';
import { useCommon } from '../../../contexts/common';
import { detox, payment_options, services } from '../../../constants/common';
import { servicesAvailableSchema } from '../../../validations/registerValidation';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const ServicesAvailable = () => {
  const { next, updateOnboardingState, onboardingState } = useOnboarding();
  const {
    fetchTreatmentTypes,
    servicesList,
    detoxList,
    paymentOptionsList,
  } = useCommon();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger
  } = useForm({
    resolver: zodResolver(servicesAvailableSchema),
    defaultValues: {
      available_services: onboardingState.available_services || [],
      payment_options: onboardingState.payment_options || [],
    },
    mode: 'onChange',
  });

  useEffect(() => {
    fetchTreatmentTypes(services);
    fetchTreatmentTypes(detox);
    fetchTreatmentTypes(payment_options);
  }, []);

  const onSubmit = (data) => {
    updateOnboardingState({
      available_services: data.available_services,
      payment_options: data.payment_options,
    });
    next();
  };

  return (
    <Container maxWidth='md'>
      <Paper
        sx={{
          p: 6,
          boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.04)',
          border: 0
        }}
      >
        <Typography variant='h5' mb={1.5}>
          Services Available
        </Typography>

        <Grid container spacing={1.5} pb={3}>
          <Grid item xs={12}>
            <FormControl error={!!errors.available_services} fullWidth>
              <InputLabel id="available-services-label">Available Services</InputLabel>
              <Controller
                name="available_services"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="available-services-label"
                    label='Available Services'
                    fullWidth

                    multiple
                    renderValue={(selected) => selected.map((val) => servicesList.find((item) => item.id === +val)?.name).join(', ')}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          width: 250,
                        },
                      },
                    }}
                    IconComponent={(props) => (
                      <Box
                        {...props}
                        component='span'
                        sx={{
                          minWidth: 24,
                          width: 24,
                          height: 24,
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '50% !important',
                          right: '16px !important',
                          transform: 'translateY(-50%) !important',
                        }}
                      >
                        <ChevronDown width={12} height={6} />
                      </Box>
                    )}
                    onChange={async (ev) => {
                      setValue('available_services', ev.target.value);
                      await trigger('available_services');
                    }}
                    value={field.value}
                  >
                    <MenuItem value={null} disabled>
                      <em>Select services</em>
                    </MenuItem>
                    {servicesList.map((item) => (
                      <MenuItem
                        sx={{ py: 1, gap: 1 }}
                        value={item.id}
                        key={item.id}
                      >
                        <Checkbox checked={field.value.indexOf(item.id) > -1} />
                        <Typography variant='body2'>{item.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.available_services && (
                <Typography color="error">{errors.available_services.message}</Typography>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl error={!!errors.payment_options} fullWidth>
              <InputLabel id="payment-options-label">Payment Options</InputLabel>
              <Controller
                name="payment_options"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="payment-options-label"
                    label='Payment Options'
                    multiple
                    renderValue={(selected) => selected.map((val) => paymentOptionsList.find((item) => item.id === +val)?.name).join(', ')}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          width: 250,
                        },
                      },
                    }}
                    IconComponent={(props) => (
                      <Box
                        {...props}
                        component='span'
                        sx={{
                          minWidth: 24,
                          width: 24,
                          height: 24,
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '50% !important',
                          right: '16px !important',
                          transform: 'translateY(-50%) !important',
                        }}
                      >
                        <ChevronDown width={12} height={6} />
                      </Box>
                    )}
                    onChange={async (ev) => {
                      setValue('payment_options', ev.target.value);
                      await trigger('payment_options');

                    }}
                    value={field.value}
                  >
                    <MenuItem value={null} disabled>
                      <em>None</em>
                    </MenuItem>
                    {paymentOptionsList.map((item) => (
                      <MenuItem
                        sx={{ py: 1, gap: 1 }}
                        value={item.id}
                        key={item.id}
                      >
                        <Checkbox checked={field.value.indexOf(item.id) > -1} />
                        <Typography variant='body2'>{item.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.payment_options && (
                <Typography color="error">{errors.payment_options.message}</Typography>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Paper>

      <Box pt={5} textAlign='center'>
        <Button
          variant='contained'
          sx={{
            minWidth: 212
          }}
          onClick={handleSubmit(onSubmit)}
        >
          Continue
        </Button>
      </Box>
    </Container>
  );
}

export default ServicesAvailable;
