import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { LOADING, SUCCESS } from '../../../../constants/common';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import { validatePhoneNumber } from '../../../../helper/Validations';
import PhoneNumberInput from '../../../PhoneNumberInput';

const OfficeInfoDrawer = ({ onClose, open }) => {
  const { editOfficeInfo, updateInfoState, updateOfficeInfoStatus, fetchInfo, data } = useBasicInfo();
  const initialState = {
    fax_number: '',
    office_number: '',
  }
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);

  function handleClose() {
    setState(initialState);
    setIsInvalid(false)
    onClose();
  }

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      ...ev
    }))
  }

  function isValid() {
    return (
      validatePhoneNumber(state.fax_number) && 
      validatePhoneNumber(state.office_number)
    )
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid())
      editOfficeInfo(state);
    else
      setIsInvalid(true)
  }


  useEffect(() => {
    if (updateOfficeInfoStatus === SUCCESS) {
      updateInfoState('updateOfficeInfoStatus', '')
      fetchInfo();
      handleClose();
    }
  }, [updateOfficeInfoStatus])

  useEffect(() => {
    if (data && open) {
      setState({
        fax_number: data?.info?.fax_number,
        office_number: data?.info?.office_number,
      })
    }
  }, [data, open])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
        component='form'
        onSubmit={handleSubmit}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Office Information</Typography>
          <Divider sx={{ mb: 4 }} />

          <PhoneNumberInput
            fullWidth
            variant='outlined'
            sx={{ mb: 3 }}
            name='fax_number'
            value={state.fax_number}
            onChange={handleInput}
            error={isInvalid && !validatePhoneNumber(state.fax_number)}
            helperText={
              isInvalid 
              && !validatePhoneNumber(state.fax_number) 
              && "Please add valid fax number"
            }
            label='Fax Number'
          />

          <PhoneNumberInput
            fullWidth
            variant='outlined'
            label='Office Number'
            sx={{ mb: 3 }}
            onChange={handleInput}
            name='office_number'
            error={isInvalid && !validatePhoneNumber(state.office_number)}
            value={state.office_number}
            helperText={
              isInvalid 
              && !validatePhoneNumber(state.office_number) 
              && "Please add valid office number"
            }
          />
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            type='submit'
            loading={updateOfficeInfoStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default OfficeInfoDrawer;