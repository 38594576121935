import React, { useEffect, useState } from 'react'
import LoadingButton from '@mui/lab/LoadingButton';
import { useForm } from 'react-hook-form';
import { Drawer, Typography, Divider, Box, Button } from '@mui/material';
import { zodResolver } from '@hookform/resolvers/zod';
import { fullFormSchema } from '../../validations/homepageValidation';

import {
  LOADING,
  SUCCESS,
  accepting_new_clients_and_taking_referrals,
  full_and_need_to_refer_out,
  treatment_program
} from '../../constants/common';
import { ReactComponent as ChevronDown } from '../../assets/Icons/chevron-down.svg';
import AgesSelection from '../AgesSelection';
import { useCommon } from '../../contexts/common';
import { useHomePage } from '../../contexts/HomePage';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useAuth } from '../../contexts/Auth';
import { useGeneralProfile } from '../../contexts/Organization';
import sortAgeRanges from '../../utils/sortAges'
import CustomMultiSelectInput from '../CustomMultiSelectInput';
import { group_accepting_from, session_type } from '../../constants/lists';

const customStyles = {
  minWidth: 24,
  width: 24,
  height: 24,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '50% !important',
  right: '16px !important',
  transform: 'translateY(-50%) !important',
}

const options = [
  { value: accepting_new_clients_and_taking_referrals, key: 'Accepting Clients', data: 'Accepting new clients and taking referrals' },
  { value: full_and_need_to_refer_out, key: 'Not Accepting Clients', data: 'Full and Need to Refer out' }
]

const PracticeStatusDrawer = ({ onClose, open }) => {
  const { data } = useBasicInfo();
  const { facilityInfo } = useGeneralProfile();
  const { account_type } = useAuth();
  const { clientTypesList, fetchClientTypesList } = useCommon();
  const { editCurrentStatus, updateCurrentStatusStatus, updateHomePagetate } = useHomePage();
  const info = account_type === treatment_program ? facilityInfo : data;
  const initialState = {
    current_status: info?.info?.current_status || '',
    age_served: info?.info?.age_served || [],
    currently_served: info?.currently_served || [],
    session_type: info?.info?.session_types?.split(',') || [],
    accepting_referrals_from: info?.info?.accepting_referrals_from || ''
  }
  const [state, setState] = useState(initialState);
  const [error, setError] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    trigger,
  } = useForm({
    resolver: zodResolver(fullFormSchema),
    defaultValues: {
      ...initialState
    },
    mode: 'onChange',
  });

  useEffect(() => {
    fetchClientTypesList();
  }, [])

  useEffect(() => {
    if (updateCurrentStatusStatus === SUCCESS) {
      updateHomePagetate('updateCurrentStatusStatus', '');
      handleClose();
    }
  }, [updateCurrentStatusStatus])

  useEffect(() => {
    setState(({
      current_status: info?.info?.current_status || '',
      age_served: info?.info?.age_served ? info.info.age_served.split(',') : [],
      currently_served: info?.currently_served?.map(item => item.id) || [],
      session_type: session_type.filter(element => info?.info?.[element.value])?.map(item => item.value),
      accepting_referrals_from: info?.info?.accepting_referrals_from || '',
    }));
    setValue('current_status', state.current_status);
    setValue('currently_served', state.currently_served);
    setValue('accepting_referrals_from', state.accepting_referrals_from);
    setValue('session_type', state.session_type);
    setValue('account_type', account_type);
  }, [info, open])


  const updateState = (obj) => {
    const key = Object.keys(obj)[0];
    setValue(key, obj[key])
    setState((prevState) => ({
      ...prevState,
      ...obj
    }))
  }

  const handleSelect = (ev) => {
    const { name, value } = ev.target;
    updateState({ [name]: value });
  }

  const handleClose = () => {
    setState(initialState);
    reset();
    setError(false);
    onClose();
  }

  const onSubmit = () => {
    if (
      state.current_status !== full_and_need_to_refer_out
      && (!state.age_served || state.age_served?.length === 0)
    ) {
      setError(true);
      return;
    }
    const acceptingPayLoad = account_type !== treatment_program ?
      {
        ...state,
        age_served: state.age_served?.length ? sortAgeRanges(state.age_served).join(',') : [],
        account_type: 'user',
        currently_served: state?.currently_served,
        ...session_type.reduce((acc, key) => {
          acc[key?.value] = state?.session_type?.includes(key?.value) ? 1 : state[key?.value] || 0;
          return acc;
        }, {}),
      } :
      {
        current_status: state.current_status,
        accepting_referrals_from: state.accepting_referrals_from,
        age_served: state.age_served?.length ? sortAgeRanges(state.age_served).join(',') : [],
        account_type: treatment_program,
      }
    delete acceptingPayLoad.session_type;
    const payload = state.current_status === accepting_new_clients_and_taking_referrals ?
      acceptingPayLoad :
      {
        current_status: state.current_status,
        account_type: account_type === treatment_program ? treatment_program : 'user',
      }

    editCurrentStatus(payload)
  }


  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
        component='form'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box
          sx={{
            pb: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            px: 3
          }}
        >
          <Typography variant='h4'>
            {
              state.current_status && state.current_status !== full_and_need_to_refer_out ?
                'What type of clients are you currently accepting?' :
                'Are you currently accepting clients?'
            }
          </Typography>
          <Divider />
          <CustomMultiSelectInput
            name='current_status'
            control={control}
            labelId='my-availability-label'
            value={state?.current_status || ''}
            label='My Availability'
            fullWidth
            renderValue={(selected) => {
              if (selected === accepting_new_clients_and_taking_referrals)
                return 'Accepting Clients'
              if (selected === full_and_need_to_refer_out)
                return 'Not Accepting Clients'
            }}
            IconComponent={(props) => (
              <Box
                {...props}
                component='span'
                sx={customStyles}
              >
                <ChevronDown width={12} height={6} />
              </Box>
            )}
            onChange={updateState}
            error={errors?.current_status}
            helperText={errors?.current_status?.message}
            items={options}
            trigger={trigger}
          />
          {state.current_status && state.current_status !== full_and_need_to_refer_out &&
            (
              <>
                {
                  account_type !== treatment_program &&
                  <CustomMultiSelectInput
                    label='Clients Currently Accepting'
                    labelId='currently_served'
                    name='currently_served'
                    value={state?.currently_served}
                    onChange={updateState}
                    error={errors?.currently_served}
                    helperText={errors ? errors?.currently_served?.message : ''}
                    control={control}
                    items={clientTypesList?.map(item => { return { key: item?.name, value: item?.id } }) ?? []}
                    trigger={trigger}
                    renderValue={(selected) => selected.map((id) => clientTypesList.find((item) => id === item.id)?.name).join(', ')}
                    multiple
                  />
                }
                <AgesSelection
                  data={state}
                  setData={updateState}
                  title='Ages Currently Accepting'
                  isInvalid={error}
                />
                {
                  account_type !== treatment_program &&
                  <CustomMultiSelectInput
                    label='Available Session Types'
                    labelId='session-types'
                    name='session_type'
                    value={state?.session_type}
                    onChange={updateState}
                    error={errors?.session_type}
                    helperText={errors ? errors?.session_type?.message : ''}
                    control={control}
                    items={session_type || []}
                    trigger={trigger}
                    renderValue={(selected) => selected.map((value) => session_type.find((item) => value === item.value)?.key).join(', ')}
                    multiple
                  />
                }
                <CustomMultiSelectInput
                  label='Group Accepting From'
                  labelId='group-accepting-from'
                  name='accepting_referrals_from'
                  value={state?.accepting_referrals_from}
                  onChange={updateState}
                  error={errors?.accepting_referrals_from}
                  helperText={errors ? errors?.accepting_referrals_from?.message : ''}
                  control={control}
                  items={group_accepting_from ?? []}
                  trigger={trigger}
                  renderValue={(selected) => {
                    const data = group_accepting_from.find(item => selected === item.value)
                    return data.key
                  }}
                />
              </>
            )}
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            type='submit'
            loading={updateCurrentStatusStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default PracticeStatusDrawer;