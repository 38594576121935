import { z } from 'zod';

export const registerValidationSchema = z.object({
  first_name: z
    .string()
    .min(1, { message: 'Please add first name.' })
    .min(2, { message: 'First Name must be at least 2 characters long.' })
    .max(50, { message: 'First Name must be less than 50 characters long.' })
    .regex(/^[a-zA-Z-' ]+$/, { message: "First Name can only contain letters or special characters ‘ and -" }),

  last_name: z
    .string()
    .min(1, { message: 'Please add last name.' })
    .min(2, { message: 'Last Name must be at least 2 characters long.' })
    .max(50, { message: 'Last Name must be less than 50 characters long.' })
    .regex(/^[a-zA-Z-'. ]+$/, { message: "Last Name can only contain letters or special characters ‘ - and ." }),

  email: z
    .string()
    .min(1, { message: 'Please add email.' })
    .email('Please add a valid email.')
    .max(100, { message: 'Email must be less than 100 characters long.' }),

  password: z
    .string()
    .min(1, { message: 'Please enter a password.' })
    .min(12, { message: 'Password must be at least 12 characters long.' })
    .regex(
      /^(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-.\/:;<=>?@[\]^_`{|}~]).{12,}$/,
      { message: 'Password must include at least one uppercase letter, one lowercase letter, one number, and one special character (#.-?!@$%^&*).' }
    ),
});


const firstNameValidation = z
  .string()
  .min(1, { message: 'Please add first name.' })
  .min(2, { message: 'First Name must be at least 2 characters long.' })
  .max(50, { message: 'First Name must be less than 50 characters long.' })
  .regex(/^[a-zA-Z-' ]+$/, { message: "First Name can only contain letters or special characters ‘ and -" });

const lastNameValidation = z
  .string()
  .min(1, { message: 'Please add last name.' })
  .min(2, { message: 'Last Name must be at least 2 characters long.' })
  .max(50, { message: 'Last Name must be less than 50 characters long.' })
  .regex(/^[a-zA-Z-'. ]+$/, { message: "Last Name can only contain letters or special characters ‘ - and ." });

const phoneNumberValidation = z
  .string({ required_error: 'Please add your phone number.' })
  .min(1, { message: 'Please add your phone number.' })
  .regex(/^\d+$/, { message: 'Phone number can only contain digits.' })
  .refine((value) => value.length === 10 || value.length === 11, {
    message: 'Please enter a valid phone number (10 or 11 digits).',
  });

const websiteLinkValidation = z
  .string()
  .max(100, { message: 'Website Link must be less than 100 characters long.' })
  .url({ message: 'Please add a valid website URL.' })
  .optional()
  .or(z.literal(''));

const streetAddressValidation = z
  .string()
  .max(100, { message: 'Street Address must be less than 100 characters long.' })
  .regex(/^[a-zA-Z0-9,.\- ]*$/, { message: 'Please enter a valid street address.' })
  .optional()
  .or(z.literal(''));

const stateValidation = z
  .number()
  .min(1, { message: 'Please select a state.' })
  .max(9999, { message: 'State must be less than 10 digits long.' });

const cityValidation = z
  .string()
  .min(1, { message: 'Please add a city.' })
  .max(20, { message: 'City must be less than 20 characters long.' })
  .regex(/^[a-zA-Z ]+$/, { message: 'City can only contain letters.' });

const zipCodeValidation = z
  .string()
  .min(1, { message: 'Please add zip code.' })  // Ensures at least 1 character
  .superRefine((value, ctx) => {
    if (!/^\d*$/.test(value)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Zip code can only contain numbers.',
      });
    } else if (value.length !== 5) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Zip code must be 5 digits long.',
      });
    }
  });


const uploadLogoValidation = z
  .object({
    file: z
      .instanceof(File)
      .optional()
      .refine(
        (file) => !file || ["image/jpeg", "image/png", "image/jpg", "image/svg+xml"].includes(file.type),
        { message: "Please upload a valid image file (JPG, JPEG, PNG, SVG)." }
      )
      .refine(
        (file) => !file || file.size <= 5 * 1024 * 1024, // 5 MB size limit
        { message: "The logo must be less than 5 MB." }
      ),
  })
  .nullable()
  .refine(async (data) => {
    if (!data || !data.file) return true;

    const file = data.file;
    const isValidDimensions = await checkImageDimensions(file, 300, 300, 2000, 2000);
    return isValidDimensions;
  }, { message: "The logo dimensions must be between 300x300 and 2000x2000 pixels." });


async function checkImageDimensions(file, minWidth, minHeight, maxWidth, maxHeight) {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = function () {
      const { width, height } = img;
      console.log('Image dimensions: ', width, height);
      if (width >= minWidth && height >= minHeight && width <= maxWidth && height <= maxHeight) {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    img.onerror = function () {
      resolve(false);
    };
    img.src = URL.createObjectURL(file);
  });
}

const treatmentFacilityValidation = z
  .string()
  .min(1, { message: 'Please add a facility name.' })
  .min(2, { message: 'Name must be at least 2 characters long.' })
  .max(50, { message: 'Name must be less than 50 characters long.' })
  .regex(/^[a-zA-Z0-9-' ]+$/, {
    message: "Name can only contain letters, numbers, spaces, or special characters ‘ and -",
  });

const treatmentFacilityTypeValidation = z
  .array(z.number().int(), { message: 'Please select at least one treatment facility type.' })
  .min(1, { message: 'Please select a treatment facility type.' })


const registerFormValidation = z.object({
  phone_number: phoneNumberValidation,
  website_link: websiteLinkValidation,
  address1: streetAddressValidation,
  state: stateValidation,
  city: cityValidation,
  zip_code: zipCodeValidation,
});

export const basicInfoValidationSchema = z.object({
  first_name: firstNameValidation,
  last_name: lastNameValidation,
  ...registerFormValidation.shape,
});

export const treatmentProgramProfileValidationSchema = z.object({
  facility_name: treatmentFacilityValidation,
  facility_type_ids: treatmentFacilityTypeValidation,
  facility_image: uploadLogoValidation,
  ...registerFormValidation.shape,
});

export const currentPracticeSchema = z.object({
  current_status: z.enum([
    "accepting_new_clients",
    "not_accepting_clients",
    "somewhere_in_between",
  ], {
    errorMap: () => ({ message: "Please select any one option to continue." }),
  }),
});

const currentYear = new Date().getFullYear();

const professionalDegreeSchema = z
  .number({
    required_error: "Please select a degree.",
    invalid_type_error: "Please select a degree.",
  });

const yearGraduatedSchema = z
  .string({
    required_error: "Please add a year.",
  })
  .length(4, { message: "Please enter a valid year (YYYY)." })
  .refine((year) => /^[0-9]+$/.test(year), { message: "Please enter a valid year (YYYY)." })
  .refine((year) => parseInt(year) >= 1900 && parseInt(year) <= currentYear, {
    message: `Please enter a year between 1900 and ${currentYear}.`,
  });

const practiceYearSchema = z
  .string({
    required_error: "Please add a year.",
  })
  .length(4, { message: "Please enter a valid year (YYYY)." })
  .refine((year) => /^[0-9]+$/.test(year), { message: "Please enter a valid year (YYYY)." })
  .refine((year) => parseInt(year) >= 1900 && parseInt(year) <= currentYear, {
    message: `Please enter a year between 1900 and ${currentYear}.`,
  });

const schoolNameSchema = z
  .string()
  .min(1, { message: "Please add a school name." })
  .min(2, { message: "School name must be at least 2 characters long." })
  .max(50, { message: "School name must be less than 50 characters long." })
  .regex(/^[A-Za-z' -]+$/, {
    message: "School name can only contain letters or special characters ‘ and -",
  });

export const educationSchema = z.object({
  professional_degree_id: professionalDegreeSchema,
  degree_year: yearGraduatedSchema,
  practice_year: practiceYearSchema,
  college_name: schoolNameSchema,
}).refine((data) => parseInt(data.practice_year) >= parseInt(data.degree_year), {
  message: "Practice year must be greater than or equal to the graduation year.",
  path: ["practice_year"],
});

export const additionalEducationSchema = z.object({
  degrees: z.array(educationSchema),
});


export const officeHoursDaySchema = z
  .array(z.string())
  .nonempty("Please select at least one day.")
  .refine((days) => {
    if (days.includes("All days")) return days.length === 8;
    if (days.includes("All weekdays")) return days.length === 6;
    return true;
  }, "Please ensure the correct days are selected.")

export const officeHoursTimeSchema = z
  .array(z.string())
  .nonempty("Please select at least one time.")
  .refine((times) => {
    if (times.includes("All times")) return times.length === 4;
    return true;
  }, "Please ensure the correct times are selected.")


export const clientTypeSchema = z.object({
  client_types: z.array(z.number().int())
    .nonempty("Please select a client type.")
});


export const cardHolderNameSchema = z
  .string()
  .min(1, { message: "Please add a card holder name"})
  .min(2, { message: "Cardholder name must be at least 2 characters long." })
  .max(50, { message: "Cardholder name must be less than 50 characters long." })
  .regex(/^[A-Za-z'. -]+$/, {
    message:
      "Cardholder name can only contain letters or special characters ‘ - and . ",
  })
  .refine((val) => val.trim() !== "", { message: "Please add cardholder name." })

export const billingStreetAddressSchema = z
  .string()
  .min(1, { message: "Please enter your street address" })
  .max(100, "Street address cannot exceed 100 characters.")
  .regex(
    /^[A-Za-z0-9 ,.-]+$/,
    "Please enter a valid street address."
  )

export const paymentFormSchema = z.object({
  name: cardHolderNameSchema,
  address1: billingStreetAddressSchema,
  state: stateValidation,
  city: cityValidation,
  zip_code: zipCodeValidation,
});

export const servicesAvailableSchema = z.object({
  payment_options: z.array(z.number().int())
    .nonempty("Please select at least one payment option."),
  available_services: z.array(z.number().int())
    .nonempty("Please select at least one available service.")
})