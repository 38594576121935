import React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import LoadingButton from '@mui/lab/LoadingButton'
import { LOADING } from '../../constants/common'
import { useAuth } from '../../contexts/Auth'
import paths from '../../constants/paths'

const PasswordRecovery = ({ resend, email }) => {
  const { forgetPasswordStatus, prev } = useAuth();
  return (
    <Box
      sx={{
        maxWidth: 600,
        px: 2,
        mx: 'auto'
      }}
    >
      <Paper
        sx={{
          p: 4,
          boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.04)',
          border: 0
        }}
      >
        <Typography variant='h3' mb={3}>
          Password Recovery
        </Typography>

        <Typography variant='body1' mb={4}>
          Click the button in the email we sent to {email} to reset your password.
        </Typography>

        <Typography variant='body1' mb={4}>
          If you do not receive the email within a few minutes,
          please check your junk/spam email folder or try our <Link href='#'>Email</Link>, <Link href='#'>FAQs</Link> & <Link href='#'>troubleshooting</Link> page.
        </Typography>

        <Typography variant='body1' mb={4}>
          Unsure which email you used for therapywordofmouth.com account, or not sure what account you are associated with? <Link href='#'>Contact us</Link>
        </Typography>

        <Button
          fullWidth
          variant='contained'
          size='large'
          sx={{ mb: 1 }}
          href={paths.login}
          onClick={prev}
        >
          Go back to login
        </Button>
        <LoadingButton
          fullWidth
          size='large'
          onClick={resend}
          loading={forgetPasswordStatus === LOADING}
        >
          Resend password reset email
        </LoadingButton>
      </Paper>
    </Box>
  )
}

export default PasswordRecovery