import { useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import LoadingButton from "@mui/lab/LoadingButton";
import { useOnboarding } from "../../../contexts/onboarding";
import { useCommon } from "../../../contexts/common";
import { ReactComponent as ChevronDown } from "../../../assets/Icons/chevron-down.svg";
import { deleteRegistration } from "../../../services/auth.services";
import { useAlert } from "../../../contexts/Alert";
import sortAgeRanges from "../../../utils/sortAges";
import { stripeHolder } from "../../../utils/stripe";
import PlaceAutoComplete from "../../PlaceAutoComplete";
import { paymentFormSchema } from "../../../validations/registerValidation";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useSearchParams } from "react-router-dom";

function CheckoutForm({ open }) {
  const { statesList, fetchStatesList } = useCommon();
  const { onboardingState, signUp, uuid, confirmSubscription } = useOnboarding();
  const { sendAlert } = useAlert();
  const [loading, setLoading] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [countryState, setCountryState] = useState(null);
  const [cardIsInvalid, setCardIsInvalid] = useState({
    cardNumber: true,
    cardExpiry: true,
    cardCvc: true,
  });
  const defaultAddress = {
    address1: onboardingState?.address1,
    city: onboardingState?.city,
    zip_code: onboardingState?.zip_code,
    lat: onboardingState?.lat,
    lng: onboardingState?.lng,
    state: onboardingState?.state,
  }
  const [state, setState] = useState({
    name: "",
    ...defaultAddress
  });
  const [searchParams] = useSearchParams();
  const promoCity = searchParams.get("city");

  const { control, handleSubmit, setValue, formState: { errors, isValid }, reset } = useForm({
    resolver: zodResolver(paymentFormSchema),
    defaultValue: state
  });

  useEffect(() => {
    if (state) {
      reset(state);
    }
  }, [reset]);

  useEffect(() => {
    if (countryState) {
      const stateId = statesList.find((st) => st.name === countryState)?.id;
      setValue("state", stateId);
      setState((prevState) => ({
        ...prevState,
        state: stateId,
      }));
    }
  }, [countryState]);

  const handleStreetAddress = (ev) => {
    setState(prev => ({
      ...prev,
      address1: ev.target?.value,
    }));
    setValue("address1", ev.target?.value, { shouldValidate: true });
  };

  const handleAddressChange = (address) => {
    const streetAddress = address?.location?.split(",")[0] || '';
    setCountryState(address?.state);
    setState((prevState) => ({
      ...prevState,
      address1: streetAddress,
      city: address.city,
      zip_code: address.zipCode,
      lat: address?.lat,
      lng: address?.lng,
    }));
    setValue("address1", streetAddress, { shouldValidate: true });
    setValue("city", address.city);
    setValue("zip_code", address.zipCode);
  }

  // stripe items
  const stripe = useStripe();
  const elements = useElements();
  const getTaxes = async () => {
    const taxRates = await stripeHolder.taxRates?.list({ country: "US" });
    setLoading(true);
  };
  useEffect(() => {
    getTaxes();
  }, []);

  // function handleInput(ev) {
  //   setState((prevState) => ({
  //     ...prevState,
  //     [ev.target.name]: ev.target.value,
  //   }));
  // }

  elements?.getElement("cardNumber")?.on("change", function (event) {
    setCardIsInvalid((prevState) => ({
      ...prevState,
      cardNumber: event.empty || event.error,
    }));
  });

  elements?.getElement("cardExpiry")?.on("change", function (event) {
    setCardIsInvalid((prevState) => ({
      ...prevState,
      cardExpiry: event.empty || event.error,
    }));
  });

  elements?.getElement("cardCvc")?.on("change", function (event) {
    setCardIsInvalid((prevState) => ({
      ...prevState,
      cardCvc: event.empty || event.error,
    }));
  });

  const onSubmit = async () => {
    if (isValid && !cardIsInvalid.cardNumber && !cardIsInvalid.cardExpiry && !cardIsInvalid.cardCvc) {
      setLoading(true);
      try {
        const payloadData = { ...state };
        const name = payloadData.name;
        const plan_id = onboardingState.payment_method_id;
        delete payloadData.name;
        delete payloadData.payment_method_id;
        const age_served = onboardingState.age_served
          ? sortAgeRanges(onboardingState.age_served).join(",")
          : onboardingState.age_served?.join(",");
        const payload = {
          ...onboardingState,
          office_hours_day: onboardingState.office_hours_day?.join(","),
          office_hours_time: onboardingState.office_hours_time?.join(","),
          age_served: age_served,
          uuid,
          promo_city: promoCity,
          billing_address: {
            ...payloadData,
          },
        };

        Object.keys(payload).forEach((key) => {
          if (!payload[key]) {
            delete payload[key]
          }
        });

        const response = await signUp(payload);

        if (response?.data?.data?.client_secret) {
          const result = await stripe.confirmCardSetup(
            response?.data?.data?.client_secret,
            {
              payment_method: {
                card: elements.getElement("cardNumber"),
                billing_details: { name },
              },
            }
          );
          
          if (result.setupIntent) {
            await confirmSubscription(
              {
                payment_token: result.setupIntent.payment_method,
                plan_id,
                uuid,
                promo_city: promoCity
              },
              response?.data?.data
            );
          } else {
            sendAlert(
              "Something went wrong"
            );
          }
        }

        setLoading(false);
      } catch (error) {

        setLoading(false);
        sendAlert(
          "Something went wrong, please check your card details and make sure it's live card",
          "error"
        );
        await deleteRegistration(uuid);
      }
    } else {
      setIsInvalid(true);
    }
  };

  const elementStyles = {
    base: {
      color: "#000000",
      fontWeight: 400,
      fontSize: "14px",
      fontSmoothing: "antialiased",
    },
    invalid: {
      color: "#FA755A",
      ":focus": {
        color: "#FA755A",
      },
      "::placeholder": {
        color: "#000",
      },
    },
  };

  const elementClasses = {
    focus: "focus",
    empty: "empty",
    invalid: "invalid",
  };

  useEffect(() => {
    if (elements && open) {
      const cardNumberElement = elements.getElement("cardNumber");
      const cardExpiryElement = elements.getElement("cardExpiry");
      const cardCvcElement = elements.getElement("cardCvc");

      if (!cardNumberElement) {
        const cardNumber = elements.create("cardNumber", {
          style: elementStyles,
          classes: elementClasses,
          showIcon: true,
          iconStyle: "solid",
        });
        cardNumber.mount("#card-number");
      }

      if (!cardExpiryElement) {
        const cardExpiry = elements.create("cardExpiry", {
          style: elementStyles,
          classes: elementClasses,
        });
        cardExpiry.mount("#card-expiry");
      }

      if (!cardCvcElement) {
        const cardCvc = elements.create("cardCvc", {
          style: elementStyles,
          classes: elementClasses,
        });
        cardCvc.mount("#card-cvc");
      }
    }
  }, [elements, open]);

  useEffect(() => {
    fetchStatesList();
  }, []);

  return (
    <Box
      component="form"
      onSubmit={(ev) => handleSubmit(onSubmit)(ev)}
      sx={{
        pt: 1.5,
        pb: 1,
        px: 3,
      }}
    >
      <Typography variant="body2" fontWeight={600} mb={2}>
        Card information*
      </Typography>
      <Grid container rowSpacing={2} columnSpacing={1} mb={1.5}>
        <Grid item xs={12}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                label="Card Holder Name"
                value={field.value || state.name}
                name="name"
                id="name"
                error={!!errors.name}
                helperText={errors.name?.message}
                onChange={(e) => {
                  const { value } = e.target;
                  field.onChange(value);
                  setState((prevState) => ({
                    ...prevState,
                    name: value,
                  }));
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Box
            id="card-number"
            sx={{
              height: "65px",
              backgroundColor: "background.paper",
              border: "1px solid",
              borderRadius: 2,
              px: 2,
              py: 2.5,
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              cursor: "text",
              borderColor:
                isInvalid && cardIsInvalid.cardNumber
                  ? "error.main"
                  : "#A3A6A8",
              "& > *": {
                width: "100%",
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Box
            id="card-expiry"
            sx={{
              height: "65px",
              backgroundColor: "background.paper",
              border: "1px solid",
              borderRadius: 2,
              px: 2,
              py: 2.5,
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              cursor: "text",
              borderColor:
                isInvalid && cardIsInvalid.cardExpiry
                  ? "error.main"
                  : "#A3A6A8",
              "& > *": {
                width: "100%",
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <Box
            id="card-cvc"
            sx={{
              height: "65px",
              backgroundColor: "background.paper",
              border: "1px solid #A3A6A8",
              borderRadius: 2,
              px: 2,
              py: 2.5,
              boxSizing: "border-box",
              display: "flex",
              alignItems: "center",
              cursor: "text",
              borderColor:
                isInvalid && cardIsInvalid.cardCvc ? "error.main" : "#A3A6A8",
              "& > *": {
                width: "100%",
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div id="card-cvc" />
        </Grid>
      </Grid>
      <Typography variant="body2" fontWeight={600} mb={1}>
        Billing Address
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <PlaceAutoComplete
            name="address1"
            variant="outlined"
            label="Street address"
            fullWidth
            onChange={handleAddressChange}
            onKeyPress={handleStreetAddress}
            error={!!errors.address1}
            helperText={errors.address1?.message}
            control={control}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Controller
            name="city"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label="City"
                fullWidth
                error={!!errors.city}
                helperText={errors.city?.message}
                onChange={(e) => {
                  const { value } = e.target;
                  field.onChange(value);
                }}
              />
            )}
          />
        </Grid>
        <Grid item md={3.5} xs={6}>
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                variant="outlined"
                label="State"
                value={String(field.value)}
                fullWidth
                error={!!errors.state}
                helperText={errors.state?.message}
                SelectProps={{
                  IconComponent: () => (
                    <Box
                      sx={{
                        minWidth: 24,
                        width: 24,
                        height: 24,
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mr: 2,
                      }}
                    >
                      <ChevronDown width={12} height={6} />
                    </Box>
                  ),
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 220,
                        width: 250,
                      },
                    },
                  },
                }}
              >
                <MenuItem value={0} disabled>
                  Select a state
                </MenuItem>
                {statesList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item md={2.5} xs={6}>
          <Controller
            name="zip_code"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                label="ZIP code"
                fullWidth
                error={!!errors.zip_code}
                helperText={errors.zip_code?.message}
                onChange={(e) => {
                  field.onChange(e);
                  setState(prev => ({ ...prev, zip_code: e.target.value }));
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          textAlign: "center",
          pt: 3.25,
          pb: 1,
        }}
      >
        <LoadingButton
          disabled={!stripe}
          variant="contained"
          size="large"
          type="submit"
          fullWidth
          sx={{
            mb: 3.75,
          }}
          loading={loading}
        >
          Place Secure Order
        </LoadingButton>
        <Typography
          variant="caption"
          color="text.secondary"
          maxWidth={416}
          mx="auto"
          component="p"
        >
          By clicking "Place Secure Order", you agree to enroll in our annual
          subscription plan and to our Terms of Service
        </Typography>
      </Box>
    </Box>
  );
}

export default CheckoutForm;
