import React, { createRef, useEffect, useState } from "react";
import moment from "moment";
import paths from "../../constants/paths";
import { SUCCESS, treatment_program } from "../../constants/common";
import {
  Box,
  Avatar,
  Link,
  Typography,
  Button,
  TextField,
  IconButton,
  Menu,
  MenuItem
} from "@mui/material";
import { useHomePage } from "../../contexts/HomePage";
import { useBasicInfo } from "../../contexts/BasicInfo";
import { ReactComponent as MenuIcom } from "../../assets/Icons/menuIcom.svg";
import { ReactComponent as EditIcon } from "../../assets/Icons/edit-Post.svg";
import Reply from "./Reply";
import scrollTo from "../../helper/scrollTo";
import { validatePostContent } from "../../helper/Validations";

export default function Comment({ item, setLoading, loading, post_id, editComment }) {
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [isInnerReplayOpen, setIsInnerReplayOpen] = useState(false);
  const [replyData, setReply] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const {
    addReplyHandler,
    fetchPosts,
    handleLikeComment,
    handleUnlikeComment,
    getRepliesOfCommentHandler,
    updateReplyHandler,
    editReplayStatus,
    updateHomePagetate
  } = useHomePage();
  const { data: info } = useBasicInfo();

  const replyInput = createRef(null);

  const handleReply = () => {
    setShowReplyForm(!showReplyForm);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    editComment({ comment: '' });
    setAnchorEl(event.currentTarget);
  };

  function handleLikeClick(comment) {
    try {
      if (comment.liked) {
        handleUnlikeComment({ comment_id: comment.id }, post_id);
      } else {
        handleLikeComment({ comment_id: comment.id }, post_id);
      }
    } catch (error) {}
  }

  function handleEditComment() {
    handleClose();
    editComment(item);
  }
  
  function onReplyTextChange(ev) {
    setReplyText(ev.target.value)
  }

  function editReply(data) {
    setReply(data)
    setReplyText(data.reply)
  }

  useEffect(() => {
    if (item?.replies_count > 0 && showReplyForm) {
      getRepliesOfCommentHandler(item.id, post_id);
    }
  }, [item.id, showReplyForm]);


  const isValid = () => {
    return replyText && validatePostContent(replyText)
  }

  async function handleKeypDown(ev) {
    if (ev.key === "Escape" && ev.keyCode === 27) {
      if (replyData?.id) {
        setReply(null);
        setReplyText('')
      }
    }

    if(ev.key === "Enter" && ev.keyCode === 13) {
      ev.preventDefault();
      if (!isValid()) {
        setIsInvalid(true);
        return;
      }
      if (!loading) {
        try {
           setLoading(true);
           if (replyData?.id) {
            await updateReplyHandler({ reply: replyText, reply_id: replyData.id }, { comment_id: item.id, post_id })
          } else {
            await addReplyHandler({ comment_id: item.id, reply: replyText }, post_id);
          }
           setLoading(false);
          //  setShowReplyForm(false);
          setReplyText("");
        } catch (error) {
           setLoading(false);
        }
      }
    } 
  }

  useEffect(() => {
    if (replyData?.id && replyInput?.current) {
      replyInput?.current?.focus();
      const element = replyInput?.current;
      scrollTo(element, 100)
    }
  }, [replyData])

  useEffect(() => {
    if (editReplayStatus === SUCCESS) {
      updateHomePagetate("updateCommentStatus", "");
      setReplyText('')
      const replyRef = document.getElementById(`reply-${replyData?.id}`);
      if (replyRef) {
        scrollTo(replyRef, 100)
      }
      setReply(null);
    }
  }, [editReplayStatus])
  

  return (
    <Box key={item.id} id={`comment-${item.id}`}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
        }}
      >
        <Box>
          <Avatar
            sx={{
              width: "40px",
              height: "40px",
            }}
            src={item?.user?.main_image}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: "#F2F5F7",
            padding: "8px",
            marginLeft: "8px",
            width: "100%",
            borderRadius: "12px",
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Link
              href={paths.userProfile(
                item?.user?.uuid,
                item?.user?.account_type === treatment_program &&
                  item?.user?.roles_num === 1
                  ? treatment_program
                  : "user"
              )}
              variant="body2"
              fontWeight={700}
              color="text.primary"
              textTransform="capitalize"
            >
              {item?.user?.account_type === treatment_program &&
              item?.user?.roles_num === 1
                ? item?.user?.treatment_program_info?.treatment_program
                    ?.facility_name
                : `${item?.user?.first_name} ${item?.user?.last_name}${
                    item?.user?.info?.title || item?.user?.degrees?.length > 0
                      ? `, ${
                          item?.user?.degrees?.length > 0
                            ? item?.user?.degrees[0]?.degree.name
                            : item?.user?.info?.title
                        }`
                      : ""
                  }`}
            </Link>

            {item?.user?.id === info?.id && (
              <Box>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={Boolean(anchorEl) ? "long-menu" : undefined}
                  aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MenuIcom />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: "20ch",
                    },
                  }}
                >
                    <MenuItem
                      onClick={handleEditComment}
                    >
                      <EditIcon /> &nbsp;{' '}
                      Edit Comment
                    </MenuItem>
                </Menu>
              </Box>
            )}
          </Box>
          <Typography variant="body2">{item.comment}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          pb: 1,
          pt: 0.5,
        }}
      >
        <Box
          sx={{
            width: "82%",
            display: "flex",
            pb: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
              pl: "1%",
            }}
          >
            <Button
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                p: 0,
                minWidth: 0,
                color: item.liked ? "#0C8CE" : "#00000099",
                textAlign: "center",
                fontamily: "SF Pro Text",
                fontSize: " 0.75rem",
                fontWeight: 600,
                lineHeight: "1rem",
              }}
              onClick={() => handleLikeClick(item)}
            >
              Like {item?.likes_count > 0 ? ` (${item?.likes_count})` : ""}
            </Button>
            <Button
              variant="body2"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                p: 0,
                minWidth: 0,
                color: "#00000099",
                textAlign: "center",
                fontamily: "SF Pro Text",
                fontSize: " 0.75rem",
                fontWeight: 600,
                lineHeight: "1rem",
              }}
              onClick={handleReply}
            >
              Reply
              {item?.replies_count > 0 ? ` (${item?.replies_count})` : ""}
            </Button>
          </Box>
        </Box>
        <Typography
          style={{
            color: "#61676B",
            fontSize: "12px",
            marginRight: "8px",
          }}
          fontWeight={400}
        >
          {moment(item?.created_at).fromNow()}
        </Typography>
      </Box>
      {showReplyForm && (
        <Box sx={{ paddingLeft: "8%", paddingTop: "1%" }}>
          <Box>
            {item?.replies?.length > 0
              ? item?.replies.map((reply, index) => (
                <Reply
                  data={reply}
                  comment={item}
                  post_id={post_id}
                  index={index}
                  setIsInnerReplayOpen={setIsInnerReplayOpen}
                  isInnerReplayOpen={isInnerReplayOpen}
                  setLoading={setLoading}
                  loading={loading}
                  fetchPosts={fetchPosts}
                  editReply={editReply}
                />
              ))
              : null}
            {!isInnerReplayOpen && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: 1,
                  gap: 1,
                  mb: "1.5rem",
                  height: "2.25rem",
                }}
              >
                <Avatar
                  sx={{
                    width: 32,
                    height: 32,
                  }}
                  src={item?.user?.main_image}
                />
                <TextField
                  // variant='filled'
                  placeholder={`Reply on ${
                    item?.user?.account_type === treatment_program &&
                    item?.user?.roles_num === 1
                      ? item?.user?.treatment_program_info?.treatment_program
                          ?.facility_name
                      : item?.user?.first_name
                  }`}
                  fullWidth
                  style={{ borderColor: "#0C8CE9" }}
                  disabled={loading}
                  sx={{
                    input: {
                      p: "0.6875rem 0.5625rem",
                      fontSize: "0.9375rem",
                      lineHeight: "1.5rem",
                      maxHeight: "36px",
                      "&::placeholder": {
                        fontWeight: 700,
                        fontSize: "0.9375rem",
                        lineHeight: "1.5rem",
                        color: "#000",
                      },
                    },
                  }}
                  onChange={onReplyTextChange}
                  name="comment"
                  value={replyText}
                  inputRef={replyInput}
                  onKeyDown={handleKeypDown}
                  error={isInvalid && !validatePostContent(replyText)}
                  helperText={
                    isInvalid
                    && !validatePostContent(replyText)
                    && "Please enter valid comment"
                  }
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
      {/* {index < data?.comments?.length - 1 && <Divider />} */}
    </Box>
  );
}