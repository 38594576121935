import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import BasicInfo from '../../components/BasicProfile/BasicInfo';
import { ReactComponent as UserIcon } from '../../assets/Icons/user.svg'
import { ReactComponent as UploadIcon } from '../../assets/Icons/upload-cloud.svg'
import AboutMe from '../../components/BasicProfile/AboutMe';
import Contact from '../../components/BasicProfile/Contact';
import OfficeInfo from '../../components/BasicProfile/OfficeInfo';
import AddressCard from '../../components/BasicProfile/AddressCard';
import LinksCard from '../../components/BasicProfile/LinksCard';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { LOADING, SUCCESS } from '../../constants/common';

const ProfileBasic = () => {
  const { fetchInfo, editProfilePhoto, updatePhotoStatus, updateInfoState, data } = useBasicInfo();

  function handleFileInput(ev) {
    if (ev.target.files && ev.target.files[0]) {
      editProfilePhoto(ev.target.files[0]);
    }
  }

  useEffect(() => {
    fetchInfo()
  }, [])

  useEffect(() => {
    if (updatePhotoStatus === SUCCESS) {
      updateInfoState('updatePhotoStatus', '')
      fetchInfo()
    }
  }, [updatePhotoStatus])

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <UserIcon width={24} height={24} />
        <Typography variant='h4' ml={1}>Information</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <BasicInfo />
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'border.main',
              p: 3,
              borderRadius: 2
            }}
          >
            <Typography variant='h5' mb={1.5}>Upload an Image</Typography>
            {updatePhotoStatus === LOADING ? (
              <Box
                sx={{
                  border: '1px dashed',
                  borderColor: 'border.dark',
                  borderRadius: 1.5,
                  height: 158,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: 'primary.light'
                  }
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box
                sx={{
                  border: '1px dashed',
                  borderColor: 'border.dark',
                  borderRadius: 1.5,
                  height: 158,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: 'primary.light'
                  }
                }}
                component='label'
              >
                {data?.main_image ? (
                  <>
                    <Avatar
                      src={data?.main_image}
                      sx={{ width: '100%', height: 'auto', borderRadius: 0 }}
                    />
                    <Typography
                      variant='body2'
                      sx={{
                        textAlign: 'center',
                        position: 'absolute',
                        bottom: 0,
                        backgroundColor: 'rgba(0,0,0,0.4)',
                        width: '100%',
                        color: 'background.paper'
                      }}
                    >
                      Edit
                    </Typography>
                  </>
                ) : (
                  <Button
                    startIcon={<UploadIcon />}
                    sx={{
                      p: 0,
                      '&:hover': {
                        backgroundColor: 'transparent'
                      }
                    }}
                    component='span'
                  >
                    Upload
                  </Button>
                )}
                <Box
                  component='input'
                  sx={{
                    display: 'none'
                  }}
                  type='file'
                  onChange={handleFileInput}
                  accept='image/*'
                />

              </Box>
            )}

          </Box>
        </Grid>
      </Grid>
      <AboutMe />
      <Contact />
      <AddressCard />
      <LinksCard />
      <OfficeInfo />
    </Paper>
  )
}

export default ProfileBasic;