import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { LOADING, SUCCESS } from '../../constants/common';
import { useGeneralProfile } from '../../contexts/Organization';

import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg'
import { ReactComponent as CloseIcon } from '../../assets/Icons/close.svg'
import { ReactComponent as QuestionIcon } from '../../assets/Icons/question.svg';

const ProfileFaqs = () => {
    const {
        editFacilityFaqs,
        updateFacilityFaqsStatus,
        updateGeneralProfileState,
        facilityInfo,
        fetchFacilityInfo
    } = useGeneralProfile();

    const initalState = {
        question_1: '',
        question_2: '',
        question_3: '',
        question_4: '',
        question_5: '',
    }
    const [isEditMode, setIsEditMode] = useState(false);
    const [state, setState] = useState(initalState);
    const [isInvalid, setIsInvalid] = useState(false);
    
    function handleInput(ev) {
        setState((prevState) => ({
            ...prevState,
            [ev.target.name]: ev.target.value
        }))
    }

    function handleOpenEditMode() {
        setIsEditMode(true)
    }

    function handleCloseEditMode() {
        setIsEditMode(false)
        setIsInvalid(false);
        setState({
            question_1: facilityInfo?.info?.faq?.question_1 || '',
            question_2: facilityInfo?.info?.faq?.question_2 || '',
            question_3: facilityInfo?.info?.faq?.question_3 || '',
            question_4: facilityInfo?.info?.faq?.question_4 || '',
            question_5: facilityInfo?.info?.faq?.question_5 || '',
        })
    }


    function isValid() {
        return state.question_1
        && state.question_2
        && state.question_3
        && state.question_4
        && state.question_5
    }

    function handleSubmit(ev) {
        ev.preventDefault();
        if (isValid()) { 
            editFacilityFaqs({ faq: state });
        } else {
            setIsInvalid(true)
        }
    }

    useEffect(() => {
      if (updateFacilityFaqsStatus === SUCCESS) {
        updateGeneralProfileState('updateFacilityFaqsStatus', '')
        setIsEditMode(false)
        setIsInvalid(false);
        fetchFacilityInfo();
      }
    }, [updateFacilityFaqsStatus])
    
    useEffect(() => {
      if (facilityInfo) {
        setState({
            question_1: facilityInfo?.info?.faq?.question_1 || '',
            question_2: facilityInfo?.info?.faq?.question_2 || '',
            question_3: facilityInfo?.info?.faq?.question_3 || '',
            question_4: facilityInfo?.info?.faq?.question_4 || '',
            question_5: facilityInfo?.info?.faq?.question_5 || '',
        })
      }
    }, [facilityInfo])


    return (
        <Paper
            sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 3
            }}
            component='form'
            onSubmit={handleSubmit}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            color: 'border.dark'
                        }}
                    >
                        <QuestionIcon width={24} height={24} />
                    </Box>
                    <Typography variant='h4' ml={1}>FAQs</Typography>
                </Box>
                {isEditMode ? (
                    <Box>
                        <LoadingButton
                            type='submit'
                            loading={updateFacilityFaqsStatus === LOADING}
                            size='small'
                        >
                            Save
                        </LoadingButton>
                        <IconButton
                            sx={{ p: 0.375 }}
                            onClick={handleCloseEditMode}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                ) : (
                    <IconButton
                        sx={{ p: 0.375 }}
                        onClick={handleOpenEditMode}
                    >
                        <EditIcon />
                    </IconButton>
                )}
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='body1' fontWeight={600} mb={1}>{`What insurance does ${facilityInfo?.info?.facility_name} accept?`}</Typography>
                    <TextField
                        fullWidth
                        variant='outlined'
                        multiline
                        onChange={handleInput}
                        name='question_1'
                        error={isInvalid && !state.question_1}
                        rows={4}
                        value={state.question_1}
                        disabled={!isEditMode}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='body1' fontWeight={600} mb={1}>{`Where is ${facilityInfo?.info?.facility_name} located?`}</Typography>
                    <TextField
                        fullWidth
                        variant='outlined'
                        multiline
                        onChange={handleInput}
                        name='question_2'
                        error={isInvalid && !state.question_2}
                        rows={4}
                        value={state.question_2}
                        disabled={!isEditMode}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='body1' fontWeight={600} mb={1}>{`What insurance does ${facilityInfo?.info?.facility_name} accept?`}</Typography>
                    <TextField
                        fullWidth
                        variant='outlined'
                        multiline
                        onChange={handleInput}
                        name='question_3'
                        error={isInvalid && !state.question_3}
                        rows={4}
                        value={state.question_3}
                        disabled={!isEditMode}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='body1' fontWeight={600} mb={1}>{`How long is treatment?`}</Typography>
                    <TextField
                        fullWidth
                        variant='outlined'
                        multiline
                        onChange={handleInput}
                        name='question_4'
                        error={isInvalid && !state.question_4}
                        rows={4}
                        value={state.question_4}
                        disabled={!isEditMode}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='body1' fontWeight={600} mb={1}>{`Does ${facilityInfo?.info?.facility_name} offer detox?`}</Typography>
                    <TextField
                        fullWidth
                        variant='outlined'
                        multiline
                        onChange={handleInput}
                        name='question_5'
                        error={isInvalid && !state.question_5}
                        rows={4}
                        value={state.question_5}
                        disabled={!isEditMode}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default ProfileFaqs