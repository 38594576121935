import React, { createContext, useContext, useState, useCallback } from "react";
import {
  deleteAttachments,
  emailExists,
  getCities,
  getClientTypesList,
  getDegreesList,
  getStatesList,
  getTratmentProgramConstants,
  sendFeedback,
  uploadFile,
  uploadImage,
  getZipcodesList,
} from "../services/common.services";
import {
  ERROR,
  LOADING,
  SUCCESS,
  detox,
  facility_types,
  payment_options,
  services,
  sortData,
  treatment,
} from "../constants/common";
import { useAlert } from "./Alert";

const CommonContext = createContext({});

export const CommonContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();

  const [common, setCommon] = useState({
    statesList: [],
    fetchStatesStatus: "",
    degreesList: [],
    fetchDegreesStatus: "",
    clientTypesList: [],
    fetchClientTypesStatus: "",
    emailExists: false,
    emailExistsStatus: "",
    uploadFilesStatus: "",
    deleteAttachmentStatus: "",
    uploadImageStatus: "",
    fetchTreatmentTypesStatus: "",
    fetchServicesStatus: "",
    fetchDetoxListStatus: "",
    fetchPaymentOptionsStatus: "",
    fetchFacilityTypesStatus: "",
    treatmentTypesList: [],
    servicesList: [],
    detoxList: [],
    paymentOptionsList: [],
    facilityTypesList: [],
    citiesList: [],
    fetchCitiesStatus: '',
    zipcodesList: [],
    fetchZipcodesStatus: '',
  });

  function handleState(obj) {
    setCommon((prevState) => ({
      ...prevState,
      ...obj,
    }));
  }

  async function checkIfEmailExists(email) {
    handleState({ emailExistsStatus: LOADING });
    try {
      const response = await emailExists(email);
      handleState({
        emailExists: response.data.code,
        emailExistsStatus: SUCCESS,
      });

      return response;
    } catch (error) {
      handleState({ emailExistsStatus: ERROR });
      Promise.reject(error);
    }
  }

  async function fetchStatesList() {
    if (common.statesList.length === 0) {
      handleState({ fetchStatesStatus: LOADING });
      try {
        const response = await getStatesList();
        console.log(response.data.data)
        handleState({
          statesList: sortData(response.data.data),
          fetchStatesStatus: SUCCESS,
        });
      } catch (error) {
        handleState({ fetchStatesStatus: ERROR });
        Promise.reject(error);
      }
    }
  }

  function getStateIdByAbbreviation(abbreviation) {
    const state = common.statesList?.find(state => state.abbreviation === abbreviation);
    return state ? state.id : null;
  }

  async function fetchDegreesList() {
    if (common.degreesList.length === 0) {
      handleState({ fetchDegreesStatus: LOADING });
      try {
        const response = await getDegreesList();
        handleState({
          degreesList: response.data.data,
          fetchDegreesStatus: SUCCESS,
        });
      } catch (error) {
        handleState({ fetchDegreesStatus: ERROR });
        Promise.reject(error);
      }
    }
  }

  async function fetchClientTypesList() {
    if (common.clientTypesList.length === 0) {
      handleState({ fetchClientTypesStatus: LOADING });
      try {
        const response = await getClientTypesList();
        handleState({
          clientTypesList: response.data.data,
          fetchClientTypesStatus: SUCCESS,
        });
      } catch (error) {
        handleState({ fetchClientTypesStatus: ERROR });
        Promise.reject(error);
      }
    }
  }

  async function createFeedback(data) {
    handleState({ sendFeedbackStatus: LOADING });
    try {
      const response = await sendFeedback(data);
      handleState({
        sendFeedbackStatus: SUCCESS,
      });
      sendAlert("Feedback has been sent successfully", "success");

      return response;
    } catch (error) {
      handleState({ sendFeedbackStatus: ERROR });
      sendAlert(error.response?.data?.message, "error");
      Promise.reject(error);
    }
  }

  async function uploadImages(data) {
    handleState({ uploadImageStatus: LOADING });
    try {
      const response = await uploadImage(data);

      handleState({
        uploadImageStatus: SUCCESS,
      });
      return response;
    } catch (error) {
      handleState({ uploadImageStatus: ERROR });
      sendAlert(
        (error.response?.data?.errors?.file || [])[0] ||
          error.response?.data?.message,
        "error"
      );
      Promise.reject(error);
    }
  }

  async function uploadFiles(data) {
    handleState({ uploadFilesStatus: LOADING });
    try {
      const response = await uploadFile(data);

      handleState({
        uploadFilesStatus: SUCCESS,
      });
      return response;
    } catch (error) {
      handleState({ uploadFilesStatus: ERROR });
      sendAlert(
        (error.response?.data?.errors?.file || [])[0] ||
          error.response?.data?.message,
        "error"
      );
      Promise.reject(error);
    }
  }

  async function removeAttachments(data) {
    handleState({ deleteAttachmentStatus: LOADING });
    try {
      const response = await deleteAttachments(data);

      handleState({
        deleteAttachmentStatus: SUCCESS,
      });
      return response;
    } catch (error) {
      handleState({ deleteAttachmentStatus: ERROR });
      sendAlert(error.response?.data?.message, "error");
      Promise.reject(error);
    }
  }

  async function fetchTreatmentTypes(type) {
    if (type === treatment) {
      handleState({ fetchTreatmentTypesStatus: LOADING });
    } else if (type === services) {
      handleState({ fetchServicesStatus: LOADING });
    } else if (type === detox) {
      handleState({ fetchDetoxListStatus: LOADING });
    } else if (type === payment_options) {
      handleState({ fetchPaymentOptionsStatus: LOADING });
    } else if (type === facility_types) {
      handleState({ fetchFacilityTypesStatus: LOADING });
    }
    try {
      const response = await getTratmentProgramConstants(type);
      if (type === treatment) {
        handleState({
          treatmentTypesList: response.data.data,
          fetchTreatmentTypesStatus: SUCCESS,
        });
      } else if (type === services) {
        handleState({
          servicesList: response.data.data,
          fetchServicesStatus: SUCCESS,
        });
      } else if (type === detox) {
        handleState({
          detoxList: response.data.data,
          fetchDetoxListStatus: SUCCESS,
        });
      } else if (type === payment_options) {
        handleState({
          paymentOptionsList: response.data.data,
          fetchPaymentOptionsStatus: SUCCESS,
        });
      } else if (type === facility_types) {
        handleState({
          facilityTypesList: response.data.data,
          fetchFacilityTypesStatus: SUCCESS,
        });
      }
    } catch (error) {
      if (type === treatment) {
        handleState({ fetchTreatmentTypesStatus: ERROR });
      } else if (type === services) {
        handleState({ fetchServicesStatus: ERROR });
      } else if (type === detox) {
        handleState({ fetchDetoxListStatus: ERROR });
      } else if (type === payment_options) {
        handleState({ fetchPaymentOptionsStatus: ERROR });
      } else if (type === facility_types) {
        handleState({ fetchFacilityTypesStatus: ERROR });
      }
      Promise.reject(error);
    }
  }


  const fetchCities = useCallback(async (data) => {
    handleState({ fetchCitiesStatus: LOADING });
    try {
      const response = await getCities(data);
      if (response && response.data) {
        handleState({
          citiesList: sortData(response.data),
          fetchCitiesStatus: SUCCESS,
        });
      }
    } catch (error) {
      handleState({ fetchCitiesStatus: ERROR });
    }
  }, []);
  
  const fetchZipcodes = useCallback(async (data) => {
    handleState({ fetchZipcodesStatus: LOADING });
    try {
      const response = await getZipcodesList(data);
      handleState({
        zipcodesList: response.data,
        fetchZipcodesStatus: SUCCESS,
      });
    } catch (error) {
      handleState({ fetchZipcodesStatus: ERROR });
      Promise.reject(error);
    }
  })

  const state = {
    ...common,
    fetchStatesList,
    fetchDegreesList,
    fetchClientTypesList,
    getStateIdByAbbreviation,
    checkIfEmailExists,
    createFeedback,
    uploadImages,
    uploadFiles,
    removeAttachments,
    fetchTreatmentTypes,
    fetchCities,
    handleState,
    fetchZipcodes,
  };

  return (
    <CommonContext.Provider value={state}>{children}</CommonContext.Provider>
  );
};

export const useCommon = () => useContext(CommonContext);
