import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { LOADING, SUCCESS } from "../../constants/common";
import { ReactComponent as ChevronDown } from "../../assets/Icons/chevron-down.svg";
import { useCommon } from "../../contexts/common";
import { useDegrees } from "../../contexts/Degrees";
import { useBasicInfo } from "../../contexts/BasicInfo";
import { useOnboarding } from "../../contexts/onboarding";
import { validateOrganizationName, yearValidation } from "../../helper/Validations";

const DegreeDrawer = ({ onClose, open, data, isLocalEditing }) => {
  const { degreesList, fetchDegreesList } = useCommon();
  const {
    fetchUserDegrees,
    createUserDegree,
    editUserDegree,
    updateDegreeState,
    updateStatus,
    createStatus,
    openConfirmationModal,
    deleteStatus,
  } = useDegrees();
  const { fetchInfo, data: info } = useBasicInfo();
  const { setOnboardingState } = useOnboarding();

  const initialState = {
    professional_degree_id: "0",
    degree_year: "",
    college_name: "",
    practice_year: "",
  };

  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);

  const isEdit = Boolean(data);

  function handleClose() {
    setState(initialState);
    setIsInvalid(false);
    onClose();
  }

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
  }

  const yearValidation = (year) => {
    const currentYear = new Date().getFullYear();
    return year >= 1900 && year <= currentYear;
  };
  
  const getDegreeYearError = () => {
    if (state.degree_year && !yearValidation(state.degree_year)) {
      return "Degree year must be valid between 1900 and the current year";
    }
    if (state.degree_year && state.practice_year && state.practice_year < state.degree_year) {
      return "Degree year must be earlier or equal to practice year";
    }
    return null;
  };
  
  const getPracticeYearError = () => {
    if (state.practice_year && !yearValidation(state.practice_year)) {
      return "Practice year must be between 1900 and the current year";
    }

    if (state.degree_year && state.practice_year && state.practice_year < state.degree_year) {
      return "Practice year must be after or equal to degree year";
    }
    return null;
  };
  
  function isValid() {
    const isDegreeYearValid = !getDegreeYearError();
    const isPracticeYearValid = !getPracticeYearError();
    const isCollegeNameValid = !state.college_name || validateOrganizationName(state.college_name);
  
    return +state.professional_degree_id && isDegreeYearValid && isPracticeYearValid && isCollegeNameValid;
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid()) {
      if (isEdit) {
        if (isLocalEditing) {
          setOnboardingState((prevState) => ({
            ...prevState,
            degrees: (prevState.degrees || []).map((item, index) => {
              if (data.index === index) {
                return state;
              }
              return item;
            }),
          }));
          handleClose();
        } else {
          editUserDegree({ ...state, id: data.id });
        }
      } else {
        if (isLocalEditing) {
          setOnboardingState((prevState) => ({
            ...prevState,
            degrees: [...(prevState.degrees || []), { ...state }],
          }));
          handleClose();
        } else {
          setOnboardingState((prevState) => ({
            ...prevState,
            degrees: [...(prevState.degrees || []), { ...state }],
          }));
          createUserDegree(state);
        }
      }
    } else setIsInvalid(true);
  }

  useEffect(() => {
    if (open) fetchDegreesList();
  }, [open]);

  useEffect(() => {
    if (isEdit && open) {
      setState({
        professional_degree_id: data.professional_degree_id,
        degree_year: data.degree_year,
        college_name: data.college_name,
        practice_year: data.practice_year,
      });
    }
  }, [data, open, isEdit]);

  useEffect(() => {
    if (updateStatus === SUCCESS) {
      updateDegreeState("updateStatus", "");
      fetchUserDegrees();
      handleClose();
    }
  }, [updateStatus]);

  useEffect(() => {
    if (createStatus === SUCCESS) {
      updateDegreeState("createStatus", "");
      fetchUserDegrees();
      if (info?.degrees?.length < 1) fetchInfo();
      handleClose();
    }
  }, [createStatus]);

  useEffect(() => {
    if (deleteStatus === SUCCESS) {
      handleClose();
    }
  }, [deleteStatus]);

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          pt: 0,
        }}
        component="form"
        onSubmit={handleSubmit}
      >
        <Box pb={3} px={3}>
          <Typography variant="h4" mb={1}>
            Education
          </Typography>
          <Typography variant="body1" mb={1}>
            Where did you go to school, and when did you begin your practice?
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                variant="outlined"
                label="Professional Degree"
                fullWidth
                SelectProps={{
                  IconComponent: () => (
                    <Box
                      sx={{
                        minWidth: 24,
                        width: 24,
                        height: 24,
                        display: "inline-flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mr: 2,
                      }}
                    >
                      <ChevronDown width={12} height={6} />
                    </Box>
                  ),
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 220,
                        width: 250,
                      },
                    },
                  },
                }}
                onChange={handleInput}
                value={state.professional_degree_id || 0}
                name="professional_degree_id"
                error={isInvalid && !+state.professional_degree_id}
              >
                <MenuItem value={0} disabled>
                  Select a degree
                </MenuItem>
                {degreesList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Year graduated (YYYY)"
                fullWidth
                onChange={handleInput}
                value={state.degree_year || ""}
                error={
                  isInvalid
                  && getDegreeYearError()
                }
                helperText={
                  isInvalid 
                  && getDegreeYearError()
                }
                name="degree_year"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Practice Year (YYYY)"
                fullWidth
                onChange={handleInput}
                value={state.practice_year || ""}
                name="practice_year"
                error={
                  isInvalid 
                  && getPracticeYearError()
                }
                helperText={
                  isInvalid 
                  && getPracticeYearError()
                }
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="School Name"
                fullWidth
                onChange={handleInput}
                value={state.college_name || ""}
                name="college_name"
                error={isInvalid && !validateOrganizationName(state.college_name)}
                helperText={
                  isInvalid &&
                  !validateOrganizationName(state.college_name) &&
                  "Please add valid school/college name"
                }
              />
            </Grid>
            {isEdit && (
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                  onClick={openConfirmationModal}
                  size="small"
                  color="error"
                  sx={{
                    minWidth: 120,
                    backgroundColor: "error.light",
                    color: "error.main",
                  }}
                >
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box
          sx={{
            boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.08)",
            px: 1,
            pt: 3,
            pb: 5.625,
            display: "flex",
            alignItems: "center",
            gap: 1,
            position: "sticky",
            width: "100%",
            backgroundColor: "background.paper",
            bottom: 0,
            zIndex: 9,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              color: "primary.main",
            }}
            size="large"
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            size="large"
            fullWidth
            type="submit"
            loading={[createStatus, updateStatus].includes(LOADING)}
          >
            {isEdit ? "Save" : "Create"}
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DegreeDrawer;
