import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';

import { LOADING, SUCCESS } from '../../constants/common';
import CheckboxListItem from '../CheckboxListItem';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useClientServed } from '../../contexts/ClientServed';

const ClientsServedDrawer = ({ onClose, open }) => {
  const {
    updateClientTypesState,
    fetchCurrentlyServed,
    currently_served,
    fetchClientTypesStatus,
    editCurrentlyServed,
    updateClientTypesStatus,
    fetchUserClientTypes
  } = useClientServed();

  const { fetchInfo, data: info } = useBasicInfo();
  const [selectedClientTypes, setSelectedClientTypes] = useState([]);

  const handleClose = () => {
    onClose();
  }

  const selectCurrentlyServed = (data) => {
    setSelectedClientTypes((prevState) => {
      const exists = !!prevState?.find((item) => +item === data.id);
      if (exists) {
        return (
          prevState.filter((item) => +item !== data.id)
        )
      }
      return (
        [
          ...prevState,
          data.id
        ]
      )
    })
  }

  const saveCurrentlyServed = () => {
    editCurrentlyServed({
      populations: selectedClientTypes
    })
  }

  const disabled = () => {
    return selectedClientTypes?.length < 1
  }

  useEffect(() => {
    if (open) {
      fetchCurrentlyServed();
    }
  }, [open])


  useEffect(() => {
    if (updateClientTypesStatus === SUCCESS) {
      updateClientTypesState('updateClientTypesStatus', '');
      fetchUserClientTypes();
      fetchInfo();
      handleClose();
    }
  }, [updateClientTypesStatus, handleClose])

  useEffect(() => {
    setSelectedClientTypes(info?.currently_served?.reduce((acc, item) => {
      if (item.is_active) {
        return [...acc, item.id.toString()]
      }
      return acc;
    }, []))
  }, [currently_served])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Clients Currently Accepting</Typography>
          <Typography variant='body1' mb={1}>Select the types of clients you work with</Typography>

          <>
            <Divider sx={{ mb: 1 }} />
            <Box>
              <Grid container spacing={2.5} mb={2}>
                {fetchClientTypesStatus === LOADING ? (
                  Array.from({ length: 6 }).map((_, index) => (
                    <Grid item xs={6} key={index}>
                      <Skeleton animation='wave' />
                    </Grid>
                  ))
                ) : (
                  currently_served.map((item) => (
                    <CheckboxListItem
                      key={item.id}
                      data={item}
                      selectItem={selectCurrentlyServed}
                      selectedItems={selectedClientTypes}
                    />
                  ))
                )}
              </Grid>
            </Box>
          </>
        </Box>
        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            onClick={saveCurrentlyServed}
            loading={[updateClientTypesStatus].includes(LOADING)}
            disabled={disabled()}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default ClientsServedDrawer;