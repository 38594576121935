import React, { createRef, useEffect, useState } from 'react'
import { useBasicInfo } from '../../contexts/BasicInfo';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { ReactComponent as MenuIcom } from "../../assets/Icons/menuIcom.svg";
import { ReactComponent as EditIcon } from "../../assets/Icons/edit-Post.svg";
import paths from '../../constants/paths';
import { treatment_program } from '../../constants/common';
import { useHomePage } from '../../contexts/HomePage';
import moment from 'moment';
const Reply = ({
    data,
    comment,
    post_id,
    index,
    setLoading,
    loading,
    editReply
}) => {
    const { data: info } = useBasicInfo();
    const {
        addReplyHandler,
        handleLikeReply,
        handleUnLikeReply,
    } = useHomePage()
    const [anchorEl, setAnchorEl] = useState(null);
    const [isInnerReplayOpen, setIsInnerReplayOpen] = useState(null);
    const [innerReplyText, setInnerReplyText] = useState("");
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    async function handleLikeClick(reply) {
        try {
          if (reply.liked) {
            await handleUnLikeReply({ reply_id: reply.id }, { comment_id: comment.id, post_id });
          } else {
           await handleLikeReply({ reply_id: reply.id }, { comment_id: comment.id, post_id });
          }
        } catch (error) {}
    }
    const handleToggleInnerReplay = (index, data) => {
        setIsInnerReplayOpen((prevState) => !prevState);
    };
    async function onInnerReplay(ev) {
        ev.preventDefault();
        if (!loading) {
            try {
            setLoading(true);
            const payload = {
                comment_id: comment.id,
                reply: innerReplyText,
                parent_reply_id: data?.id,
                metioned_user_id: data?.user?.id
            }
            await addReplyHandler(payload, post_id);
            setLoading(false);
            setIsInnerReplayOpen(false);
            setInnerReplyText("");
            } catch (error) {
                setLoading(false);
            }
        }
    }

    function handleEditReply() {
        handleClose();
        editReply(data);
    }
    

    return (
        <Box key={data.id} id={`reply-${data.id}`}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",

                    justifyContent: "flex-start",
                    p: 0.5,
                }}
            >
                <Box
                    sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    }}
                >
                    <Box>
                        <Avatar
                            sx={{
                            width: "32px",
                            height: "32px",
                            }}
                            src={data?.user?.main_image}
                        />
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: "#F2F5F7",
                            padding: "8px",
                            marginLeft: "8px",
                            width: "100%",
                            borderRadius: "12px",
                        }}
                    >
                        <Box
                            sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                            }}
                        >
                            <Link
                                href={paths.userProfile(
                                    data?.user?.uuid,
                                    data?.user?.account_type === treatment_program &&
                                    data?.user?.roles_num === 1
                                    ? treatment_program
                                    : "user"
                                )}
                                variant="body2"
                                fontWeight={700}
                                color="text.primary"
                                textTransform="capitalize"
                            >
                                {data?.user?.account_type === treatment_program &&
                                data?.user?.roles_num === 1
                                    ? data?.user?.treatment_program_info?.treatment_program
                                        ?.facility_name
                                    : `${data?.user?.first_name} ${data?.user?.last_name}${
                                        data?.user?.info?.title ||
                                        data?.user?.degrees?.length > 0
                                        ? `, ${
                                            data?.user?.degrees?.length > 0
                                                ? data?.user?.degrees[0]?.degree.name
                                                : data?.user?.info?.title
                                            }`
                                        : ""
                                    }`}
                            </Link>
                            {data?.user?.id === info?.id && (
                            <Box>
                                <IconButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-controls={Boolean(anchorEl) ? "long-menu" : undefined}
                                    aria-expanded={Boolean(anchorEl) ? "true" : undefined}
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                >
                                    <MenuIcom />
                                </IconButton>
                                <Menu
                                    id="long-menu"
                                    MenuListProps={{
                                        "aria-labelledby": "long-button",
                                    }}
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: {
                                        maxHeight: 48 * 4.5,
                                        width: "20ch",
                                        },
                                    }}
                                >
                                    <MenuItem
                                        onClick={handleEditReply}
                                    >
                                        <EditIcon /> &nbsp;{' '}
                                        Edit reply
                                    </MenuItem>
                                </Menu>
                            </Box>
                            )}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 0.5
                            }}
                        >
                            {data?.metioned_user && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: "primary.main",
                                        whiteSpace: 'nowrap'                        
                                    }}
                                >
                                    {data?.metioned_user?.account_type === treatment_program &&
                                        data?.metioned_user?.roles_num === 1
                                            ? `@${data?.metioned_user?.treatment_program_info?.treatment_program
                                                ?.facility_name}`
                                            : `@${data?.metioned_user?.first_name} ${data?.metioned_user?.last_name}`}{' '}
                                </Typography>
                            )}
                            
                            <Typography variant="body2">{data.reply}</Typography>
                        </Box>
                        
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        pb: 1,
                        pl: "10%",
                        pt: "2%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            gap: 2,
                        }}
                    >
                    <Button
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                            p: 0,
                            minWidth: 0,
                            color: data.liked ? "#0C8CE" : "#00000099",
                            textAlign: "center",
                            fontamily: "SF Pro Text",
                            fontSize: " 0.75rem",
                            fontWeight: 600,
                            lineHeight: "1rem",
                        }}
                        onClick={() => handleLikeClick(data)}
                    >
                        Like {data?.likes_count > 0 ? ` (${data?.likes_count})` : ""}
                    </Button>
                    <Button
                        onClick={handleToggleInnerReplay}
                        variant="body2"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                            p: 0,
                            minWidth: 0,
                            color: "#00000099",
                            textAlign: "center",
                            fontamily: "SF Pro Text",
                            fontSize: " 0.75rem",
                            fontWeight: 600,
                            lineHeight: "1rem",
                        }}
                    >
                        Reply
                    </Button>
                    </Box>
                    <Box>
                    <Typography
                        variant="caption"
                        color="text.secondary"
                        component="p"
                        sx={{ fontSize: "14px", color: "#61676B" }}
                    >
                        {moment(data?.created_at).fromNow()}
                    </Typography>
                    </Box>
                </Box>
            </Box>
            {isInnerReplayOpen && (
                <Box
                    sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                    gap: 1,
                    mb: "1.5rem",
                    height: "2.25rem",
                    pl: "4rem",
                    }}
                    component="form"
                    onSubmit={(ev) => onInnerReplay(ev, data)}
                >
                    <Avatar
                        sx={{
                            width: 32,
                            height: 32,
                        }}
                        src={data?.user?.main_image}
                    />
                    <Box
                        sx={{
                            border: "1px solid",
                            borderColor: "secondary.main",
                            borderRadius: 1.5,
                            minHeight: 40,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            px: 1,
                        }}
                    >
                        <Typography
                            variant="body2"
                            sx={{
                                color: "primary.main",
                                whiteSpace: 'nowrap'                        
                            }}
                        >
                            {data?.user?.account_type === treatment_program &&
                                data?.user?.roles_num === 1
                                    ? `@${data?.user?.treatment_program_info?.treatment_program
                                        ?.facility_name}`
                                    : `@${data?.user?.first_name} ${data?.user?.last_name}`}{' '}
                        </Typography>
                        
                        <TextField
                            // variant='filled'
                            // placeholder={`Reply on ${
                            //     data?.user?.account_type === treatment_program &&
                            //     data?.user?.roles_num === 1
                            //         ? data?.user?.treatment_program_info?.treatment_program
                            //             ?.facility_name
                            //         : data?.user?.first_name
                            // }`}
                            fullWidth
                            style={{ borderColor: "#0C8CE9" }}
                            sx={{
                            input: {
                                p: "0.6875rem 0.5625rem",
                                fontSize: "0.9375rem",
                                lineHeight: "1.5rem",
                                maxHeight: "36px",
                                "&::placeholder": {
                                fontWeight: 700,
                                fontSize: "0.9375rem",
                                lineHeight: "1.5rem",
                                color: "#000",
                                },
                            },
                            }}
                            InputProps={{
                            sx: {
                                "&.Mui-focused": {
                                fieldset: {
                                    border: "0!important",
                                },
                                },
                                "&:hover": {
                                fieldset: {
                                    border: "0!important",
                                },
                                },
                                fieldset: {
                                border: 0,
                                },
                            },
                            }}
                            onChange={(e) => setInnerReplyText(e.target.value)}
                            name="comment"
                            value={innerReplyText}
                            autoFocus
                        />
                    </Box>
                </Box>
            )}
      </Box>
    )
}

export default Reply