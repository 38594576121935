import React, { useEffect, useState } from 'react'
import Availability from '../../components/Availability';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { SUCCESS, treatment_program } from '../../constants/common';
import { useAuth } from '../../contexts/Auth';
import { useGeneralProfile } from '../../contexts/Organization';

const ProfileAvailability = () => {
  const { fetchInfo, updateAvailabilityStatus, editAvailability, updateInfoState, data } = useBasicInfo();
  const { fetchFacilityInfo, facilityInfo } = useGeneralProfile();
  const { account_type } = useAuth();

  const initialState = {
    office_hours_day: [],
    office_hours_time: [],
  }

  const [state, setState] = useState(initialState);

  function setData(obj) {
    setState((prevState) => ({
        ...prevState,
        ...obj
    }))
  }

  function onSave() {
    editAvailability({
      office_hours_day: state.office_hours_day?.join(','),
      office_hours_time: state.office_hours_time?.join(','),
      account_type: account_type === treatment_program ? account_type : 'user'
    });
  }

  const info = account_type === treatment_program ? facilityInfo : data;

  useEffect(() => {
    if (account_type === treatment_program)
      fetchFacilityInfo();
    else
      fetchInfo();
  }, [])
  
  useEffect(() => {
    if (data || facilityInfo) {
      setState((prevState) => ({
        ...prevState,
        office_hours_day: info?.info?.office_hours_day?.split(',') || [],
        office_hours_time: info?.info?.office_hours_time?.split(',') || [],
      }))
    }
  }, [data, facilityInfo])

  useEffect(() => {
    if (updateAvailabilityStatus === SUCCESS) {
      updateInfoState('updateAvailabilityStatus', '')
      if (account_type === treatment_program)
        fetchFacilityInfo();
      else
        fetchInfo();
    }
  }, [updateAvailabilityStatus, account_type])
  
  return (
    <Availability
        widthIcon
        sx={{
            p: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 3
        }}
        onSave={onSave}
        setData={setData}
        data={state}
    />
  )
}

export default ProfileAvailability;