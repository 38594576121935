import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as EditIcon } from '../../../assets/Icons/edit-3.svg'
import OfficeInfoDrawer from './OfficeInfoDrawer';
import { useBasicInfo } from '../../../contexts/BasicInfo';
const OfficeInfo = () => {
  const { data } = useBasicInfo();
  const [open, setOpen] = useState(false)

  const formatPhoneNumber = (value) => {
    let formatted = '';
    if (value?.length > 0) {
      if (value.length === 11 && value.startsWith('1')) {
        formatted = `+1 (${value.slice(1, 4)}) ${value.slice(4, 7)}-${value.slice(7, 11)}`;
      } else if (value.length <= 10) {
        formatted = `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
      }
    }

    return formatted;
  };

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  return (
    <>
      <Box
        sx={{
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: 'border.main',
          p: 3,
          borderRadius: 2
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 1.5
          }}
        >
          <Typography variant='h5'>Additional Office Information</Typography>
          <IconButton
            sx={{ p: 0.375 }}
            onClick={handleOpen}
          >
            <EditIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1.5,
            gap: 0.5
          }}
        >
          <Typography variant='body2' fontWeight={600}>Fax Number:</Typography>
          <Typography variant='body2' color='text.secondary'>{formatPhoneNumber(data?.info?.fax_number)}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <Typography variant='body2' fontWeight={600}>Office Number:</Typography>
          <Typography variant='body2' color='text.secondary'>{formatPhoneNumber(data?.info?.office_number)}</Typography>
        </Box>
      </Box>
      <OfficeInfoDrawer
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

export default OfficeInfo;