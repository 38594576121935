import React, { createContext, useContext, useState } from 'react';
import { ERROR, LOADING, SUCCESS, colleagues, connections, favorites } from '../constants/common';
import { connectUser, getConnections,getNetworkConnections, getFavorites, getNetwork } from '../services/network.services';
import { useAlert } from './Alert';


const NetworkContext = createContext({});

export const NetworkContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();
    const [networkState, setNetworkState] = useState({
        fetchStatus: '',
        connectStatus: '',
        network: {
            data: []
        },
        fitlersData:{ page: 1 }
    });

    function handleState(obj) {
        setNetworkState((prevState) => {
          const newData = {
            ...prevState,
            ...obj
          };
          console.log("newData====>",newData);

          return {
            ...prevState,
            ...obj
        }
        })

    }

    async function fetchNetwork(params, type, withoutLoader,isProfile) {
      console.log("isProfile",isProfile)
      if (!withoutLoader)
        handleState({ fetchStatus: LOADING })
      try {
        let response
        if (!type || type === connections)
        if(isProfile){
          response = await getNetworkConnections(params)
        }else{
          response = await getNetwork({ ...params, user_types:["first"]});
        }
        if (type === favorites)
          response = await getFavorites(params);
        if (type === colleagues)
          response = await getNetwork(params);
          handleState({
            network: response?.data?.data || { data: [] },
            fetchStatus: SUCCESS
        })
      } catch (error) {
        sendAlert(error.response?.data?.message, 'error')
        handleState({ fetchStatus: ERROR, network: { data: [] } })
        Promise.reject(error)
      }
    }

    async function connectWithOthers(data) {
        handleState({ connectStatus: LOADING })
      try {
        const response = await connectUser(data);
        handleState({
            connectStatus: SUCCESS
        })
        sendAlert('Connected successfully!', 'success')
        return response;
      } catch (error) {
        handleState({ connectStatus: ERROR })
        sendAlert(error.response?.data?.message, 'error')
        Promise.reject(error)
      }
    }

    function updateNetworkState(field, value) {
        handleState({ [field]: value })
    }
    
    const setFitlersData = (data)=> {
      console.log("data===>",data)
      handleState({ fitlers: { ...networkState.fitlers,...data} })
  }

    const state = {
        ...networkState,
        fetchNetwork,
        connectWithOthers,
        updateNetworkState,
        setFitlersData
    };

    return (
        <NetworkContext.Provider value={state}>
        {children}
        </NetworkContext.Provider>
    );
};

export const useNetwork = () => useContext(NetworkContext);