import { useEffect, useState, useRef } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

const useFilters = (initialFilters) => {
  const [filters, setFilters] = useState(initialFilters);
  const location = useLocation();
  const navigate = useNavigate();
  const isInitialLoad = useRef(true);
  const previousFilters = useRef(initialFilters);

  const cleanFilters = (filters) => {
    const newFilters = Object.fromEntries(
      Object.entries(filters || {}).filter(([_, value]) =>
        value !== undefined &&
        value !== '' &&
        !(Array.isArray(value) && value.length === 0)
      )
    );
    return newFilters;
};

const hasFiltersChanged = (prev, current) => {
  if (prev && current) {
    return Object.keys(current).some(key => {
      if (key === 'page') return false;
      return prev[key] !== current[key];
    });
  }
  return false;
};

  useEffect(() => {
    if (isInitialLoad.current) {
      const params = queryString.parse(location.search, { arrayFormat: 'bracket-separator', arrayFormatSeparator: '|' });
      setFilters((prev) => ({ ...prev, ...params }));
      previousFilters.current = params;
      isInitialLoad.current = false;
    } else {
      const cleanedFilters = cleanFilters(filters);
      if (hasFiltersChanged(previousFilters.current, cleanedFilters)) {
        cleanedFilters.page = 1;
        setFilters(prev => ({...prev, page: 1}))
      }
      const params = queryString.stringify(cleanedFilters, { arrayFormat: 'bracket-separator', arrayFormatSeparator: '|' });
      navigate(`${location.pathname}?${params}`, { replace: true });
      previousFilters.current = cleanedFilters;
    }
  }, [filters, location.pathname, location.search, navigate]);

  return [filters, setFilters];
};

export default useFilters;
