import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  Paper,
  Typography,
  Box,
  IconButton,
  Button,
  TextField,
  MenuItem,
  Tooltip,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ReactComponent as CloseIcon } from "../../assets/Icons/close.svg";
import { ReactComponent as LinkIcon } from "../../assets/Icons/link.svg";
import isEmail from "validator/lib/isEmail";

import { useTeams } from "../../contexts/Teams";
import { useGeneralProfile } from "../../contexts/Organization";
import { validateEmail } from "../../helper/Validations";
import { LOADING, SUCCESS } from "../../constants/common";
import CheckBox from "../CheckBox";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 580,
  p: 0,
  overflow: "hidden",
};

const initialState = {
  emails: [],
  role: "",
  email: "",
  invite_as_mental_health: false,
};

function InvitationModal({ open, onClose }) {
  const {
    inviteMember,
    inviteMemberStatus,
    updateTeamsState,
    copyInvitationLink,
    copyInvitationLinkStatus,
    fetchTeams,
    teams,
  } = useTeams();
  const { facilityInfo } = useGeneralProfile();

  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isCopyMode, setIsCopyMode] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    fetchTeams();
  }, []);

  useEffect(() => {
    if (inviteMemberStatus === SUCCESS) {
      updateTeamsState("inviteMemberStatus", "");
      fetchTeams();
      handleClose();
    }
  }, [inviteMemberStatus, fetchTeams, updateTeamsState]);

  useEffect(() => {
    if (copyInvitationLinkStatus === SUCCESS) {
      updateTeamsState("copyInvitationLinkStatus", "");
    }
  }, [copyInvitationLinkStatus, updateTeamsState]);

  const handleClose = () => {
    onClose();
    setState(initialState);
    setIsInvalid(false);
  };

  const handleEmails = (ev) => {
    const emails = ev.target.value.split(" ").filter(Boolean);
    const lastEmail = emails[emails.length - 1];
    if (lastEmail && isEmail(lastEmail) && !state.emails.includes(lastEmail)) {
      setState((prevState) => ({
        ...prevState,
        emails: [...prevState.emails, ...emails],
        email: "",
      }));
    } else {
      setState((prevState) => ({ ...prevState, email: "" }));
    }
  };

  const handleEmailsInput = (ev) => {
    if (ev.key === " " || ev.code === "Space" || ev.keyCode === 32) {
      handleEmails(ev);
    }
  };

  const removeEmail = (index) => {
    setState((prevState) => ({
      ...prevState,
      emails: prevState.emails.filter((_, i) => i !== index),
    }));
  };

  const handleInput = (ev) => {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
  };

  const handleEmailsInputClick = () => {
    ref.current?.focus();
  };

  const isValid = (isCopy) => {
    return (!isCopy ? state.emails.length > 0 : true) && state.role && !validateEmail(state.email);
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    setIsCopyMode(false);
    if (isValid()) {
      inviteMember({
        team_id: teams?.id,
        user_id: teams?.user_id,
        emails: state.emails.reduce((acc, item) => {
          if (item && isEmail(item)) {
            if (!Boolean(acc.find((email) => email === item))) {
              return [...acc, item];
            }
          }
          return acc;
        }, []),
        role: state.role,
        invite_as_mental_health: state.invite_as_mental_health,
      });
    } else {
      setIsInvalid(true);
    }
  };

  const copyLink = () => {
    setIsCopyMode(true);
    if (isValid(true)) {
      copyInvitationLink({
        team_id: facilityInfo?.team?.id || teams?.id,
        user_id: facilityInfo?.id || teams?.user_id,
        role: state.role,
        invite_as_mental_health: state.invite_as_mental_health,
      });
    } else {
      setIsInvalid(true);
    }
  };

  const handleCheckBox = (ev) => {
    setState((prevState) => ({
      ...prevState,
      [ev.target.value]: ev.target.checked,
    }));
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
      <Paper sx={modalStyle}>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", pt: 3, px: 3, pb: 1.5 }}>
          <Typography variant="h4">
            Send invitation to {facilityInfo?.info?.facility_name}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0.25, color: "border.dark" }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box component="form" onSubmit={handleSubmit} sx={{ display: "flex", flexDirection: "column", gap: 1, px: 3, pb: 3 }}>
          <Typography variant="body2" sx={{ fontWeight: 600, color: "text.primary" }}>
            To:
          </Typography>
          <Box
            sx={{
              border: "1px solid",
              borderColor: isInvalid && !isCopyMode && (state.emails.length < 1 || state.email) ? "error.main" : "secondary.main",
              borderRadius: 1.5,
              minHeight: 127,
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              p: 2,
              gap: 1,
            }}
            onClick={handleEmailsInputClick}
          >
            {state.emails?.map((item, index) => (
              <Typography
                key={item}
                variant="body2"
                size="small"
                sx={{
                  backgroundColor: "primary.light",
                  color: "primary.main",
                  py: 0.5,
                  px: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1,
                  borderRadius: 1.5,
                }}
              >
                {item}
                <IconButton sx={{ color: "rgba(0 ,0, 0, 0.5)", p: 0 }} onClick={() => removeEmail(index)}>
                  <CloseIcon width={18} height={18} />
                </IconButton>
              </Typography>
            ))}
            <TextField
              onChange={handleInput}
              onKeyUp={handleEmailsInput}
              onBlur={handleEmails}
              name="email"
              value={state.email}
              inputProps={{
                ref,
                sx: {
                  "&:not(textarea)": { py: 0.5, px: 0 },
                },
              }}
              InputProps={{
                sx: {
                  "&.Mui-focused fieldset, &:hover fieldset, fieldset": { border: "0!important" },
                },
              }}
              error={isInvalid && !validateEmail(state.email)}
              helperText={
                isInvalid
                && !validateEmail(state.email)
                && "Please enter valid email"
              }
            />
          </Box>
          <Typography variant="body2" sx={{ fontWeight: 600, color: "text.primary" }}>
            Invite as:
          </Typography>
          <TextField
            fullWidth
            select
            onChange={handleInput}
            error={isInvalid && !state.role}
            name="role"
            value={state.role}
            sx={{ mb: 2 }}
          >
            <MenuItem value="" disabled />
            <MenuItem value="team_admin">Admin</MenuItem>
            <MenuItem value="team_assistant">Assistant Admin</MenuItem>
          </TextField>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <CheckBox
              label="Assign an Individual Profile"
              value="invite_as_mental_health"
              onChange={handleCheckBox}
              checked={state.invite_as_mental_health}
            />
            <Tooltip
              arrow
              placement="top"
              title={
                <Typography textAlign="center" variant="body2">
                  This allows the individual to be listed in search results, displaying their availability.
                  They will be clearly associated with your organization, showing your logo and organization
                  name next to their name.
                </Typography>
              }
            >
              <IconButton
                sx={{
                  color: "warning.main",
                  border: "1px solid",
                  borderColor: "warning.main",
                  backgroundColor: "transparent",
                  p: 0.25,
                  minWidth: 21,
                  minHeight: 21,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="15"
                  viewBox="0 0 100 150"
                >
                  <rect
                    x="45"
                    y="20"
                    width="10"
                    height="80"
                    fill="currentColor"
                  />
                  <circle cx="50" cy="120" r="10" fill="currentColor" />
                </svg>
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              py: 2.5,
              mb: 2,
            }}
          >
            <Button
              startIcon={<LinkIcon />}
              sx={{
                p: 0,
                "&,&:hover": {
                  backgroundColor: "transparent",
                },
              }}
              disableRipple
              onClick={copyLink}
              disabled={copyInvitationLinkStatus === LOADING}
            >
              Copy invite link
            </Button>
            <Typography variant="body2" color="text.secondary">
              Link expire in 7 days
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton
              variant="contained"
              loading={inviteMemberStatus === LOADING}
              type="submit"
            >
              Send Request
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}

export default InvitationModal;
