import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';

import { useTreatment } from '../../contexts/teamentModalities';
import { LOADING, SUCCESS, treatment_program } from '../../constants/common';
import CheckboxListItem from '../CheckboxListItem';
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useAuth } from '../../contexts/Auth';

const TreatmentDrawer = ({ onClose, open }) => {
    const {
        fetchTreatmentModalities,
        treatmentModalities,
        fetchStatus,
        editTreatmentModalities,
        createStatus,
        updateTreatmentModalitiesState,
        fetchUserTreatmentModalities,
    } = useTreatment();

    const { fetchInfo, data: info } = useBasicInfo();
    const { account_type } = useAuth();
    const [selectedTreatmentModalities, setSelectedTreatmentModalities] = useState([]);

    function handleClose() {
        onClose();
    }

    function selectTreatmentModality(data) {
        setSelectedTreatmentModalities((prevState) => {
            const exists = !!prevState.find((item) => +item === data.id);
            if (exists) {
                return (
                    prevState.filter((item) => +item !== data.id)
                )
            }
            return (
                [
                    ...prevState,
                    data.id
                ]
            )
        })
    }

    function saveTreatmentModalities() {
        editTreatmentModalities({
            account_type: account_type === treatment_program ? treatment_program : 'user',
            treatment_modalities: selectedTreatmentModalities
        })
    }

    function disabled() {
        return selectedTreatmentModalities.length < 1 
    }
    
    useEffect(() => {
        if (open) {
            fetchTreatmentModalities({ account_type: account_type === treatment_program ? treatment_program : 'user' });
        }
    }, [open])
    

    useEffect(() => {
        if (createStatus === SUCCESS) {
            updateTreatmentModalitiesState('createStatus', '');
            fetchUserTreatmentModalities({ account_type: account_type === treatment_program ? treatment_program : 'user' });
            if (info?.treatment_modalities?.length < 1)
                fetchInfo();
            handleClose();
        }
    }, [createStatus, handleClose])

    useEffect(() => {
        setSelectedTreatmentModalities(treatmentModalities.reduce((acc, item) => {
            if (item.selected) {
                return [...acc, item.id.toString()]
            }
            return acc;
        }, []))
    }, [treatmentModalities])

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            anchor='right'
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    pt: 8
                }}
            >
                <Box pb={3} px={3}>
                    <Typography variant='h4' mb={1}>Treatment Modalities</Typography>
                    <Typography variant='body1' mb={1}>Select the treatment modalities you utilize.</Typography>
                    <>
                        <Divider sx={{ mb: 3 }} />
                        {Boolean(info?.info?.has_medication_prescriber) && (
                            <>
                                <Typography variant='body2' mb={2} fontWeight='bold'>Medication Management</Typography>
                                <Grid container spacing={2.5} mb={2}>
                                    {fetchStatus === LOADING ? (
                                        <>
                                            <Grid item xs={6}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Skeleton animation="wave" />
                                            </Grid>
                                        </>
                                    ) : (
                                        treatmentModalities.reduce((acc, item) => {
                                            if (item.type === 'prescribers') {
                                                return [...acc, (
                                                    <CheckboxListItem
                                                        key={item.id}
                                                        data={item}
                                                        selectItem={selectTreatmentModality}
                                                        selectedItems={selectedTreatmentModalities}
                                                    />
                                                )]
                                            }
                                            return acc
                                        }, [])
                                    )}
                                </Grid>
                                <Divider sx={{ mb: 2 }} />
                            </>
                        )}
                        <Typography variant='body2' mb={2} fontWeight='bold'>Psychotherapy</Typography>
                        <Grid container spacing={2.5} mb={2}>
                            {fetchStatus === LOADING ? (
                                <>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                </>
                            ) : (
                                treatmentModalities.reduce((acc, item) => {
                                    if (item.type === 'therapist') {
                                        return [...acc, (
                                            <CheckboxListItem
                                                key={item.id}
                                                data={item}
                                                selectItem={selectTreatmentModality}
                                                selectedItems={selectedTreatmentModalities}
                                            />
                                        )]
                                    }
                                    return acc
                                }, [])
                            )}
                        </Grid>
                    </>
                </Box>
                
                <Box
                    sx={{
                        boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
                        px: 1,
                        pt: 3,
                        pb: 5.625,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        position: 'sticky',
                        width: '100%',
                        backgroundColor: 'background.paper',
                        bottom: 0,
                        zIndex: 9
                    }}
                >
                    <Button
                        variant='outlined'
                        color='secondary'
                        sx={{
                            color: 'primary.main'
                        }}
                        size='large'
                        fullWidth
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        size='large'
                        fullWidth
                        onClick={saveTreatmentModalities}
                        loading={[createStatus].includes(LOADING)}
                        disabled={disabled()}
                    >
                        Save
                    </LoadingButton>
                </Box>
            </Box>
        </Drawer>
    )
}

export default TreatmentDrawer;