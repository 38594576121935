import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import { LOADING, SUCCESS, treatment_program } from '../../../../constants/common';
import { useGeneralProfile } from '../../../../contexts/Organization';
import { useAuth } from '../../../../contexts/Auth';
import { validateAbout } from '../../../../helper/Validations';

const AboutMeDrawer = ({ onClose, open }) => {
  const { editAboutMe, updateInfoState, updateAboutMeStatus, fetchInfo, data } = useBasicInfo();
  const { editFacilityAboutMe, updateFacilityAboutMeStatus, updateGeneralProfileState, fetchFacilityInfo, facilityInfo } = useGeneralProfile();
  const { account_type } = useAuth();
  const info = account_type === treatment_program ? facilityInfo : data;

  const initialState = {
    about_me: ''
  }
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);

  function handleClose() {
    setState(initialState);
    setIsInvalid(false)
    onClose();
  }

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  function isValid() {
    return validateAbout(state.about_me)
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid()) {
      if (account_type === treatment_program)
        editFacilityAboutMe(state);
      else
        editAboutMe(state);
    } else
      setIsInvalid(true)
  }

  useEffect(() => {
    if (updateAboutMeStatus === SUCCESS) {
      updateInfoState('updateAboutMeStatus', '')
      fetchInfo();
      handleClose();
    }
  }, [updateAboutMeStatus]);

  useEffect(() => {
    if (updateFacilityAboutMeStatus === SUCCESS) {
      updateGeneralProfileState('updateFacilityAboutMeStatus', '')
      fetchFacilityInfo();
      handleClose();
    }
  }, [updateFacilityAboutMeStatus]);

  useEffect(() => {
    if (data && open) {
      setState({
        about_me: info?.info?.about_me
      })
    }
  }, [data, open])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
        component='form'
        onSubmit={handleSubmit}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>About Me</Typography>
          <Divider sx={{ mb: 4 }} />

          <TextField
            fullWidth
            variant='outlined'
            multiline
            label='About Me'
            onChange={handleInput}
            name='about_me'
            rows={4}
            value={state.about_me}
            error={isInvalid && !validateAbout(state.about_me)}
            helperText={
              isInvalid &&
              !validateAbout(state.about_me) &&
              "Please add valid text in About"
            }
          />
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            type='submit'
            loading={updateAboutMeStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default AboutMeDrawer;