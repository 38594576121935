import React, { useEffect } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from '@mui/material/Avatar';

import BasicInfo from './components/BasicInfo';
import Contact from './components/Contact';

import { ReactComponent as UploadIcon } from '../../assets/Icons/upload.svg'
import { ReactComponent as BuildingIcon } from '../../assets/Icons/building.svg'
import { LOADING, SUCCESS } from '../../constants/common';
import { useGeneralProfile } from '../../contexts/Organization';
import AboutMe from '../../components/BasicProfile/AboutMe';

const ProfileOrganization = () => {
    const { facilityInfo, fetchFacilityInfo, editFacilityPhoto, updatePhotoStatus, updateGeneralProfileState } = useGeneralProfile();

    function handleFileInput(ev) {
        if (ev.target.files && ev.target.files[0]) {
            editFacilityPhoto(ev.target.files[0]);
        }
    }
    
    useEffect(() => {
        if (updatePhotoStatus === SUCCESS) {
            updateGeneralProfileState('updatePhotoStatus', '')
            fetchFacilityInfo()
        }
    }, [updatePhotoStatus])

    return (
        <Paper
            sx={{
                p: 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 3
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'border.dark'
                    }}
                >
                    <BuildingIcon width={24} height={24} />
                </Box>
                <Typography variant='h4' ml={1}>Organization Settings</Typography>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={8}>
                    <BasicInfo />
                </Grid>
                <Grid item xs={4}>
                    <Box
                        sx={{
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            borderColor: 'border.main',
                            p: 3,
                            borderRadius: 2
                        }}
                    >
                        <Typography variant='h5' mb={3}>Upload logo</Typography>
                        {updatePhotoStatus === LOADING ? (
                            <Box
                                sx={{
                                    border: '1px dashed',
                                    borderColor: 'border.dark',
                                    borderRadius: 1.5,
                                    height: 145,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    '&:hover': {
                                        backgroundColor: 'primary.light'
                                    }
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Box
                                sx={{
                                    border: '1px dashed',
                                    borderColor: 'border.dark',
                                    borderRadius: 1.5,
                                    height: 142,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 1,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    cursor: 'pointer',
                                    position: 'relative',
                                    '&:hover': {
                                        backgroundColor: 'primary.light'
                                    }
                                }}
                                component='label'
                            >
                                {facilityInfo.main_image ? (
                                    <Avatar
                                        sx={{ width: '100%', height: 'auto', borderRadius: 0 }}
                                        src={facilityInfo.main_image}
                                    />
                                ) : (
                                    <>
                                        <UploadIcon />
                                        <Typography variant='body2' color='text.primary'>Upload Logo</Typography>
                                        <Box
                                            component='input'
                                            sx={{
                                                display: 'none'
                                            }}
                                            type='file'
                                            accept='image/*'
                                            onChange={handleFileInput}
                                        />
                                    </>
                                )}
                            </Box>
                        )}
                        <Box
                            component='ul'
                            sx={{
                                mx: 0,
                                my : 3,
                                pl: 2
                            }}
                        >
                            <Typography variant='caption' color='text.secondary' component='li' lineHeight='150%'>Use a solid background color.</Typography>
                            <Typography variant='caption' color='text.secondary' component='li' lineHeight='150%'>Use a graphical logo or image rather than text.</Typography>
                            <Typography variant='caption' color='text.secondary' component='li' lineHeight='150%'>Leave some space around your icon.</Typography>
                            <Typography variant='caption' color='text.secondary' component='li' lineHeight='150%'>Upload an image that is 265 px square or larger.</Typography>
                        </Box>
                        <Box
                            component='label'
                        >
                            <Box
                                component='input'
                                sx={{
                                    display: 'none'
                                }}
                                type='file'
                                accept='image/*'
                                onChange={handleFileInput}
                            />
                            <Button
                                fullWidth
                                sx={{
                                    backgroundColor: 'primary.light'
                                }}
                                component='span'
                            >
                                Upload
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Contact />
            <AboutMe />
        </Paper>
    )
}

export default ProfileOrganization;