import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import { LOADING, SUCCESS } from '../../../../constants/common';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import { ReactComponent as ChevronDown } from '../../../../assets/Icons/chevron-down.svg'
import { useCommon } from '../../../../contexts/common';
import useGeolocation from '../../../../hooks/use-GeoLocation';
import { 
  validateName, 
  validateStreetAddress, 
  validateZipcode 
} from '../../../../helper/Validations';

const AddressDrawer = ({ onClose, open }) => {
  const { statesList, fetchStatesList } = useCommon();
  const { editAddress, updateInfoState, updateAddressStatus, fetchInfo, data } = useBasicInfo();
  const initialState = {
    address1: '',
    zip_code: '',
    state: '0',
  }
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);
  const { location, loading } = useGeolocation(state.zip_code);

  function handleClose() {
    setState(initialState);
    setIsInvalid(false)
    onClose();
  }

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  function isValid() {
    return validateStreetAddress(state.address1)
      && state.city
      && validateName(state.city)
      && validateZipcode(state.zip_code)
      && state.state
      && validateName(state.city)
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid()) {
      editAddress(state);
    } else
      setIsInvalid(true)
  }

  useEffect(() => {
    if (updateAddressStatus === SUCCESS) {
      updateInfoState('updateAddressStatus', '')
      fetchInfo();
      handleClose();
    }
  }, [updateAddressStatus])

  useEffect(() => {
    fetchStatesList()
  }, [])

  useEffect(() => {
    if (data && open) {
      setState({
        address1: data?.info?.address?.address1,
        city: data?.info?.address?.city,
        zip_code: data?.info?.address?.zip_code,
        state: data?.info?.address?.state?.id,
      })
    }
  }, [data, open])

  useEffect(() => {
    if ((location.latitude && location.longitude) && state.zip_code && open) {
      setState((prevState) => ({
        ...prevState,
        ...location
      }))
    } else {
      setState((prevState) => {
        delete prevState.latitude
        delete prevState.longitude
        return prevState
      })
    }
  }, [location, state.zip_code, open])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
        component='form'
        onSubmit={handleSubmit}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Address</Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                label='Street address'
                fullWidth
                onChange={handleInput}
                value={state.address1 || ''}
                name='address1'
                error={isInvalid && !validateStreetAddress(state.address1)}
                helperText={
                  isInvalid &&
                  !validateStreetAddress(state.address1) &&
                  "Please add valid street address"
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant='outlined'
                label='City'
                fullWidth
                onChange={handleInput}
                value={state.city || ''}
                name='city'
                error={isInvalid && !validateName(state.city)}
                helperText={
                  isInvalid &&
                  !validateName(state.city) &&
                  "Please add valid city name"
                }
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                select
                variant='outlined'
                label='State'
                fullWidth
                SelectProps={{
                  IconComponent: () => (
                    <Box
                      sx={{
                        minWidth: 24,
                        width: 24,
                        height: 24,
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mr: 2
                      }}
                    >
                      <ChevronDown width={12} height={6} />
                    </Box>
                  ),
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 220,
                        width: 250,
                      },
                    },
                  }
                }}
                onChange={handleInput}
                value={state.state || 0}
                name='state'
                error={isInvalid && !+state.state}
                helperText={
                  isInvalid &&
                  !+state.state &&
                  "Please select any state"
                }
              >
                <MenuItem value={0} disabled>
                  Select a state
                </MenuItem>
                {statesList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                variant='outlined'
                label='ZIP code'
                fullWidth
                onChange={handleInput}
                value={state.zip_code || ''}
                name='zip_code'
                error={isInvalid && !validateZipcode(state.zip_code)}
                helperText={
                  isInvalid &&
                  !validateZipcode(state.zip_code) &&
                  "Please add valid zip code"
                }
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            type='submit'
            loading={updateAddressStatus === LOADING}
            disabled={loading}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default AddressDrawer;