import React from "react";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";

import paths from "../../constants/paths";
import { ReactComponent as CheckCircleIcon } from "../../assets/Icons/verify-outlined.svg";
import { useBasicInfo } from "../../contexts/BasicInfo";
import {
  full_and_need_to_refer_out,
  treatment_program,
} from "../../constants/common";
import { useGeneralProfile } from "../../contexts/Organization";
import { useAuth } from "../../contexts/Auth";
import { ReactComponent as AddIcon } from "../../assets/Icons/add.svg";
import { connectUser } from "../../services/network.services";
import { useAlert } from "../../contexts/Alert";

const TherapistCardInfo = ({ data }) => {
  const { facilityInfo } = useGeneralProfile();
  const { account_type } = useAuth();
  const { sendAlert } = useAlert();
  const info = account_type === treatment_program ? facilityInfo : data;

  console.log("data-=====>",data)
  const connect = (id) => {
    connectUser({ connected_user_id: id,account_type:data.account_type})
      .then((res) => {
        sendAlert("Connected successfully!", "success");
      })
      .catch((error) => {
        sendAlert(error.response?.data?.message, "error");
      });
  };
  return (
    <Paper sx={{ py: 3 }}>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          px: 2,
        }}
      >
        <Badge
          color={
            info?.info?.current_status !== full_and_need_to_refer_out
              ? "success"
              : "error"
          }
          overlap="circular"
          badgeContent=" "
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            mb: 1.5,
            "& .MuiBadge-badge": {
              border: "1px solid #FFFFFF",
            },
          }}
        >
          <Avatar
            sx={{
              width: 72,
              height: 72,
            }}
            src={data?.user_profile_image}
          />
        </Badge>
        <Link
          href={
            info.profile_link ??
            paths.userProfile(info?.uuid, info?.account_type)
          }
          variant="h5"
          mb={1.5}
          color="text.primary"
          textTransform="capitalize"
        >
          {account_type === treatment_program
            ? facilityInfo?.info?.facility_name
            : `${data.first_name || ""} ${data.last_name || ""}`}
        </Link>
        <Typography
          variant="caption"
          color="text.secondary"
          component="p"
          lineHeight="18px"
        >
          {data?.user?.info?.headline}
        </Typography>
        {data?.email_verified_at && (
          <Typography
            variant="body2"
            color="success.main"
            display="flex"
            alignItems="center"
            fontWeight={600}
            gap={0.5}
          >
            <CheckCircleIcon />
            Verified Account
          </Typography>
        )}
      </Box>
      <Divider sx={{ my: 1.5 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {" "}
        {!data.connected? (
          <Button
            variant="outlined"
            style={{ borderRadius: "25px", margin: "0px 20px" }}
            onClick={() => connect(data.id)}
            startIcon={<AddIcon />}
          >
            Connect
          </Button>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Link
              href={data.profile_link}
              variant="body2"
              mt={2}
              textTransform="capitalize"
            >
              View full profile
            </Link>
          </Box>
        )}
      </Box>
    </Paper>
  );
};

export default TherapistCardInfo;
