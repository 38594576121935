import React, { useEffect } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import LoadingButton from '@mui/lab/LoadingButton';
import CheckBox from "../../components/CheckBox";

import { useLanguages } from '../../contexts/Languages';
import { useState } from 'react';
import { LOADING, SUCCESS } from '../../constants/common';

const LanguageDrawer = ({ onClose, open }) => {
    const [languages, setLanguages] = useState([]);

    const {
        fetchLanguageList,
        editLanguages,
        languagesList,
        fetchLanguagesStatus,
        updateLanguageStatus,
    } = useLanguages();

    function handleClose() {
        setLanguages([]);
        onClose();
    }

  

    function handleSave() {
        editLanguages({ language_ids: languages });        
    }

    useEffect(() => {
        if (open)
        fetchLanguageList(true);        
    }, [open])

    useEffect(() => {
        if (updateLanguageStatus === SUCCESS) {
          setLanguages({ updateLanguageStatus: '' });
          fetchLanguageList(true);
          handleClose();
        }
    }, [updateLanguageStatus])
    useEffect(() => {
        languagesList?.length &&  setLanguages(languagesList.filter((item) => item.selected).map(item=>item.id))
    }, [languagesList])
    

    function handleCheckBox(ev) {
        const id =parseInt(ev.target.name)
        setLanguages(languages=>{
            let updatedLanguages
            if(languages?.length===0){
                updatedLanguages= [...languages,id]
            }else if(!languages.includes(id)){
                updatedLanguages= [...languages,id]
            }else{
                updatedLanguages= languages.filter(item=>item !==id)
            }
            return updatedLanguages
        });
      }

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            anchor='right'
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    pt: 8
                }}
            >
                <Box pb={3} px={3}>
                    <Typography variant='h4' mb={1}>Languages</Typography>
                    <Typography variant='body1' mb={1}>Select your languages</Typography>
                    <Divider sx={{ mb: 4 }} />
                    <Typography variant='body2' mb={2} fontWeight='bold'>Languages</Typography>
                        <Grid container spacing={2.5}>
                            {fetchLanguagesStatus === LOADING ? (
                                <>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                </>
                            ) : (
                                languagesList?.length > 0 && languagesList?.map((item) => (
                                    <Grid item xs={6} key={item.id}>
                                        <CheckBox
                                            label={item.name}
                                            name={item.id}
                                            onChange={handleCheckBox}
                                            checked={languages.includes(item.id)}
                                         />  
                                    </Grid>
                                ))
                            )}
                        </Grid>
                </Box>
                <Box
                    sx={{
                        boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
                        px: 1,
                        pt: 3,
                        pb: 5.625,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        position: 'sticky',
                        width: '100%',
                        backgroundColor: 'background.paper',
                        bottom: 0,
                        zIndex: 9
                    }}
                >
                    <Button
                        variant='outlined'
                        color='secondary'
                        sx={{
                            color: 'primary.main'
                        }}
                        size='large'
                        fullWidth
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        size='large'
                        fullWidth
                        onClick={handleSave}
                        loading={updateLanguageStatus === LOADING}
                        disabled={!languages.length}
                    >
                        Save
                    </LoadingButton>
                </Box>
            </Box>
        </Drawer>
    )
}

export default LanguageDrawer;