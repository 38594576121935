import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import paths from "../../constants/paths";
import { useAuth } from "../../contexts/Auth";
import isEmail from "validator/lib/isEmail";
import { SUCCESS } from "../../constants/common";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { Alert } from "@mui/material";
import { ReactComponent as ShieldExclamation } from "../../assets/Icons/shield-exclamation.svg";

const Login = () => {
  const navigate = useNavigate();

  const { signIn, loginStatus, updateAuthState, isLogginBlocked, countDown } = useAuth();

  const initialState = {
    email: "",
    password: "",
  };

  const [state, setState] = useState(initialState);

  const [isInValid, setIsInValid] = useState();

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value,
    }));
  }

  function isValid() {
    return state.email && isEmail(state.email) && state.password;
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid()) {
      signIn(state);
    } else setIsInValid(true);
  }

  useEffect(() => {
    if (loginStatus === SUCCESS) {
      setState(initialState);
      setIsInValid(false);
      navigate(paths.home);
      updateAuthState("loginStatus", "");
    }
  }, [loginStatus]);

  return (
    <Container className="hideShadow" sx={{ minHeight: "75vh" }} maxWidth="xs">
      <div className="formStyle">
        <Typography variant="h3" sx={{ marginBottom: "8px" }} mb={2}>
          Sign in
        </Typography>
        <Typography variant="body2" sx={{ marginBottom: "26px" }}>
          Welcome back
        </Typography>
        <Box component="form" mb={2} onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Email"
                type="email"
                fullWidth
                onChange={handleInput}
                value={state.email}
                name="email"
                error={isInValid && (!state.email || !isEmail(state.email))}
                disabled={isLogginBlocked}
                helperText={
                  isInValid
                  && !isEmail(state.email)
                  && "Please enter valid email"
                }
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Password"
                type="password"
                fullWidth
                onChange={handleInput}
                value={state.password}
                name="password"
                error={isInValid && !state.password}
                disabled={isLogginBlocked}
              />
            </Grid>

            <Grid item xs={12}>
              <Button variant="contained" size="large" fullWidth type="submit" disabled={isLogginBlocked}>
                Sign in
              </Button>
            </Grid>
          </Grid>
        </Box>
        {isLogginBlocked && (
          <Alert
            severity="error"
            sx={{
              backgroundColor: "#FAD4D4",
              borderRadius: 1,
              border: "1px solid #EE6363",
              mb: 1.5,
              mt: -1.5,
              py: 0.5,
              px: 1,
              alignItems: "center",
              color: "text.primary",
            }}
            icon={<ShieldExclamation />}
          >
            Security Lockout! Try again in {countDown} minutes.
          </Alert>
        )}
        <Typography textAlign="center" variant="body2" fontWeight={600}>
          <Link href={paths.forgetPassword}>Forgot Password</Link> or{" "}
          <Link href={paths.onboarding}>Create an account</Link>
        </Typography>
      </div>
    </Container>
  );
};

export default Login;
