import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBasicInfo } from '../../../../contexts/BasicInfo';
import { LOADING, SUCCESS } from '../../../../constants/common';
import { validateHeadline, validateName, validateText, validateTitle } from '../../../../helper/Validations';

const BasicInfoDrawer = ({ onClose, open }) => {
  const { editBasicInfo, updateInfoState, updateBasicInfoStatus, fetchInfo, data } = useBasicInfo();
  const initialState = {
    title: '',
    first_name: '',
    last_name: '',
    headline: ''
  }
  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);

  function handleClose() {
    setState(initialState);
    setIsInvalid(false)
    onClose();
  }

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  function isValid() {
    return state.title
      && validateTitle(state.title)
      && state.first_name
      && validateName(state.first_name)
      && state.last_name
      && validateName(state.last_name)
      && validateHeadline(state.headline)
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid())
      editBasicInfo(state);
    else
      setIsInvalid(true)
  }

  useEffect(() => {
    if (updateBasicInfoStatus === SUCCESS) {
      updateInfoState('updateBasicInfoStatus', '')
      fetchInfo();
      handleClose();
    }
  }, [updateBasicInfoStatus])

  useEffect(() => {
    if (data && open) {
      setState({
        title: data?.info?.title,
        first_name: data.first_name,
        last_name: data.last_name,
        headline: data?.info?.headline,
      })
    }
  }, [data, open])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
        component='form'
        onSubmit={handleSubmit}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Basic Information</Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                label='Title'
                onChange={handleInput}
                name='title'
                error={isInvalid && !validateTitle(state.title)}
                value={state.title}
                helperText={
                  isInvalid &&
                  !validateTitle(state.title) &&
                  "Please add valid Title"
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant='outlined'
                label='First Name'
                onChange={handleInput}
                name='first_name'
                error={isInvalid && !validateName(state.first_name)}
                helperText={
                  isInvalid &&
                  !validateName(state.first_name) &&
                  "Please add valid first name"
                }
                value={state.first_name}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                variant='outlined'
                label='Last Name'
                onChange={handleInput}
                name='last_name'
                error={isInvalid && !validateName(state.last_name)}
                helperText={
                  isInvalid &&
                  !validateName(state.last_name) &&
                  "Please add valid last name"
                }
                value={state.last_name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                variant='outlined'
                label='Headline'
                onChange={handleInput}
                name='headline'
                value={state.headline}
                error={isInvalid && !validateHeadline(state.headline)}
                helperText={
                  isInvalid && 
                  !validateHeadline(state.headline) ? 
                    'Headline should be valid and less than 80 characters' : 
                    ''
                  }
              />
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            type='submit'
            loading={updateBasicInfoStatus === LOADING}
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default BasicInfoDrawer;