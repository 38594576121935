import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ReactComponent as EditIcon } from '../../../assets/Icons/edit-3.svg'
import { ReactComponent as CloseIcon } from '../../../assets/Icons/close.svg'
import { ReactComponent as ChevronDown } from '../../../assets/Icons/chevron-down.svg'
import { useGeneralProfile } from '../../../contexts/Organization';
import { LOADING, SUCCESS, facility_types } from '../../../constants/common';
import { useCommon } from '../../../contexts/common';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { Checkbox } from '@mui/material';
import { validateOrganizationName, validatePhoneNumber, validateUrl } from '../../../helper/Validations';
import PhoneNumberInput from '../../../components/PhoneNumberInput';

const BasicInfo = () => {
  const {
    editFacilityBasicInfo,
    updateFacilityBasicInfoStatus,
    updateGeneralProfileState,
    facilityInfo,
    fetchFacilityInfo
  } = useGeneralProfile();
  const { fetchTreatmentTypes, facilityTypesList } = useCommon();

  const initalState = {
    facility_name: '',
    business_number: '',
    website_link: '',
    facility_type_ids: []
  }

  const [isEditMode, setIsEditMode] = useState(false);
  const [state, setState] = useState(initalState);
  const [isInvalid, setIsInvalid] = useState(false);

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  const handlePhoneNumber = (ev) => {
    setState((prevState) => ({
      ...prevState,
      ...ev
    }))
  }

  function handleOpenEditMode() {
    setIsEditMode(true)
  }

  function handleCloseEditMode() {
    setIsEditMode(false)
    setIsInvalid(false);
    setState({
      facility_name: facilityInfo.info?.facility_name || '',
      business_number: facilityInfo?.info?.business_number || '',
      website_link: facilityInfo?.info?.website_link || '',
      facility_type_ids: facilityInfo.info?.facility_types?.map((item) => item.id) || [],
    })
  }


  function isValid() {
    return validateOrganizationName(state.facility_name)
      && state.facility_type_ids.length > 0
      && (state.business_number ? validatePhoneNumber(state.business_number):true)
      && validateUrl(state.website_link)
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid()) {
      editFacilityBasicInfo(state);
    } else {
      setIsInvalid(true)
    }
  }

  useEffect(() => {
    if (updateFacilityBasicInfoStatus === SUCCESS) {
      updateGeneralProfileState('updateFacilityBasicInfoStatus', '')
      setIsEditMode(false)
      setIsInvalid(false);
      fetchFacilityInfo();
    }
  }, [updateFacilityBasicInfoStatus])

  useEffect(() => {
    if (facilityInfo) {
      setState({
        facility_name: facilityInfo?.info?.facility_name || '',
        business_number: facilityInfo?.info?.business_number || '',
        website_link: facilityInfo?.info?.website_link || '',
        facility_type_ids: facilityInfo.info?.facility_types?.map((item) => item.id) || [],
      })
    }
  }, [facilityInfo])

  useEffect(() => {
    fetchTreatmentTypes(facility_types);
  }, [])

  return (
    <Box
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'border.main',
        p: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
      component='form'
      onSubmit={handleSubmit}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h5'>Basic Information</Typography>
        {isEditMode ? (
          <Box>
            <LoadingButton
              type='submit'
              loading={updateFacilityBasicInfoStatus === LOADING}
              size='small'
            >
              Save
            </LoadingButton>
            <IconButton
              sx={{ p: 0.375 }}
              onClick={handleCloseEditMode}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <IconButton
            sx={{ p: 0.375 }}
            onClick={handleOpenEditMode}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
      <TextField
        fullWidth
        label='Name'
        onChange={handleInput}
        name='facility_name'
        value={state.facility_name}
        error={isInvalid && !validateOrganizationName(state.facility_name)}
        helperText={
          isInvalid
          && !validateOrganizationName(state.facility_name)
          && "Please enter valid facility name"
        }
        disabled={!isEditMode}
      />
      <PhoneNumberInput
        fullWidth
        name='business_number'
        label='Business Phone Number'
        value={state.business_number}
        onChange={handlePhoneNumber}
        error={isInvalid && !validatePhoneNumber(state.business_number)}
        helperText={
          isInvalid
          && !validatePhoneNumber(state.business_number)
          && "Please enter valid phone number"
        }
        disabled={!isEditMode}
      />
      <TextField
        fullWidth
        label='Company website'
        onChange={handleInput}
        name='website_link'
        value={state.website_link}
        error={isInvalid && !validateUrl(state.website_link)}
        disabled={!isEditMode}
        helperText={
          isInvalid
          && !validateUrl(state.website_link)
          && "Please enter valid business number"
        }
      />
      <FormControl fullWidth>
        <InputLabel id="facility-type-label">Treatment Facility Type</InputLabel>
        <Select
          labelId="facility-type-label"
          label='Treatment Facility Type'
          fullWidth
          renderValue={(selected) => selected.map((val) => facilityTypesList.find((item) => item.id === +val)?.name).join(', ')}
          name='facility_type_ids'
          multiple
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 224,
                width: 250,
              },
            },
          }}
          IconComponent={(props) => (
            <Box
              {...props}
              component='span'
              sx={{
                minWidth: 24,
                width: 24,
                height: 24,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                top: '50% !important',
                right: '16px !important',
                transform: 'translateY(-50%) !important',
              }}
            >
              <ChevronDown width={12} height={6} />
            </Box>
          )}
          onChange={handleInput}
          value={state.facility_type_ids || []}
          error={isInvalid && (state.facility_type_ids || []).length < 1}
          helperText={
            isInvalid
            && (state.facility_type_ids || []).length < 1
            && "Please select facility type"
          }
          disabled={!isEditMode}
        >
          <MenuItem
            sx={{
              p: 0
            }}
            value={null}
            disabled
          />
          {facilityTypesList.map((item) => (
            <MenuItem
              sx={{
                py: 1,
                gap: 1
              }}
              value={item.id}
              key={item.id}
            >
              <Checkbox sx={{ p: 0 }} checked={Boolean((state.facility_type_ids || []).find((val) => +val === item.id))} />
              <Typography variant='body2'>{item.name}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default BasicInfo