import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';

import { LOADING, SUCCESS, detox, payment_options, services, treatment } from '../../constants/common';
import { useGeneralProfile } from '../../contexts/Organization';

import { ReactComponent as EditIcon } from '../../assets/Icons/edit-3.svg'
import { ReactComponent as CloseIcon } from '../../assets/Icons/close.svg'
import { ReactComponent as ChevronDown } from '../../assets/Icons/chevron-down.svg'
import { ReactComponent as AddUserIcon } from '../../assets/Icons/user-add-plus-outlined.svg'
import CheckBox from '../../components/CheckBox';
import { useCommon } from '../../contexts/common';

const AvailableServices = () => {
  const {
    editFacilityServices,
    updateFacilityServicesStatus,
    updateGeneralProfileState,
    facilityInfo
  } = useGeneralProfile();
  const {
    fetchTreatmentTypes,
    servicesList,
    detoxList,
    paymentOptionsList,
  } = useCommon();
  const initalState = {
    available_services: [],
    detox_for: [],
    payment_options: []
  }
  const [isEditMode, setIsEditMode] = useState(false);
  const [state, setState] = useState(initalState);
  const [isInvalid, setIsInvalid] = useState(false);

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  function handleCheckBoxInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.checked
    }))
  }

  function handleOpenEditMode() {
    setIsEditMode(true)
  }

  function handleCloseEditMode() {
    setIsEditMode(false)
    setIsInvalid(false);
    setState({
      available_services: (facilityInfo?.info?.available_services || [])?.map(({ id }) => id),
      detox_for: (facilityInfo?.info?.detox_for || [])?.map(({ id }) => id),
      payment_options: (facilityInfo?.info?.payment_options || [])?.map(({ id }) => id),
      medication_assisted_treatment: facilityInfo?.info?.medication_assisted_treatment
    })
  }


  function isValid() {
    return state.available_services.length > 0
      && Boolean((facilityInfo?.info?.facility_types || []).find((item) => [23, 29].includes(item.id))) ? state.detox_for.length > 0 : true
    && state.payment_options.length > 0
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid()) {
      editFacilityServices(state);
    } else {
      setIsInvalid(true)
    }
  }

  useEffect(() => {
    if (updateFacilityServicesStatus === SUCCESS) {
      updateGeneralProfileState('updateFacilityServicesStatus', '')
      setIsEditMode(false)
      setIsInvalid(false);
    }
  }, [updateFacilityServicesStatus])

  useEffect(() => {
    if (facilityInfo) {
      setState({
        available_services: (facilityInfo?.info?.available_services || [])?.map(({ id }) => id),
        detox_for: (facilityInfo?.info?.detox_for || [])?.map(({ id }) => id),
        payment_options: (facilityInfo?.info?.payment_options || [])?.map(({ id }) => id),
        medication_assisted_treatment: facilityInfo?.info?.medication_assisted_treatment
      })
    }
  }, [facilityInfo])

  useEffect(() => {
    fetchTreatmentTypes(treatment);
    fetchTreatmentTypes(services);
    fetchTreatmentTypes(detox);
    fetchTreatmentTypes(payment_options);
  }, [])

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
      component='form'
      onSubmit={handleSubmit}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: 'border.dark'
            }}
          >
            <AddUserIcon width={24} height={24} />
          </Box>
          <Typography variant='h4' ml={1}>Services Available</Typography>
        </Box>
        {isEditMode ? (
          <Box>
            <LoadingButton
              type='submit'
              loading={updateFacilityServicesStatus === LOADING}
              size='small'
            >
              Save
            </LoadingButton>
            <IconButton
              sx={{ p: 0.375 }}
              onClick={handleCloseEditMode}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <IconButton
            sx={{ p: 0.375 }}
            onClick={handleOpenEditMode}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="available-services-label">Available Services</InputLabel>
            <Select
              labelId="available-services-label"
              label='Available Services'
              fullWidth
              name='available_services'
              multiple
              renderValue={(selected) => selected.map((val) => servicesList.find((item) => item.id === +val)?.name).join(', ')}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250,
                  },
                },
              }}
              IconComponent={(props) => (
                <Box
                  {...props}
                  component='span'
                  sx={{
                    minWidth: 24,
                    width: 24,
                    height: 24,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    top: '50% !important',
                    right: '16px !important',
                    transform: 'translateY(-50%) !important',
                  }}
                >
                  <ChevronDown width={12} height={6} />
                </Box>
              )}
              onChange={handleInput}
              value={state.available_services || []}
              error={isInvalid && (state.available_services || []).length < 1}
              disabled={!isEditMode}
            >
              <MenuItem
                sx={{
                  p: 0
                }}
                value={null}
                disabled
              />
              {servicesList.map((item) => (
                <MenuItem
                  sx={{
                    py: 1,
                    gap: 1
                  }}
                  value={item.id}
                  key={item.id}
                >
                  <Checkbox sx={{ p: 0 }} checked={Boolean((state.available_services || []).find((val) => +val === item.id))} />
                  <Typography variant='body2'>{item.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {Boolean((facilityInfo?.info?.facility_types || []).find((item) => [23, 29].includes(item.id))) && (
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="detox-for-label">Detox For</InputLabel>
              <Select
                labelId="detox-for-label"
                label='Detox For'
                fullWidth
                name='detox_for'
                multiple
                renderValue={(selected) => selected.map((val) => detoxList.find((item) => item.id === +val)?.name).join(', ')}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      width: 250,
                    },
                  },
                }}
                IconComponent={(props) => (
                  <Box
                    {...props}
                    component='span'
                    sx={{
                      minWidth: 24,
                      width: 24,
                      height: 24,
                      display: 'inline-flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      top: '50% !important',
                      right: '16px !important',
                      transform: 'translateY(-50%) !important',
                    }}
                  >
                    <ChevronDown width={12} height={6} />
                  </Box>
                )}
                onChange={handleInput}
                value={state.detox_for || []}
                error={isInvalid && (state.detox_for || []).length < 1}
                disabled={!isEditMode}
              >
                <MenuItem
                  sx={{
                    p: 0
                  }}
                  value={null}
                  disabled
                />
                {detoxList.map((item) => (
                  <MenuItem
                    sx={{
                      py: 1,
                      gap: 1
                    }}
                    value={item.id}
                    key={item.id}
                  >
                    <Checkbox sx={{ p: 0 }} checked={Boolean((state.detox_for || []).find((val) => +val === item.id))} />
                    <Typography variant='body2'>{item.name}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel id="payment-options-label">Payment options</InputLabel>
            <Select
              labelId="payment-options-label"
              label='Payment options'
              fullWidth
              name='payment_options'
              multiple
              renderValue={(selected) => selected.map((val) => paymentOptionsList.find((item) => item.id === +val)?.name).join(', ')}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224,
                    width: 250,
                  },
                },
              }}
              IconComponent={(props) => (
                <Box
                  {...props}
                  component='span'
                  sx={{
                    minWidth: 24,
                    width: 24,
                    height: 24,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    top: '50% !important',
                    right: '16px !important',
                    transform: 'translateY(-50%) !important',
                  }}
                >
                  <ChevronDown width={12} height={6} />
                </Box>
              )}
              onChange={handleInput}
              value={state.payment_options || []}
              error={isInvalid && (state.payment_options || []).length < 1}
              disabled={!isEditMode}
            >
              <MenuItem
                sx={{
                  p: 0
                }}
                value={null}
                disabled
              />
              {paymentOptionsList.map((item) => (
                <MenuItem
                  sx={{
                    py: 1,
                    gap: 1
                  }}
                  value={item.id}
                  key={item.id}
                >
                  <Checkbox sx={{ p: 0 }} checked={Boolean((state.payment_options || []).find((val) => +val === item.id))} />
                  <Typography variant='body2'>{item.name}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <CheckBox
        label='Medication-assisted Treatment'
        onChange={handleCheckBoxInput}
        name='medication_assisted_treatment'
        checked={Boolean(state.medication_assisted_treatment)}
        disabled={!isEditMode}
      />
    </Paper>
  )
}

export default AvailableServices