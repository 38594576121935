import { z } from 'zod';
import { treatment_program } from '../constants/common';

const myAvailabilitySchema = z.string()
  .refine(value => value !== '', {
    message: "Please select your availability."
  })
  .refine(value => ["accepting_new_clients", "not_accepting_clients"].includes(value), {
    message: "Invalid selection. Please choose 'Accepting clients' or 'Not accepting clients'."
  });

const clientsCurrentlyAcceptingSchema = z
  .array(z.number().int())


const availableSessionTypesSchema = z
  .array(z.enum([
    'has_video_provided',
    'inperson_session',
    'psypact_provider'
  ]))

const groupAcceptingFromSchema = z.enum([
  "",
  "everyone",
  "only_from_my_connections",
  "only_from_my_favorites",
]);

export const fullFormSchema = z.object({
  current_status: myAvailabilitySchema,
  currently_served: clientsCurrentlyAcceptingSchema.optional(),
  session_type: availableSessionTypesSchema.optional(),
  accepting_referrals_from: groupAcceptingFromSchema.optional(),
  account_type: z.string()
}).superRefine((data, ctx) => {
  if (data.current_status === "accepting_new_clients") {
    if (!data.currently_served?.length && data.account_type !== treatment_program) {
      ctx.addIssue({
        path: ["currently_served"],
        message: "Please select one or more client type.",
      });
    }
    if (!data.session_type?.length && data.account_type !== treatment_program) {
      ctx.addIssue({
        path: ["session_type"],
        message: "Please select one or more session type.",
      });
    }
    if (!data.accepting_referrals_from) {
      ctx.addIssue({
        path: ["accepting_referrals_from"],
        message: "Please select one or more group.",
      });
    }
  }
});

const urlPattern = /^(https?:\/\/)?[\w.-]+(\.[a-z]+)+([\/\w.-]*)?(\?[\w&%=.-]*)?$/i;

// Define validation schema using Zod
export const postLinkSchema = z.object({
  label: z
    .string()
    .min(1, "Please add a label.")
    .min(2, "Label must be at least 2 characters long.")
    .max(255, "Label must be less than 255 characters."),
  url: z
    .string()
    .optional()
    .superRefine((value, ctx) => {
      if (!value) {
        ctx.addIssue({
          code: "custom",
          message: "Please add a URL.",
        });
      } else if (value.length > 500) {
        ctx.addIssue({
          code: "custom",
          message: "URL must be less than 500 characters.",
        });
      } else if (!urlPattern.test(value)) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a valid URL.",
        });
      }
    }),
});
