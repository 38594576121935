import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import { ReactComponent as EditIcon } from '../../../assets/Icons/edit-3.svg'
import paths from '../../../constants/paths';
import LinksDrawer from './LinksDrawer';
import { useBasicInfo } from '../../../contexts/BasicInfo';
const LinksCard = () => {
    const { data } = useBasicInfo();
    const [open, setOpen] = useState(false)

    function handleClose() {
        setOpen(false);
    }

    function handleOpen() {
        setOpen(true);
    }

    return (
        <>
            <Box
                sx={{
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: 'border.main',
                    p: 3,
                    borderRadius: 2
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        mb: 1.5
                    }}
                >
                    <Typography variant='h5'>Links</Typography>
                    <IconButton
                        sx={{ p: 0.375 }}
                        onClick={handleOpen}
                    >
                        <EditIcon />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1.5,
                        gap: 0.5
                    }}
                >
                    <Typography variant='body2' fontWeight={600}>Website:</Typography>
                    <Link variant='body2' href={data?.info?.website_link} target='_blank'>{data?.info?.website_link}</Link>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1.5,
                        gap: 0.5
                    }}
                >
                    <Typography variant='body2' fontWeight={600}>Facebook:</Typography>
                    <Link variant='body2' href={data?.info?.facebook} target='_blank'>{data?.info?.facebook}</Link>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mb: 1.5,
                        gap: 0.5
                    }}
                >
                    <Typography variant='body2' fontWeight={600}>X:</Typography>
                    <Link variant='body2' href={data?.info?.twitter} target='_blank'>{data?.info?.twitter}</Link>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5
                    }}
                >
                    <Typography variant='body2' fontWeight={600}>LinkedIn:</Typography>
                    <Link variant='body2' href={data?.info?.linkedin} target='_blank'>{data?.info?.linkedin}</Link>
                </Box>
            </Box>
            <LinksDrawer
                open={open}
                onClose={handleClose}
            />
        </>
    )
}

export default LinksCard;