import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import paths from './constants/paths';
import MainLayout from './layouts/MainLayout';
import ProfileBasic from './pages/ProfileBasic';
import ProfileLayout from './layouts/ProfileLayout';
import ProfileDegree from './pages/ProfileDegree';
import ProfileAvailability from './pages/ProfileAvailability';
import ProfileSpecialties from './pages/ProfileSpecialties';
import ProfileTreatment from './pages/ProfileTreatment';
import ProfileInterests from './pages/ProfileInterests';
import ProfileFees from './pages/ProfileFees';
import OnboardingLayout from './layouts/OnboardingLayout';
import Onboarding from './pages/Onboarding';
import Login from './pages/Login';
import Info from './pages/Info';
import ProfileDemographics from './pages/ProfileDemographics';
import ForgetPassword from './pages/ForgetPassword';
import Network from './pages/Network';
import Referral from './pages/Referral';
import UserProfile from './pages/UserProfile';
import ResetPassword from './pages/ResetPassword';
import ProfileClientsServed from './pages/ProfileClientsServed';
import ProfileCredentials from './pages/ProfileCredentials';
import AccountSettings from './pages/AccountSettings';
import PasswordSettings from './pages/PasswordSettings';
import ProfileTraining from './pages/ProfileTraining';
import ProfileOrganization from './pages/ProfileOrganization';
import { facilityProfileNavMenu } from './constants/NavMenus';
import SettingsLayout from './layouts/SettingsLayout';
import ProfileTeam from './pages/ProfileTeam';
import ProfileBilling from './pages/ProfileBilling';
import { useGeneralProfile } from './contexts/Organization';
import { useAuth } from './contexts/Auth';
import { treatment_program } from './constants/common';
import ProfileServices from './pages/ProfileServices';
import ProfileFaqs from './pages/ProfileFaqs';
import ProfileImages from './pages/ProfileImages';
import InvitationOnboarding from './pages/InvitationOnboarding';
import InvitatationSettings from "./pages/InvitatationSettings";
import NotificationSettings from "./pages/NotificationSettings";
import Notifcations from "./pages/Notifcations";
import SpecificPostPage from "./pages/SpecificPostPage";
import Pusher from 'pusher-js';
import { useEffect, useMemo, useRef, useState } from 'react';
import useSnackbar from '@mui/base/useSnackbar';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { css, keyframes, styled } from '@mui/system';
import { useNotifcations } from "./contexts/Notifications"
import ReactGA from 'react-ga';
import initHelpHero from 'helphero';
import { getUnReadNotificationCount } from "./services/home.services";
import ChangePassword from './pages/ChangePassword';

var hlp = initHelpHero('vGIwFAylIg');
const pusher = new Pusher('eb5d61b1932629fa1677', {
  cluster: 'eu',
});

function App() {
  const location = useLocation();
  const prevPathname = useRef(location.pathname);
  const { facilityInfo } = useGeneralProfile();
  const { account_type, isLoggedIn } = useAuth();
  const [open, setOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const { updateNotificationData, updateNotificationCount } = useNotifcations();
  const memoizedPath = useMemo(() => location.pathname, [location.pathname]);


  useEffect(() => {
    hlp.anonymous();
    ReactGA.initialize('G-093R5M4FHE'); // Replace with your GA Tracking ID
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getUnReadNotificationCount()
        .then((res) => {
          updateNotificationCount(res.data.data.count);
        })
        .catch((error) => { });
    } else {
      localStorage.removeItem('accessToken')
    }
  }, [isLoggedIn]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const { getRootProps, onClickAway } = useSnackbar({
    onClose: handleClose,
    open,
    autoHideDuration: 5000,
  });

  const subscribeToNotifications = async () => {
    const userId = await localStorage.getItem('userId');
    const channel = await pusher.subscribe(`userNotifications.${userId}`);
    channel.bind('App\\Events\\UserNotifications', (res) => {
      updateNotificationData(res.notifications.data)
      updateNotificationCount(res.notifications.un_seen_count)
      const newMessage = res.notifications.data[0]
      setNotificationMessage("New notification received")
      handleOpen()
    });
  }

  useEffect(() => {
    subscribeToNotifications()
  }, []);


  return (
    <>
      <Routes>
        <Route
          path={paths.home}
          element={
            <MainLayout>
              <HomePage />
            </MainLayout>
          }
        />
        <Route
          path={`${paths.post}/:post_id/:comment_id?`}
          element={
            <MainLayout>
              <SpecificPostPage />
            </MainLayout>
          }
        />
        <Route
          path={paths.profileBasic}
          element={
            <ProfileLayout>
              <ProfileBasic />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileDegree}
          element={
            <ProfileLayout>
              <ProfileDegree />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileAvailability}
          element={
            <ProfileLayout
              menu={account_type === treatment_program && facilityProfileNavMenu}
              // hideNavButton={account_type === treatment_program}
              currentPage={account_type === treatment_program && 'Business Accounts'}
              currentPageTitle={account_type === treatment_program ? facilityInfo?.info?.facility_name : ''}
            >
              <ProfileAvailability />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileDemographics}
          element={
            <ProfileLayout
              menu={account_type === treatment_program && facilityProfileNavMenu}
              // hideNavButton={account_type === treatment_program}
              currentPage={account_type === treatment_program && 'Business Accounts'}
              currentPageTitle={account_type === treatment_program ? facilityInfo?.info?.facility_name : ''}
            >
              <ProfileDemographics />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileSpecialties}
          element={
            <ProfileLayout>
              <ProfileSpecialties />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileTreatment}
          element={
            <ProfileLayout
              menu={account_type === treatment_program && facilityProfileNavMenu}
              // hideNavButton={account_type === treatment_program}
              currentPage={account_type === treatment_program && 'Business Accounts'}
              currentPageTitle={account_type === treatment_program ? facilityInfo?.info?.facility_name : ''}
            >
              <ProfileTreatment />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.clientsServed}
          element={
            <ProfileLayout
              menu={account_type === treatment_program && facilityProfileNavMenu}
              // hideNavButton={account_type === treatment_program}
              currentPage={account_type === treatment_program && 'Business Accounts'}
              currentPageTitle={account_type === treatment_program ? facilityInfo?.info?.facility_name : ''}
            >
              <ProfileClientsServed />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileInterests}
          element={
            <ProfileLayout>
              <ProfileInterests />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileFees}
          element={
            <ProfileLayout
              menu={account_type === treatment_program && facilityProfileNavMenu}
              // hideNavButton={account_type === treatment_program}
              currentPage={account_type === treatment_program && 'Business Accounts'}
              currentPageTitle={account_type === treatment_program ? facilityInfo?.info?.facility_name : ''}
            >
              <ProfileFees />
            </ProfileLayout>
          }
        />
        {/* <Route
          path={paths.profileLanguages}
          element={
            <ProfileLayout
              menu={account_type === treatment_program && facilityProfileNavMenu}
              // hideNavButton={account_type === treatment_program}
              currentPage={account_type === treatment_program && 'Business Accounts'}
              currentPageTitle={account_type === treatment_program ? facilityInfo?.info?.facility_name : ''}
            >
              <ProfileLanguages />
            </ProfileLayout>
          }
        /> */}
        <Route
          path={paths.profileCredentials}
          element={
            <ProfileLayout>
              <ProfileCredentials />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileTraining}
          element={
            <ProfileLayout>
              <ProfileTraining />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileOrganization}
          element={
            <ProfileLayout
              menu={facilityProfileNavMenu}
              // hideNavButton
              currentPage='Business Accounts'
              currentPageTitle={facilityInfo?.info?.facility_name || ''}
            >
              <ProfileOrganization />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileServices}
          element={
            <ProfileLayout
              menu={facilityProfileNavMenu}
              // hideNavButton
              currentPage='Business Accounts'
              currentPageTitle={facilityInfo?.info?.facility_name || ''}
            >
              <ProfileServices />
            </ProfileLayout>
          }
        />


        <Route
          path={paths.profileFaqs}
          element={
            <ProfileLayout
              menu={facilityProfileNavMenu}
              // hideNavButton
              currentPage='Business Accounts'
              currentPageTitle={facilityInfo?.info?.facility_name || ''}
            >
              <ProfileFaqs />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileImages}
          element={
            <ProfileLayout
              menu={facilityProfileNavMenu}
              // hideNavButton
              currentPage='Business Accounts'
              currentPageTitle={facilityInfo?.info?.facility_name || ''}
            >
              <ProfileImages />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileTeam}
          element={
            <ProfileLayout
              menu={facilityProfileNavMenu}
              // hideNavButton
              currentPage='Business Accounts'
              currentPageTitle={facilityInfo?.info?.facility_name || ''}
            >
              <ProfileTeam />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profileBilling}
          element={
            <ProfileLayout
              // hideNavButton
              menu={facilityProfileNavMenu}
              currentPage='Business Accounts'
              currentPageTitle={facilityInfo?.info?.facility_name || ''}
            >
              <ProfileBilling />
            </ProfileLayout>
          }
        />

        {/* <Route
          path={paths.profileAccountSettings}
          element={
            <ProfileLayout
              menu={facilityProfileNavMenu}
              noWarning
              hideNavButton
              currentPage='Business Accounts'
              currentPageTitle={facilityInfo?.info?.facility_name || ''}
            >
              <AccountSettings />
            </ProfileLayout>
          }
        />

        <Route
          path={paths.profilePasswordSettings}
          element={
            <ProfileLayout
              menu={facilityProfileNavMenu}
              hideNavButton
              currentPage='Business Accounts'
              currentPageTitle={facilityInfo?.info?.facility_name || ''}
            >
              <PasswordSettings />
            </ProfileLayout>
          }
        /> */}

        <Route
          path={`${paths.onboarding}/:token?`}
          element={
            <OnboardingLayout
              withArrow
            >
              <Onboarding />
            </OnboardingLayout>
          }
        />

        <Route
          path={paths.login}
          element={
            <OnboardingLayout isLogin>
              <Login />
            </OnboardingLayout>
          }
        />

        <Route
          path={paths.info}
          element={
            <OnboardingLayout >
              <Info />
            </OnboardingLayout>
          }
        />


        <Route
          path={paths.forgetPassword}
          element={
            <OnboardingLayout
              withArrow
            >
              <ForgetPassword />
            </OnboardingLayout>
          }
        />

        <Route
          path={paths.resetPassword}
          element={
            <OnboardingLayout
              withArrow
            >
              <ResetPassword />
            </OnboardingLayout>
          }
        />

        <Route
          path={paths.changePassword}
          element={
            <ChangePassword />
          }
        />

        <Route
          path={paths.invitationOnboarding()}
          element={
            <OnboardingLayout>
              <InvitationOnboarding />
            </OnboardingLayout>
          }
        />

        <Route
          path={paths.network()}
          element={
            <Network />
          }
        />

        <Route
          path={`${paths.referrals()}`}
          element={
            <Referral />
          }
        />

        <Route
          path={paths.userProfile()}
          element={
            <UserProfile />
          }
        />

        <Route
          path={paths.settingsAccount}
          element={
            <SettingsLayout>
              <AccountSettings />
            </SettingsLayout>
          }
        />

        <Route
          path={paths.settingsPassword}
          element={
            <SettingsLayout>
              <PasswordSettings />
            </SettingsLayout>
          }
        />
        <Route
          path={paths.settingsInvitations}
          element={
            <SettingsLayout>
              <InvitatationSettings />
            </SettingsLayout>
          }
        />
        <Route
          path={paths.settingsNotifications}
          element={
            <SettingsLayout>
              <NotificationSettings />
            </SettingsLayout>
          }
        />
        <Route
          path={paths.Notifications}
          element={
            <Notifcations />
          }
        />
      </Routes>
      {open ? (
        <ClickAwayListener onClickAway={onClickAway}>
          <CustomSnackbar {...getRootProps()}>{notificationMessage}</CustomSnackbar>
        </ClickAwayListener>
      ) : null}
    </>
  );
}


const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const snackbarInRight = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

const CustomSnackbar = styled('div')(
  ({ theme }) => css`
    position: fixed;
    z-index: 5500;
    display: flex;
    right: 16px;
    bottom: 16px;
    left: auto;
    justify-content: start;
    max-width: 560px;
    min-width: 300px;
    background-color: ${theme.palette.mode === 'dark' ? grey[900] : '#FFF'};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    box-shadow: ${theme.palette.mode === 'dark'
      ? `0 4px 8px rgb(0 0 0 / 0.7)`
      : `0 4px 8px rgb(0 0 0 / 0.1)`};
    padding: 0.75rem;
    color: ${theme.palette.mode === 'dark' ? blue[200] : blue[700]};
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    animation: ${snackbarInRight} 200ms;
    transition: transform 0.2s ease-out;
  `,
);

export default App;
