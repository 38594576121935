import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { useCommon } from "../../../contexts/common";
import { ReactComponent as AwardIcon } from "../../../assets/Icons/award.svg";
import Filter from "./Filter";
import CheckBox from "../../../components/CheckBox";
import { ages, agesValues } from "../../../constants/lists";
import {
  inperson_session,
  psypact_provider,
  has_video_provided,
} from "../../../constants/common";

const ClientTypesFilter = ({ setFilters, filters, goBack, reset }) => {
  const { fetchClientTypesList, clientTypesList } = useCommon();
  const [expandClientTypes, setExpandClientTypes] = useState(false);
  const [expandAges, setExpandAges] = useState(false);

  useEffect(() => {
    if (goBack && reset) {
      setExpandClientTypes(true);
      setExpandAges(true);
    }
  }, [goBack, reset]);

  useEffect(() => {
    fetchClientTypesList();
  }, []);


  const toggleExpandClients = () => setExpandClientTypes(prevState => !prevState);
  const toggleExpandAges = () => setExpandAges(prevState => !prevState);

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFilters(prevState => {
      const items = prevState[name] || [];
      const updatedItems = items.includes(value)
        ? items.filter(remainingItem => remainingItem !== value)
        : [...items, value];

      if (name === "age" && !updatedItems.includes(value)) {
        return { ...prevState, [name]: updatedItems };
      }

      return { ...prevState, [name]: updatedItems };
    });
  };

  const handleAllAges = (ev) => {
    setFilters(prevState => ({
      ...prevState,
      age: ev.target.checked ? agesValues : [],
    }));
  }

  const handleCheckBox = (ev) => {
    const { name, checked } = ev.target;
    setFilters(prevState => {
      const updatedState = { ...prevState };
      if (!checked) {
        delete updatedState[name];
      } else {
        updatedState[name] = 1;
      }
      return updatedState;
    });
  }

  const allAgesChecked = filters?.age?.length === agesValues.length;

  return (
    <Filter
      label="Clients Served"
      icon={<AwardIcon />}
      isRigntIcon={true}
      goBack={goBack}
      reset={reset}
    >
      <Typography variant="body2" fontWeight={600}>
        Client Types
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          maxHeight: expandClientTypes ? "auto" : 90,
          overflow: "hidden",
        }}
      >
        {clientTypesList.map((item) => (
          <CheckBox
            key={item.name}
            label={item.name}
            value={item.id}
            onChange={handleChange}
            name="population"
            checked={Boolean(
              (filters?.population || []).find(
                (data) => data.toString() === item.id.toString()
              )
            )}
          />
        ))}
      </Box>

      <Button
        sx={{
          p: 0,
          justifyContent: "flex-start",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={toggleExpandClients}
        disableRipple
      >
        {expandClientTypes ? "See less" : "See more"}
      </Button>

      <Typography variant="body2" fontWeight={600}>
        Ages Served
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          maxHeight: expandAges ? "auto" : 90,
          overflow: "hidden",
        }}
      >
        <CheckBox
          label="All Ages"
          onChange={handleAllAges}
          checked={allAgesChecked}
        />
        {ages.map((item) => (
          <CheckBox
            label={item.label}
            key={item.value}
            value={item.value}
            onChange={handleChange}
            name="age"
            checked={Boolean(
              (filters?.age || []).find(
                (data) => data.toString() === item.value.toString()
              )
            )}
          />
        ))}
      </Box>
      <Button
        sx={{
          p: 0,
          justifyContent: "flex-start",
          backgroundColor: "transparent",
          "&:hover": {
            backgroundColor: "transparent",
          },
        }}
        onClick={toggleExpandAges}
        disableRipple
      >
        {expandAges ? "See less" : "See more"}
      </Button>

      <Typography variant="body2" fontWeight={600}>
        Others
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
          overflow: "hidden",
        }}
      >
        <CheckBox
          label={inperson_session.label}
          name={inperson_session.value}
          onChange={handleCheckBox}
          checked={Boolean(filters?.inperson_session)}
        />
        <CheckBox
          label={has_video_provided.label}
          name={has_video_provided.value}
          onChange={handleCheckBox}
          checked={Boolean(filters?.has_video_provided)}
        />
        <CheckBox
          label={psypact_provider.label}
          name={psypact_provider.value}
          onChange={handleCheckBox}
          checked={Boolean(filters?.psypact_provider)}
        />
      </Box>
    </Filter>
  );
};

export default ClientTypesFilter;
