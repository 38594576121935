import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import { ReactComponent as EditIcon } from '../../../assets/Icons/edit-3.svg'
import { ReactComponent as CloseIcon } from '../../../assets/Icons/close.svg'
import { ReactComponent as ChevronDown } from '../../../assets/Icons/chevron-down.svg'
import { useGeneralProfile } from '../../../contexts/Organization';
import { LOADING, SUCCESS } from '../../../constants/common';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { MenuItem } from '@mui/material';
import { useCommon } from '../../../contexts/common';
import useGeolocation from '../../../hooks/use-GeoLocation';
import { validatePhoneNumber, validateStreetAddress, validateText, validateZipcode } from '../../../helper/Validations';
import PhoneNumberInput from '../../../components/PhoneNumberInput';

const Contact = () => {
  const {
    updateFacilityContactStatus,
    editFacilityContact,
    updateGeneralProfileState,
    facilityInfo,
    fetchFacilityInfo
  } = useGeneralProfile();
  const { statesList, fetchStatesList } = useCommon();
  const initalState = {
    phone_number: '',
    fax_number: '',
    address1: '',
    city: '',
    state: null,
    zip_code: '',
  }
  const [isEditMode, setIsEditMode] = useState(false);
  const [state, setState] = useState(initalState);
  const [isInvalid, setIsInvalid] = useState(false);
  const { loading, location } = useGeolocation(state.zip_code);

  const handleInput = (ev) => {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }))
  }

  const handlePhoneNumberChange = (formattedNumber) => {
    setState((prevState) => ({
      ...prevState,
      ...formattedNumber
    }));
  };

  const handleOpenEditMode = () => {
    setIsEditMode(true)
  }

  const handleCloseEditMode = () => {
    setIsEditMode(false)
    setIsInvalid(false);
    setState({
      phone_number: facilityInfo?.info?.phone_number || '',
      fax_number: facilityInfo?.info?.fax_number || '',
      address1: facilityInfo?.address?.address1 || '',
      city: facilityInfo?.address?.city || '',
      state: facilityInfo?.address?.state?.id || null,
      zip_code: facilityInfo?.address?.zip_code || '',
    })
  }


  const isValid = () => {
    return (state.state
      && validatePhoneNumber(state.phone_number))
      && validateStreetAddress(state.address1)
      && validateText(state.city)
      && validateZipcode(state.zip_code)
      && validatePhoneNumber(state.fax_number)
  }

  const handleSubmit = (ev) => {
    ev.preventDefault();
    if (isValid()) {
      editFacilityContact(state);
    } else {
      setIsInvalid(true);
    }
  }

  useEffect(() => {
    if (updateFacilityContactStatus === SUCCESS) {
      updateGeneralProfileState('updateFacilityContactStatus', '')
      setIsEditMode(false)
      setIsInvalid(false);
      fetchFacilityInfo();
    }
  }, [updateFacilityContactStatus])

  useEffect(() => {
    if (facilityInfo) {
      setState({
        phone_number: facilityInfo?.info?.phone_number || '',
        fax_number: facilityInfo?.info?.fax_number || '',
        address1: facilityInfo?.info?.address?.address1 || '',
        city: facilityInfo?.info?.address?.city || '',
        state: facilityInfo?.info?.address?.state?.id || null,
        zip_code: facilityInfo?.info?.address?.zip_code || '',
      })
    }
  }, [facilityInfo])

  useEffect(() => {
    fetchStatesList();
  }, [])

  useEffect(() => {
    if ((location.latitude && location.longitude) && state.zip_code) {
      setState((prevState) => ({
        ...prevState,
        ...location
      }))
    } else {
      setState((prevState) => {
        delete prevState.latitude
        delete prevState.longitude
        return prevState
      })
    }
  }, [location, state.zip_code])

  return (
    <Box
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'border.main',
        p: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
      component='form'
      onSubmit={handleSubmit}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h5'>Primary program address</Typography>
        {isEditMode ? (
          <Box>
            <LoadingButton
              type='submit'
              loading={updateFacilityContactStatus === LOADING}
              size='small'
              disabled={loading}
            >
              Save
            </LoadingButton>
            <IconButton
              sx={{ p: 0.375 }}
              onClick={handleCloseEditMode}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <IconButton
            sx={{ p: 0.375 }}
            onClick={handleOpenEditMode}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>

      <Grid container columnSpacing={1.5} rowSpacing={3}>
        <Grid item xs={12}>
          <TextField
            variant='outlined'
            label='Street address'
            fullWidth
            onChange={handleInput}
            value={state.address1 || ''}
            name='address1'
            disabled={!isEditMode}
            error={isInvalid && !validateStreetAddress(state.address1)}
            helperText={
              isInvalid &&
              !validateStreetAddress(state.address1) &&
              "Please add valid street address"
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant='outlined'
            label='City'
            fullWidth
            onChange={handleInput}
            value={state.city || ''}
            name='city'
            disabled={!isEditMode}
            error={isInvalid && !validateText(state.city)}
            helperText={
              isInvalid
              && !validateText(state.city)
              && "Please enter valid city name"
            }
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            variant='outlined'
            label='State'
            fullWidth
            SelectProps={{
              IconComponent: () => (
                <Box
                  sx={{
                    minWidth: 24,
                    width: 24,
                    height: 24,
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mr: 2
                  }}
                >
                  <ChevronDown width={12} height={6} />
                </Box>
              ),
              MenuProps: {
                PaperProps: {
                  style: {
                    maxHeight: 220,
                    width: 250,
                  },
                },
              }
            }}
            onChange={handleInput}
            value={state.state || 0}
            name='state'
            disabled={!isEditMode}
            error={isInvalid && !state.state}
          >
            <MenuItem value={0} disabled>
              Select a state
            </MenuItem>
            {statesList.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            variant='outlined'
            label='ZIP code'
            fullWidth
            onChange={handleInput}
            value={state.zip_code || ''}
            name='zip_code'
            disabled={!isEditMode}
            error={isInvalid && !validateZipcode(state.zip_code)}
            helperText={
              isInvalid
              && !validateZipcode(state.zip_code)
              && "Please enter valid zipcode"
            }
          />
        </Grid>
        <Grid item sx={12} sm={6}>
          <PhoneNumberInput
            fullWidth
            label='Phone Number'
            onChange={handlePhoneNumberChange}
            value={state.phone_number}
            name='phone_number'
            error={isInvalid && !validatePhoneNumber(state.phone_number)}
            disabled={!isEditMode}
            helperText={
              isInvalid 
              && !validatePhoneNumber(state.phone_number)
              && "Please enter valid phone number"
            }
          />
        </Grid>
        <Grid item sx={12} sm={6}>
          <PhoneNumberInput
            fullWidth
            label='Fax'
            onChange={handlePhoneNumberChange}
            value={state.fax_number}
            name='fax_number'
            disabled={!isEditMode}
            error={isInvalid && !validatePhoneNumber(state.fax_number)}
            helperText={
              isInvalid
              && !validatePhoneNumber(state.fax_number)
              && "Please enter valid fax number"
            }
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Contact

