import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import RadioGroup from '@mui/material/RadioGroup';
import LoadingButton from '@mui/lab/LoadingButton';
import RadioButton from '../RadioButton';
import { useDemographics } from '../../contexts/Demographics';
import { LOADING, SUCCESS } from '../../constants/common';

const SexualOrientationDrawer = ({ onClose, open }) => {
    const [sexualOrientation, setSexualOrientation] = useState();
    const {
        sexualOrientationsList,
        fetchSexualOrientationsStatus,
        fetchSexualOrientationsList,
        editSexualOrientations,
        updateSexualOrientationsStatus,
        handleState,
        fetchDemographics
    } = useDemographics();

    function handleClose() {
        setSexualOrientation(undefined);
        onClose();
    }

    function handleInput(ev) {
        setSexualOrientation(ev.target.value);
    }

    function handleSave() {
        editSexualOrientations({ sexual_orientations: [sexualOrientation]  })
    }

    useEffect(() => {
        if (open)
            fetchSexualOrientationsList(true);
    }, [open])

    useEffect(() => {
        if (updateSexualOrientationsStatus === SUCCESS) {
          handleState({ updateSexualOrientationsStatus: '' });
          fetchDemographics(true);
          handleClose();
        }
    }, [updateSexualOrientationsStatus])

    useEffect(() => {
        setSexualOrientation(sexualOrientationsList.find((item) => item.selected)?.id?.toString())
      }, [sexualOrientationsList])

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            anchor='right'
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    pt: 8
                }}
            >
                <Box pb={3} px={3}>
                    <Typography variant='h4' mb={1}>Provider Demographics</Typography>
                    <Typography variant='body1' mb={1}>Select your sexual orientation</Typography>
                    <Divider sx={{ mb: 4 }} />
                    <Typography variant='body2' mb={2} fontWeight='bold'>Sexual orientations</Typography>
                    <RadioGroup
                        name="gender"
                        onChange={handleInput}
                    >
                        <Grid container spacing={2.5}>
                            {fetchSexualOrientationsStatus === LOADING ? (
                                <>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Skeleton animation="wave" />
                                    </Grid>
                                </>
                            ) : (
                                sexualOrientationsList.map((item) => (
                                    <Grid item xs={6} key={item.id}>
                                        <RadioButton
                                            label={item.name}
                                            value={item.id}
                                            checked={item.id === +sexualOrientation}
                                        />
                                    </Grid>
                                ))
                            )}
                        </Grid>
                    </RadioGroup>
                </Box>
                
                <Box
                    sx={{
                        boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
                        px: 1,
                        pt: 3,
                        pb: 5.625,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        position: 'sticky',
                        width: '100%',
                        backgroundColor: 'background.paper',
                        bottom: 0,
                        zIndex: 9
                    }}
                >
                    <Button
                        variant='outlined'
                        color='secondary'
                        sx={{
                            color: 'primary.main'
                        }}
                        size='large'
                        fullWidth
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant='contained'
                        size='large'
                        fullWidth
                        onClick={handleSave}
                        loading={updateSexualOrientationsStatus === LOADING}
                        disabled={!sexualOrientation}
                    >
                        Save
                    </LoadingButton>
                </Box>
            </Box>
        </Drawer>
    )
}

export default SexualOrientationDrawer;