import React, { createContext, useContext, useState } from "react";
import { ERROR, LOADING, SUCCESS } from "../constants/common";
import { useAlert } from "./Alert";
import {
  addComment,
  addPost,
  editPost,
  deletePost,
  getPosts,
  getSuggestedPeople,
  likePost,
  updateCurrentStatus,
  updateStatus,
  addReply,
  getRepliesOfComment,
  likeComment,
  likeReply,
  unlikeReply,
  unlikeComment,
  editComment,
  editReply,
} from "../services/home.services";
import CreatePostModal from "../components/CreatePostModal";
import { useBasicInfo } from "./BasicInfo";
import scrollTo from "../helper/scrollTo";

const HomePageContext = createContext({});

export const HomePageContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();
  const { data: userInfo } = useBasicInfo();
  const [homePageState, setHomePageState] = useState({
    fetchStatus: "",
    suggestPeople: [],
    createPostStatus: "",
    posts: {
      data: [],
    },
    fetchPostsStatus: "",
    createCommentStatus: "",
    addReplayStatus: "",
    likePostStatus: "",
    likeCommentStatus: "",
    currentStatus: {},
    updateCurrentStatusStatus: "",
    postdata: null,
    repliesToComment: [],
    updateCommentStatus: "",
    editReplayStatus: ""
  });

  const [openCreatePostModal, setOpenCreatePostModal] = useState(false);

  function handleOpenCreatePostModal() {
    setOpenCreatePostModal(true);
  }

  function handleCloseCreatePostModal() {
    setHomePageState((prev) => ({ ...prev, postdata: null }));
    setOpenCreatePostModal(false);
  }

  function handleState(obj) {
    setHomePageState((prevState) => ({
      ...prevState,
      ...obj,
    }));
  }

  async function fetchSuggestedPeople() {
    handleState({ fetchSuggestPeopleStatus: LOADING });
    try {
      const response = await getSuggestedPeople();
      handleState({
        suggestPeople: response.data.data.data,
        fetchSuggestPeopleStatus: SUCCESS,
      });
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ fetchSuggestPeopleStatus: ERROR });
      Promise.reject(error);
    }
  }

  async function fetchPosts(params) {
   // handleState({ fetchPostsStatus: LOADING });
    try {
      const response = await getPosts(params);
      handleState({
        posts:
          params.page > 1
            ? {
                ...response.data.data,
                data: [...homePageState.posts.data, ...response.data.data.data],
              }
            : response.data.data,
       // fetchPostsStatus: SUCCESS,
      });
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      //handleState({ fetchPostsStatus: ERROR });
      Promise.reject(error);
    }
  }

  async function createPost(data) {
    handleState({ createPostStatus: LOADING });
    try {
      const response = await addPost(data);
      handleState({
        createPostStatus: SUCCESS,
      });
      sendAlert("The post has been created successfully", "success");
      return response;
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ createPostStatus: ERROR });
      Promise.reject(error);
    }
  }
  async function editPostAction(data) {
    handleState({ createPostStatus: LOADING });
    try {
      const response = await editPost(homePageState.postdata.id, data);
      handleState({
        createPostStatus: SUCCESS,
      });
      sendAlert("The post has been updated successfully", "success");
      return response;
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ createPostStatus: ERROR });
      Promise.reject(error);
    }
  }

  async function togglePostLike(data) {
    handleState({ likePostStatus: LOADING });
    try {
      const response = await likePost(data);
      handleState({
        likePostStatus: SUCCESS,
        posts: {
          ...homePageState.posts,
          data: homePageState.posts.data.map((item) => {
            if (item.id === data.post_id) {
              return {
                ...item,
                liked: data.isLiked,
                likes_count: data.isLiked?
                   ++item.likes_count
                  : --item.likes_count,
              };
            }
            return item;
          }),
        },
      });
      return response;
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ likePostStatus: ERROR });
      Promise.reject(error);
    }
  }

  async function handleLikeComment(data, post_id) {
    handleState({ likeCommentStatus: LOADING });
    try {
      const response = await likeComment(data);
      handleState({
        likeCommentStatus: SUCCESS,
        posts: {
          ...homePageState.posts,
          data: homePageState.posts.data.map((item) => {
            if (item.id === post_id) {
              return {
                ...item,
                comments: item.comments.map((comment) => {
                  if (comment.id === data.comment_id) {
                    return {
                      ...comment,
                      liked: true,
                      likes_count: response?.data?.data?.likes
                    };
                  }
                  return comment;
                }),
              };
            }
            return item;
          }),
        },
      });
      return response;
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ likeCommentStatus: ERROR });
      Promise.reject(error);
    }
  }

  async function handleUnlikeComment(data, post_id) {
    handleState({ likeCommentStatus: LOADING });
    try {
      const response = await unlikeComment(data);
      handleState({
        likeCommentStatus: SUCCESS,
        posts: {
          ...homePageState.posts,
          data: homePageState.posts.data.map((item) => {
            if (item.id === post_id) {
              return {
                ...item,
                comments: item.comments.map((comment) => {
                  if (comment.id === data.comment_id) {
                    return {
                      ...comment,
                      liked: false,
                      likes_count: response?.data?.data?.likes
                    };
                  }
                  return comment;
                }),
              };
            }
            return item;
          }),
        },
      });
      return response;
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ likeCommentStatus: ERROR });
      Promise.reject(error);
    }
  }

  async function handleLikeReply(data, { comment_id, post_id }) {
    handleState({ likeCommentStatus: LOADING });
    try {
      const response = await likeReply(data);
      console.log('response', response)
      handleState({
        likeCommentStatus: SUCCESS,
        posts: {
          ...homePageState.posts,
          data: homePageState.posts.data.map((item) => {
            if (item.id === post_id) {
              return {
                ...item,
                comments: item.comments.map((comment) => {
                  if (comment.id === comment_id) {
                    return {
                      ...comment,
                      replies: comment.replies.map((r) => {
                        if (r.id === response?.data?.data?.id) {
                          return { ...r, likes_count: response?.data?.data?.likes, liked: true };
                        } else {
                          return r;
                        }
                      })
                    };
                  }
                  return comment;
                }),
              };
            }
            return item;
          }),
        },
      });
      return response;
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ likeCommentStatus: ERROR });
      Promise.reject(error);
    }
  }

  async function handleUnLikeReply(data, { comment_id, post_id }) {
    handleState({ likeCommentStatus: LOADING });
    try {
      const response = await unlikeReply(data);
      handleState({
        likeCommentStatus: SUCCESS,
        posts: {
          ...homePageState.posts,
          data: homePageState.posts.data.map((item) => {
            if (item.id === post_id) {
              return {
                ...item,
                comments: item.comments.map((comment) => {
                  if (comment.id === comment_id) {
                    return {
                      ...comment,
                      replies: comment.replies.map((r) => {
                        if (r.id === response?.data?.data?.id) {
                          return { ...r, likes_count: response?.data?.data?.likes, liked: false };
                        } else {
                          return r;
                        }
                      })
                    };
                  }
                  return comment;
                }),
              };
            }
            return item;
          }),
        },
      });
      return response;
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ likeCommentStatus: ERROR });
      Promise.reject(error);
    }
  }
  const deletePostAction = (id) => {
    return deletePost(id);
  };

  async function createComment(data) {
    handleState({ createCommentStatus: LOADING });
    try {
      const response = await addComment(data);
      handleState({
        createCommentStatus: SUCCESS,
        posts: {
          ...homePageState.posts,
          data: homePageState.posts.data.map((item) => {
            if (item.id === data.post_id) {
              return {
                ...item,
                comments: [...item.comments, response.data.data],
              };
            }
            return item;
          }),
        },
      });
      sendAlert("The Comment has been created successfully", "success");
      return response;
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ createCommentStatus: ERROR });
      Promise.reject(error);
    }
  }

  async function updateComment(data, post_id) {
    handleState({ updateCommentStatus: LOADING });
    try {
      const response = await editComment(data);
      handleState({
        updateCommentStatus: SUCCESS,
        posts: {
          ...homePageState.posts,
          data: homePageState.posts.data.map((item) => {
            if (item.id === post_id) {
              return {
                ...item,
                comments: item.comments.map((comment) => {
                  if (comment.id === data.comment_id) {
                    return {
                      ...comment,
                      comment: data.comment
                    };
                  }
                  return comment
                }),
              };
            }
            return item;
          }),
        },
      });
      sendAlert("The Comment has been updated successfully", "success");
      return response;
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ updateCommentStatus: ERROR });
      Promise.reject(error);
    }
  }
  

  async function addReplyHandler(data, post_id) {
    handleState({ addReplayStatus: LOADING });
    try {
      const response = await addReply(data);
      handleState({
        addReplayStatus: SUCCESS,
        posts: {
          ...homePageState.posts,
          data: homePageState.posts.data.map((item) => {
            if (item.id === post_id) {
              return {
                ...item,
                comments: item.comments.map((comment) => {
                  if (comment.id === data.comment_id) {
                    return {
                      ...comment,
                      replies_count: comment.replies_count + 1,
                      replies: [response.data.data, ...(comment?.replies || [])]
                    };
                  }
                  return comment;
                }),
              };
            }
            return item;
          }),
        },
      });
      scrollTo(document.getElementById(`comment-${data.comment_id}`), 100)
      sendAlert("The Replay Comment has been created successfully", "success");
      return response;
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ addReplayStatus: ERROR });
      Promise.reject(error);
    }
  }

  async function updateReplyHandler(data, {post_id, comment_id}) {
    handleState({ editReplayStatus: LOADING });
    try {
      const response = await editReply(data);
      handleState({
        editReplayStatus: SUCCESS,
        posts: {
          ...homePageState.posts,
          data: homePageState.posts.data.map((item) => {
            if (item.id === post_id) {
              return {
                ...item,
                comments: item.comments.map((comment) => {
                  if (comment.id === comment_id) {
                    return {
                      ...comment,
                      replies: comment?.replies.map((reply) => {
                        if (reply.id === data.reply_id) {
                          return {
                            ...reply,
                            reply: data.reply
                          };
                        }
                        return reply
                      }),
                    };
                  }
                  return comment;
                }),
              };
            }
            return item;
          }),
        },
      });
      sendAlert("The Replay Comment has been created successfully", "success");
      return response;
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ editReplayStatus: ERROR });
      Promise.reject(error);
    }
  }

  async function getRepliesOfCommentHandler(params, post_id) {
   // handleState({ fetchRepliesStatus: LOADING });
    try {
      const response = await getRepliesOfComment(params);
      handleState({
        createCommentStatus: SUCCESS,
        posts: {
          ...homePageState.posts,
          data: homePageState.posts.data.map((item) => {
            if (item.id === post_id) {
              return {
                ...item,
                comments: item.comments.map((comment) => {
                  if (comment.id === params) {
                    return {
                      ...comment,
                      replies: response.data.data
                    };
                  }
                  return comment;
                }),
              };
            }
            return item;
          }),
        },
      });
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
     // handleState({ fetchRepliesStatus: ERROR });
      Promise.reject(error);
    }
  }

  async function editCurrentStatus(data) {
    handleState({ updateCurrentStatusStatus: LOADING });
    try {
      const response = await updateCurrentStatus(data);
      handleState({
        updateCurrentStatusStatus: SUCCESS,
      });
      sendAlert("The status has been upated successfully", "success");
      return response;
    } catch (error) {
      sendAlert(error.response?.data?.message, "error");
      handleState({ updateCurrentStatusStatus: ERROR });
      Promise.reject(error);
    }
  }

  function updateHomePagetate(field, value) {
    handleState({ [field]: value });
  }
  function updateEditPostData(data) {
    setHomePageState((prevState) => ({
      ...prevState,
      postdata: data,
    }));
  }

  const state = {
    ...homePageState,
    fetchSuggestedPeople,
    createPost,
    fetchPosts,
    createComment,
    updateComment,
    togglePostLike,
    openCreatePostModal,
    handleOpenCreatePostModal,
    handleCloseCreatePostModal,
    editCurrentStatus,
    updateHomePagetate,
    updateEditPostData,
    deletePostAction,
    editPostAction,
    addReplyHandler,
    updateReplyHandler,
    getRepliesOfCommentHandler,
    handleLikeComment,
    handleUnlikeComment,
    handleLikeReply,
    handleUnLikeReply,
  };

  return (
    <HomePageContext.Provider value={state}>
      {children}
      <CreatePostModal
        open={openCreatePostModal}
        onClose={handleCloseCreatePostModal}
        postData={homePageState.postdata}
        isEditMode={!!homePageState.postdata}
      />
    </HomePageContext.Provider>
  );
};

export const useHomePage = () => useContext(HomePageContext);
