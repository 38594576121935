import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemButton from "@mui/material/ListItemButton";
import Container from "@mui/material/Container";
import { ReactComponent as SearchIcon } from "../../assets/Icons/search.svg";
import { colleagues } from "../../constants/common";

import { useLocation } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/Icons/logo-meduim.svg";
import { ReactComponent as LogoMobile } from "../../assets/Icons/logo-mobile.svg";

// import { ReactComponent as BellIcon } from '../../assets/Icons/bell.svg'
// import { ReactComponent as SearchIcon } from '../../assets/Icons/search.svg'
import paths from "../../constants/paths";
import navMenu from "../../constants/navMenu";
import UserMenu from "./UserMenu";
import { useBasicInfo } from "../../contexts/BasicInfo";
import NavMenu from "./NavMenu";
import { useGeneralProfile } from "../../contexts/Organization";
import { useAuth } from "../../contexts/Auth";
import { treatment_program } from "../../constants/common";
import Button from "@mui/material/Button";
import { ReactComponent as RewardIcon } from "../../assets/Icons/rewardWhite.svg";
import Badge from "@mui/material/Badge";
import { getUnReadNotificationCount } from "../../services/home.services";
import { useNotifcations } from "../../contexts/Notifications";
import { useNavigate } from "react-router-dom";
import useResize from "../../hooks/useResize";

const Header = ({ toggleInvitationModalVisability }) => {
  const { fetchInfo, data } = useBasicInfo();
  const { fetchFacilityInfo, facilityInfo } = useGeneralProfile();
  const { account_type } = useAuth();
  const location = useLocation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const { updateNotificationCount, unSeenNotifications: notifcationsNumber } = useNotifcations();
  const navigate = useNavigate();
  const isMobileView = useResize(600);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    if (account_type !== treatment_program) {
      if (
        !data?.id &&
        ![paths.home, paths.profileBasic].includes(location.pathname)
      )
        fetchInfo();
    } else {
      if (
        !facilityInfo?.id &&
        ![paths.home, paths.profileBasic].includes(location.pathname)
      )
        fetchFacilityInfo();
    }
  }, [data, facilityInfo, account_type]);

  // useEffect(() => {
  //   getUnReadNotificationCount()
  //     .then((res) => {
  //       updateNotificationCount(res.data.data.count);
  //     })
  //     .catch((error) => {});
  // }, [data, facilityInfo, account_type]);

  return (
    <Box minHeight={56}>
      <AppBar
        sx={{
          boxShadow: "0px 1px 0px #F1F2F3",
          backgroundColor: "background.paper",
          borderRadius: 0,
          border: 0,
        }}
      >
        <Container>
          <Toolbar
            disableGutters
            sx={{
              display: "flex",
              alignItems: "stretch",
              justifyContent: "space-between",
              minHeight: { xs: 56 },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Link
                href={paths.home}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  "& svg": { width: { xs: 100, md: 151 } },
                }}
              >
                {!isMobileView ? <Logo /> : <LogoMobile />}
              </Link>
              <div
                className="dummy-search-input"
                onClick={() => {
                  navigate(paths.network(colleagues));
                }}
                style={{
                  padding: "8px",
                  width: "150px",
                  display: "flex",
                  height: "35px",
                  AlignItems: "center",
                  marginLeft: "10px",
                  marginRight: "10px",
                  color: "#0C8CE9",
                  borderRadius: "30px",
                  borderWidth: 1,
                  fontSize: "14px",
                  borderStyle: "solid",
                  justifyContent: "flex-start",
                  alignItems: "center"
                }}
              >
                {" "}
                <SearchIcon /><span style={{ marginLeft: "8px" }}>Search Providers</span>{" "}
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 3,
              }}
            >
              <Box
                sx={{
                  display: { xs: "none", md: "flex" },
                  alignItems: "center",
                  mt: 1,
                }}
              >
                {navMenu.map((item, index) => {
                  const isActive =
                    location.pathname?.split("/")[1] ===
                    item.path?.split("/")[1];
                  if (!item.children) {
                    return (
                      <Link
                        key={item.id}
                        href={item.path}
                        sx={{
                          color: isActive ? "text.primary" : "text.secondary",
                          borderBottom: isActive ? "2px solid #0C8CE9" : "0",
                          lineHeight: "22px",
                          mx: 2,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "12px"
                          }}
                        >
                          {item.text === "Notifications" ? (
                            <Badge
                              badgeContent={notifcationsNumber}
                              color="error"
                              size="large"
                            >
                              {!isActive ? <item.Icon /> : <item.DarkIcon />}
                            </Badge>
                          ) :
                            !isActive ? <item.Icon /> : <item.DarkIcon />
                          }

                          {item.text}
                        </div>
                      </Link>
                    );
                  }

                  return <NavMenu key={item.id} data={item} />;
                })}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {!isMobileView && <Button
                  variant="contained"
                  size="small"
                  sx={{ mr: 2 }}
                  onClick={toggleInvitationModalVisability}
                  startIcon={<RewardIcon />}
                >
                  Invite
                </Button>}
                {/* <IconButton sx={{ mr: 1 }}>
                                <BellIcon />
                            </IconButton>
                            <IconButton sx={{ mr: 2 }}>
                                <SearchIcon />
                            </IconButton> */}
                <UserMenu toggleInvitationModalVisability={toggleInvitationModalVisability} />
                <Box
                  sx={{
                    flexGrow: 1,
                    display: { xs: "flex", md: "none" },
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                    className="menu-icon-dropdown"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0.5,
                      height: "100%",
                    }}
                  >
                    <Box
                      component="span"
                      sx={{
                        height: 2,
                        width: 20,
                        borderRadius: 8,
                        backgroundColor: "text.primary",
                      }}
                    />
                    <Box
                      component="span"
                      sx={{
                        height: 2,
                        width: 20,
                        borderRadius: 8,
                        backgroundColor: "text.primary",
                      }}
                    />
                    <Box
                      component="span"
                      sx={{
                        height: 2,
                        width: 20,
                        borderRadius: 8,
                        backgroundColor: "text.primary",
                      }}
                    />
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                  >
                    {navMenu.map((item) => {
                      const isActive = location.pathname === item.path;
                      return (
                        <MenuItem sx={{ p: 0 }} key={item.id}>
                          <ListItemButton
                            onClick={handleCloseNavMenu}
                            href={item.path}
                            sx={{
                              color: isActive
                                ? "text.primary"
                                : "text.secondary",
                              borderRight: isActive ? "2px solid #0C8CE9" : "0",
                              fontWeight: 600,
                              lineHeight: "22px",
                              py: 2,
                            }}
                          >
                            {item.text}
                          </ListItemButton>
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};

export default Header;
