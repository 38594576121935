import { convertObjectToFormData } from "../utils/helper";
import { axiosInstance } from "./axiosInstance";

function login(data) {
    return axiosInstance.post('login', data);
}

function register(data) {
    return axiosInstance.post('register', data);
}

function registerFacility(data) {
    return axiosInstance.post('register/treatment-program', convertObjectToFormData(data));
}

function subscriptionPlans(data) {
    return axiosInstance.get(data?.isSpecial ? 'tp-plans' : 'plans');
}

function subscriptionConfirm(data) {
    return axiosInstance.post('subscription/confirm', data);
}

function forgetPassword(data) {
    return axiosInstance.post('init-reset-password', data);
}

function resetPassword(data) {
    return axiosInstance.post('reset-password', data);
}

function deleteRegistration(uuid) {
    return axiosInstance.delete('remove-registration', { params: { uuid }});
}

function resendEmail(data) {
    return axiosInstance.post(`email/verify/${data.email}`);
}

function validateLinkToken(token) {
    return axiosInstance.get('invitations/validate-link', { params: { token } });
}

function changePassword({ password }) {
    return axiosInstance.put('password/change', {password});
}


export {
    login,
    register,
    subscriptionPlans,
    subscriptionConfirm,
    forgetPassword,
    deleteRegistration,
    resetPassword,
    registerFacility,
    resendEmail,
    validateLinkToken,
    changePassword
}