import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import LoadingButton from '@mui/lab/LoadingButton';
import { useFees } from '../../contexts/Fees';
import { LOADING, SUCCESS } from '../../constants/common';
import { ReactComponent as DollarSign } from '../../assets/Icons/dollar-sign.svg'
import { useBasicInfo } from '../../contexts/BasicInfo';
import { useLocation } from 'react-router-dom';
import paths from '../../constants/paths';
import isNumeric from 'validator/lib/isNumeric';

const FeesDrawer = ({ onClose, open }) => {
  const { fetchFees, fees, editFees, updateFeesStatus, updateFeesState } = useFees();
  const { fetchInfo, data: info } = useBasicInfo();
  const [state, setstate] = useState([]);
  const [isInvalid, setIsInvalid] = useState(false);
  const location = useLocation();

  function handleClose() {
    onClose();
    setIsInvalid(false);
  }

  function handleInput(ev) {
    setstate((prevState) => {
      return prevState.map((item) => {
        const isDouble = item.type === 'double';
        const id = isDouble ? +ev.target.name?.split('-')[0] : +ev.target.name
        if (item.id === id) {
          return ({
            ...item,
            fee: isDouble ? item.fee.map((fee, index) => {
              if (index === +ev.target.name?.split('-')[1]) {
                return ev.target.value
              }
              return fee
            }) : ev.target.value
          })
        }
        return item;
      })
    })
  }

  function isValid() {
    return state.filter((item) => 
      item.type === 'double' 
        ? ((item?.fee[0] && !isNumeric(item?.fee[0])) 
          || (item.fee[1] && !isNumeric(item?.fee[1]))) 
        : (item?.fee && !isNumeric(item?.fee)))?.length === 0
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid())
      editFees({ fees: state.map((item) => 
        ({ fee_id: item.id, fee: item.type === 'double' 
          ? (item.fee.filter((item) => !!item).length > 0 
            ? item.fee?.join(',') 
            : '') 
          : item.fee })) })
    else
      setIsInvalid(true);
  }

  useEffect(() => {
    setstate(fees.map((item) => ({ ...item, fee: item.type === 'double' ? (item.fee || ',')?.split(',') : item.fee || '' })));
  }, [fees])

  useEffect(() => {
    if (updateFeesStatus === SUCCESS) {
      updateFeesState('updateFeesStatus', '');
      fetchFees();
      if (info?.fees?.length < 1)
        fetchInfo();
      handleClose();
    }
  }, [updateFeesStatus])

  useEffect(() => {
    if (open && location.pathname === paths.home)
      fetchFees();
  }, [open, location])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
        onSubmit={handleSubmit}
        component='form'
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Session Fees:</Typography>
          <Typography variant='body1' mb={1}>Fill your preferred fees.</Typography>

          <Box mb={3}>
            {state.map((item) => (
              <Box key={item.id}>
                {item.type === 'double' ? (
                  <>
                    <Typography variant='h5' mb={1}>{item.name}</Typography>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          variant='outlined'
                          label='Minimum'
                          fullWidth
                          name={`${item.id.toString()}-0`}
                          type='number'
                          sx={{
                            mb: 1,
                            '.MuiOutlinedInput-input:not(textarea)': {
                              pl: 0
                            }
                          }}
                          onChange={handleInput}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DollarSign />
                              </InputAdornment>
                            )
                          }}
                          value={item?.fee[0] || ''}
                          error={isInvalid && (item?.fee[0] && !isNumeric(item?.fee[0]))}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          variant='outlined'
                          label='Maximum'
                          fullWidth
                          name={`${item.id.toString()}-1`}
                          type='number'
                          sx={{
                            mb: 1,
                            '.MuiOutlinedInput-input:not(textarea)': {
                              pl: 0
                            }
                          }}
                          onChange={handleInput}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DollarSign />
                              </InputAdornment>
                            )
                          }}
                          value={item?.fee[1] || ''}
                          error={isInvalid && (item?.fee[1] && !isNumeric(item?.fee[1]))}
                        />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <TextField
                    variant='outlined'
                    label={item.name}
                    fullWidth
                    name={item.id.toString()}
                    type='number'
                    sx={{
                      mb: 1,
                      '.MuiOutlinedInput-input:not(textarea)': {
                        pl: 0
                      }
                    }}
                    onChange={handleInput}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DollarSign />
                        </InputAdornment>
                      )
                    }}
                    value={item?.fee || ''}
                    error={isInvalid && (item.fee && !isNumeric(item?.fee))}
                  />
                )}
              </Box>
            ))}
          </Box>

        </Box>

        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            loading={updateFeesStatus === LOADING}
            type='submit'
          >
            Save
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default FeesDrawer;