import React, { useEffect, useRef, useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ListItemButton from '@mui/material/ListItemButton';
import { ReactComponent as ChevronDown } from '../../assets/Icons/chevron-down.svg'
import { ReactComponent as CheckIcon } from '../../assets/Icons/check.svg'
import { ReactComponent as XIcon } from '../../assets/Icons/x.svg'
import { useHomePage } from '../../contexts/HomePage';
import { SUCCESS, accepting_new_clients_and_taking_referrals, full_and_need_to_refer_out, treatment_program } from '../../constants/common';
import PracticeStatusDrawer from '../PracticeStatusDrawer';
import { useBasicInfo } from '../../contexts/BasicInfo';
import moment from 'moment';
import { useGeneralProfile } from '../../contexts/Organization';
import { useAuth } from '../../contexts/Auth';

const UpdateStatusMenu = ({
  openStatusDrawer,
  handleOpenStatusDrawer,
  handleCloseStatusDrawer
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { editCurrentStatus, updateCurrentStatusStatus, updateHomePagetate } = useHomePage();
  const { data, fetchInfo } = useBasicInfo();
  const { facilityInfo, fetchFacilityInfo } = useGeneralProfile();
  const { account_type } = useAuth();
  const info = account_type === treatment_program ? facilityInfo : data;


  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (updateCurrentStatusStatus === SUCCESS) {
      updateHomePagetate('updateCurrentStatusStatus', '');
      if (account_type !== treatment_program)
        fetchInfo();
      else
        fetchFacilityInfo()
    }
  }, [updateCurrentStatusStatus, account_type])


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
  }


  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (updateCurrentStatusStatus === SUCCESS) {
      updateHomePagetate('updateCurrentStatusStatus', '');
      if (account_type !== treatment_program)
        fetchInfo();
      else
        fetchFacilityInfo()
    }
  }, [updateCurrentStatusStatus, account_type])

  function updateStatus(ev, value) {
    editCurrentStatus({
      current_status: value,
      account_type: account_type === treatment_program ? treatment_program : 'user'
    })
    handleClose(ev)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }
  
  const currDate = moment();
  const dateToTest = moment(info.created_at);
  const result = currDate.diff(dateToTest, 'days')
  return (
    <>
      <Button
        variant={info?.info?.current_status === full_and_need_to_refer_out && result <= 3 ? 'contained' : 'outlined'}
        size='small'
        fullWidth
        disableRipple
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color={info?.info?.current_status === 'not_accepting_clients' ? 'error' : 'success'}
        sx={{
          borderRadius: 20,
          textTransform: 'capitalize',
          gap: 0.75,
          px: 1,
          alignItems: 'center',
          fontWeight: 600
        }}
      >
        {
          !info?.info?.current_status
            ? 'Change Practice Status'
            : info?.info?.current_status !== 'not_accepting_clients'
              ? 'Accepting Clients'
              : 'Not Accepting Clients'
        }
        {info?.info?.current_status && <Box sx={{ color: info?.info?.current_status === full_and_need_to_refer_out && result <= 3 ? '' : 'text.secondary' }}><ChevronDown /></Box>}
      </Button>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          zIndex: 1
        }}
      >
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: 'left top',
            }}
          >
            <Paper sx={{
              border: '1px solid',
              borderColor: 'border.main',
              boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
              borderRadius: 1.5,
              minWidth: 267
            }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem sx={{ p: 0 }}>
                    <ListItemButton
                      onClick={function (ev) {
                        if (info?.info?.accepting_referrals_from || (info?.client_types?.length > 0) || info?.info?.age_served) {
                          updateStatus(ev, accepting_new_clients_and_taking_referrals)
                        } else {
                          handleOpenStatusDrawer();
                        }
                      }}
                      sx={{ py: 1, px: 2, gap: 0.5 }}
                    >
                      <CheckIcon />
                      <Typography variant='body2' component='span'>
                        Accepting Clients
                      </Typography>
                    </ListItemButton>
                  </MenuItem>
                  <Box>
                    <Divider sx={{ my: 1 }} />
                  </Box>
                  <MenuItem sx={{ p: 0 }}>
                    <ListItemButton
                      onClick={function (ev) {
                        updateStatus(ev, full_and_need_to_refer_out)
                      }}
                      sx={{ py: 1, px: 2, gap: 0.5 }}
                    >
                      <XIcon />
                      <Typography variant='body2' component='span'>
                        Not Accepting Clients
                      </Typography>
                    </ListItemButton>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <PracticeStatusDrawer
        onClose={handleCloseStatusDrawer}
        open={openStatusDrawer}
      />
    </>
  )
}

export default UpdateStatusMenu