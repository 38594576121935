import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Container from "@mui/material/Container";

import { useOnboarding } from "../../../contexts/onboarding";
import { ReactComponent as ChevronDown } from "../../../assets/Icons/chevron-down.svg";
import { useCommon } from "../../../contexts/common";
import AgesSelection from "../../AgesSelection";
import { treatment_program } from "../../../constants/common";
import "./style.css";
import { clientTypeSchema } from "../../../validations/registerValidation";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

const ClientServed = () => {
  const { next, updateOnboardingState, onboardingState } = useOnboarding();
  const { clientTypesList, fetchClientTypesList } = useCommon();
  const [error, setError] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(clientTypeSchema),
    defaultValues: {
      client_types: onboardingState.client_types || [],
    },
    mode: "onChange",
  });

  function handleSelect(ev) {
    setValue("client_types", ev.target.value);
    updateOnboardingState({ [ev.target.name]: ev.target.value });
  }

  const onSubmit = () => {
    if (!onboardingState.age_served || onboardingState.age_served?.length === 0) {
      setError(true);
      return;
    }
    next();
  };

  useEffect(() => {
    fetchClientTypesList();
  }, []);


  return (
    <Container maxWidth="md">
      <div className="clients_served">
        {onboardingState.account_type !== treatment_program && (
          <>
            <Typography variant="h4">Clients Served</Typography>
            <FormControl error={!!errors.client_types}>
              <InputLabel id="demo-multiple-checkbox-label">
                Type of Clients*
              </InputLabel>
              <Controller
                name="client_types"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    input={<OutlinedInput label="Type of Clients*" />}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (id) =>
                            clientTypesList.find((item) => id === item.id)?.name
                        )
                        .join(", ")
                    }
                    value={field.value}
                    IconComponent={() => (
                      <Box
                        sx={{
                          minWidth: 24,
                          width: 24,
                          height: 24,
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mr: 2,
                        }}
                      >
                        <ChevronDown width={12} height={6} />
                      </Box>
                    )}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          width: 250,
                        },
                      },
                    }}
                    onChange={handleSelect}
                  >
                    {clientTypesList.map((item) => (
                      <MenuItem key={item.id} value={item.id} sx={{ gap: 1 }}>
                        <Checkbox
                          sx={{ p: 0 }}
                          checked={
                            !!(onboardingState.client_types || []).find(
                              (id) => id === item.id
                            )
                          }
                        />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.client_types && (
                <Typography color="error" variant="body2">
                  {errors.client_types.message || "Please select client types"}
                </Typography>
              )}
            </FormControl>
          </>
        )}
        <AgesSelection
          data={onboardingState}
          setData={updateOnboardingState}
          clientTypesList={clientTypesList}
          isInvalid={error}
        />
      </div>

      <div className="buttons_education">
        <Button
          variant="contained"
          sx={{
            minWidth: 212,
          }}
          onClick={async (e) => {
            if (onboardingState.account_type !== treatment_program) {
              await trigger("client_types");
              if (isValid) {
                handleSubmit(onSubmit)(e)
              }
            } else {
              onSubmit(e)
            }
          }}
        >
          Continue
        </Button>
      </div>
    </Container>
  );
};

export default ClientServed;
