import React, { useEffect, useState } from 'react'
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactComponent as ChevronDown } from '../../assets/Icons/chevron-down.svg'
import { useCommon } from '../../contexts/common';
import { useCredentials } from '../../contexts/Credentials';
import { LOADING, SUCCESS } from '../../constants/common';
import { validateMembershipId, validateOrganizationName, validateTitle, yearValidation } from '../../helper/Validations';

const CredentialsDrawer = ({ onClose, open, activeCredential }) => {
  const { statesList, fetchStatesList } = useCommon();
  const { generateCredential, createStatus, fetchCredentials, editCredential, updateStatus, updateCredentialstate, openConfirmationModal, deleteStatus } = useCredentials();
  const initialState = {
    name: '',
    title: '',
    state: '',
    number: '',
    year: ''
  }

  const [state, setState] = useState(initialState);
  const [isInvalid, setIsInvalid] = useState(false);

  const isEdit = Boolean(activeCredential);

  function handleInput(ev) {
    setState((prevState) => ({
      ...prevState,
      [ev.target.name]: ev.target.value
    }));
  }

  function handleClose() {
    onClose();
    setState(initialState);
    setIsInvalid(false);
  }

  function isValid() {
    return Boolean(state.name)
      && validateTitle(state.title)
      && Boolean(state.state)
      && validateMembershipId(state.number)
      && yearValidation(state.year)
  }

  function handleSubmit(ev) {
    ev.preventDefault();
    if (isValid()) {
      if (isEdit) {
        editCredential({
          credential_type: state.name,
          state_id: state.state,
          credential_data: state,
          credential_id: activeCredential?.id
        });
      } else {
        generateCredential({
          credential_type: state.name,
          state_id: state.state,
          credential_data: state
        });
      }
    } else {
      setIsInvalid(true);
    }
  }

  useEffect(() => {
    fetchStatesList()
  }, [])

  useEffect(() => {
    if (createStatus === SUCCESS) {
      updateCredentialstate('createStatus', '');
      fetchCredentials();
      handleClose();
    }
  }, [createStatus])

  useEffect(() => {
    if (updateStatus === SUCCESS) {
      updateCredentialstate('updateStatus', '');
      fetchCredentials();
      handleClose();
    }
  }, [updateStatus])


  useEffect(() => {
    if (deleteStatus === SUCCESS) {
      handleClose();
    }
  }, [deleteStatus])

  useEffect(() => {
    if (isEdit && open) {
      setState({
        ...activeCredential.data,
      });
    }
  }, [activeCredential, open, isEdit])

  return (
    <Drawer
      open={open}
      onClose={handleClose}
      anchor='right'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pt: 8
        }}
        component='form'
        onSubmit={handleSubmit}
      >
        <Box pb={3} px={3}>
          <Typography variant='h4' mb={1}>Credential</Typography>
          <Typography variant='body1' mb={3}>
            Please indicate the state(s) in which you are licensed and the year of your first licensure:
          </Typography>
          <Divider sx={{ mb: 3 }} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                select
                variant='outlined'
                label='Credential Type'
                fullWidth
                SelectProps={{
                  IconComponent: () => (
                    <Box
                      sx={{
                        minWidth: 24,
                        width: 24,
                        height: 24,
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'text.secondary',
                        mr: 2
                      }}
                    >
                      <ChevronDown width={12} height={6} />
                    </Box>
                  ),
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 220,
                        width: 250,
                      },
                    },
                  }
                }}
                name='name'
                onChange={handleInput}
                value={state.name}
                error={isInvalid && !state.name}
                disabled={isEdit}
              >
                <MenuItem value={''} disabled sx={{ py: 0 }} />
                <MenuItem value='membership' sx={{ py: 1 }}>Membership</MenuItem>
                <Divider sx={{ my: 1 }} />
                <MenuItem value='licence' sx={{ py: 1 }}>License</MenuItem>
                <Divider sx={{ my: 1 }} />
                <MenuItem value='certificate' sx={{ py: 1 }}>Certificate</MenuItem>
              </TextField>
            </Grid>
            {Boolean(state.name) && (
              <>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    label={`
                      ${state.name === 'membership' ? 'Membership Organization' : ''}
                      ${state.name === 'licence' ? 'License Issuer' : ''}
                      ${state.name === 'certificate' ? 'Accrediting Institution' : ''}
                    `}
                    fullWidth
                    name='title'
                    onChange={handleInput}
                    value={state.title}
                    error={isInvalid && !validateOrganizationName(state.title)}
                    helperText={
                      isInvalid &&
                      !validateOrganizationName(state.title) &&
                      "Please enter valid title"
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    variant='outlined'
                    label='State'
                    fullWidth
                    SelectProps={{
                      IconComponent: () => (
                        <Box
                          sx={{
                            minWidth: 24,
                            width: 24,
                            height: 24,
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'text.secondary',
                            mr: 2
                          }}
                        >
                          <ChevronDown width={12} height={6} />
                        </Box>
                      ),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 220,
                            width: 250,
                          },
                        },
                      }
                    }}
                    name='state'
                    onChange={handleInput}
                    value={state.state}
                    error={isInvalid && !state.state}
                  >
                    <MenuItem value={0} disabled />
                    {statesList.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant='outlined'
                    label={`
                      ${state.name === 'membership' ? 'Membership ID/Number' : ''}
                      ${state.name === 'licence' ? 'License Number' : ''}
                      ${state.name === 'certificate' ? 'Certificate ID/Number' : ''}
                    `}
                    fullWidth
                    name='number'
                    onChange={handleInput}
                    value={state.number}
                    error={isInvalid && !validateMembershipId(state.number)}
                    helperText={
                      isInvalid &&
                      !validateMembershipId(state.number) &&
                      `Please add valid ${state.name || ""} number`
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant='outlined'
                    label='Year (YYYY)'
                    fullWidth
                    name='year'
                    onChange={handleInput}
                    value={state.year}
                    error={isInvalid && !yearValidation(state.year)}
                    helperText={
                      isInvalid &&
                      !yearValidation(state.year) &&
                      "please enter valid year"
                    }
                  />
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Typography variant='body2' color='text.secondary'>
                Please note that the licensure information provided will be visible to other users when viewing your full profile.
              </Typography>
            </Grid>
            {isEdit && (
              <Grid item xs={12} display='flex' justifyContent='flex-end'>
                <Button
                  onClick={openConfirmationModal}
                  size='small'
                  color='error'
                  sx={{
                    minWidth: 120,
                    backgroundColor: 'error.light',
                    color: 'error.main',
                  }}
                >
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>


        <Box
          sx={{
            boxShadow: '0px 0px 32px rgba(0, 0, 0, 0.08)',
            px: 1,
            pt: 3,
            pb: 5.625,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'sticky',
            width: '100%',
            backgroundColor: 'background.paper',
            bottom: 0,
            zIndex: 9
          }}
        >
          <Button
            variant='outlined'
            color='secondary'
            sx={{
              color: 'primary.main'
            }}
            size='large'
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            size='large'
            fullWidth
            type='submit'
            loading={createStatus === LOADING}
          >
            {isEdit ? 'Save' : 'Create'}
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  )
}

export default CredentialsDrawer;